import React, { useState, useCallback } from 'react'
import { isEmpty } from 'ramda'
import { ReactComponent as AccessTimeIcon } from 'src/assets/ico-time-cash.svg'
import { useInterval } from 'src/hooks/useInterval'
import d from 'src/components/dishboard/Dishboard.module.css'
import { ReactComponent as MiniCrossIcon } from 'src/assets/ico-close-16.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getOptionsets } from 'src/state/dish'
import { getReadableDate, optionsToTextWithPrice, t } from 'src/utils'
import { addTimer, getTimerById, removeTimer } from 'src/state/timers'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import useOrderApi from 'src/hooks/useOrderApi'
import s from './Cashier.module.css'

const Timer = ({ paidAt, showTime, hideCountdown, children, className, timedTakeout, isUnpaider }) => {
  const paidTime = new Date(timedTakeout ? timedTakeout : paidAt).getTime()
  //const tt = getReadableDate(timedTakeout)
  const pa = getReadableDate(paidAt)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const time2 = new Date(parseInt(paidTime))

  const renderPaymentDate = useCallback(dateTime => {
    const { time, date } = getReadableDate(dateTime)
    const [day, month] = date.split(' ')
    return <>{date ? ` ${day} ${translations[month]}, ` : <></>}</>
  }, [])

  let hours2 = time2.getHours()
  let minutes2 = time2.getMinutes()
  return (
    <div className={[s.timer, s.badge, s.badgeNew, className].join(' ')}>
      {showTime ? (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={[s.timerBadge, timedTakeout && isUnpaider ? s.unpaiderTimed : ''].join(' ')}
        >
          {/* {isUnpaider && timedTakeout ? <ArrowBack style={{ marginRight: '8px' }} /> : ''} */}
          {timedTakeout ? <AccessTimeIcon /> : ''}
          <span style={{ marginLeft: timedTakeout ? '8px' : '0px' }}>
            {timedTakeout
              ? new Date(timedTakeout).getDate() !== new Date().getDate()
                ? renderPaymentDate(timedTakeout)
                : translations.todayComma
              : ''}
            {hours2 > 9 ? hours2 : '0' + hours2}:{minutes2 > 9 ? minutes2 : '0' + minutes2} /{' '}
          </span>
          {hideCountdown === true ? '' : <TimerText time={paidTime} countdown={timedTakeout} />}
        </div>
      ) : (
        ''
      )}
      {/* <div className={[s.badge, badgeStyle].join(' ')}>{badge}</div> */}
      {children}
    </div>
  )
}

const TimerText = ({ time, countdown = false }) => {
  const [result, setResult] = useState(getDurationSeconds(time, countdown))

  const h = Math.floor(result / 3600)
  const m = Math.floor(result / 60) % 60
  const s = result % 60

  useInterval(() => {
    const timeframe = getDurationSeconds(time, countdown)

    setResult(timeframe)
  }, 1000)

  return (
    <strong style={{ marginLeft: '8px' }}>
      {Boolean(h) && `${padWithZero(h)}:`}
      {padWithZero(m)}:{padWithZero(s)}
    </strong>
  )
}

export const DishboardBlock = ({ time, countdown = false, callback = () => {} }) => {
  const [result, setResult] = useState(getDurationSeconds(time, countdown))
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const m = Math.floor(result / 60)
  const s = result % 60

  useInterval(() => {
    const timeframe = getDurationSeconds(time, countdown)
    if (timeframe === 0) {
      if (callback) {
        callback()
      }
    } else {
      setResult(timeframe)
    }
  }, 1000)

  return (
    <div className={d.timerWrapper}>
      <div className={d.timerText1}>0:{padWithZero(s)}</div>
      <div className={d.timerText2}>{translations.cancel}</div>
    </div>
  )
}

export const getDurationSeconds = (time, countdown = false, allowNegative) => {
  const duration = timeToDelta(time)
  return countdown && duration > 0 ? (allowNegative ? duration : 0) : Math.abs(duration)
}

export const padWithZero = n => {
  const str = String(n)
  return str.length === 1 ? `0${str}` : str
}
export const timeToDelta = time => Math.floor((Date.now() - time) / 1000)

export default Timer

export const OrderComponent = ({ order, index, comment, timedTakeout, cookingTime }) => {
  // const payment = useSelector(state => getPaymentById(state, paymentId))
  // return payment.id
  // const order = useSelector(state => getPaymentOrderById(state, orderId))
  const countdown = true
  const time = new Date(timedTakeout)
  const [result, setResult] = useState(getDurationSeconds(time, countdown))
  const initialTimer = useSelector(getTimerById)(order.id ? order.id : order.ids.join(' '))
  const timer = initialTimer ? initialTimer.time : false
  // const [timer, setTimer] = useState(initialTimer)

  useInterval(() => {
    const timeframe = getDurationSeconds(time, countdown)

    setResult(timeframe)
  }, 1000)
  return <OrderComponentContent {...{ order, index, comment, cookingTime, timer, time }} />
}

export const OrderComponentContent = ({ order, index, comment, cookingTime, timer, time }) => {
  const dishes = useSelector(getDishesEntities)
  const optionsets = useSelector(getOptionsets)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const dispatch = useDispatch()

  const [, { sendOrderStatus }] = useOrderApi(order.id ? [order.id] : order.ids)

  const dish = order && order.dishId ? dishes[order.dishId] : { label: '', selectedOptions: [] }
  const title = dish ? (dish.label ? dish.label : t(dish.name, selectedLanguage)) : ''
  const selOptions = (order && order.selectedOptions) || []
  const orderStatus = (order && order.status) || false
  const subtitle = dish
    ? isEmpty(selOptions) || selOptions === undefined
      ? ''
      : optionsToTextWithPrice(selOptions, optionsets)
    : ''
  const timeToCooking = cookingTime * 60 + 120 > -timeToDelta(time) // < cookingTime

  return (
    <div
      className={[
        d.blockCard,
        orderStatus && order.status.ready ? d.greenCard : timeToCooking ? d.whiteCard : d.blackCard,
        (comment && index > 1) || index > 2 ? d.mtop1 : ''
      ].join(' ')}
      onClick={() => {
        if (timeToCooking && !order?.status?.ready) {
          if (timer) {
            dispatch(removeTimer(order.id ? order.id : order.ids.join(' ')))
          } else {
            dispatch(addTimer(order.id ? order.id : order.ids.join(' ')))
          }
        }
      }}
    >
      {timer ? (
        <DishboardBlock
          time={timer}
          countdown
          callback={() => {
            sendOrderStatus({ status: 'ready' })
            dispatch(removeTimer(order.id ? order.id : order.ids.join(' ')))
          }}
        />
      ) : (
        ''
      )}
      <div className={d.blockCardContent}>
        {order.ids && order.ids.length > 1 ? (
          <div className={d.nameCount}>
            {order.ids.length}
            {/* <div className={d.minicross}>x</div> */}
            <MiniCrossIcon className={d.minicross} />
          </div>
        ) : (
          ''
        )}
        <div className={[d.name].join(' ')}>
          {title.slice(0, 16)}
          {title.length > 16 ? '…' : ''}
        </div>
        <div className={[d.subname].join(' ')}>
          {/* {subtitle.slice(0, 16)}
                        {subtitle.length > 16 ? '…' : ''} */}
          {subtitle}
        </div>
      </div>
    </div>
  )
}
