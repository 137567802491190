import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsAuthorized, setUserProfile, setSelectedLanguage } from 'src/state/general/actions'
import { fetchSelfProfile } from 'src/api'
import { getAppPreferencesThunk } from './state/app-preferences/thunkActions'
import { findDefaultLanguage } from './utils'
import { toggleOfflineMode } from './state/status/actions'
import { getVenuesThunk } from './state/venues/thunkActions'
import AppPicker from './AppPicker'
import ErrorBoundary from './ErrorBoundary'

export default function App() {
  const dispatch = useDispatch()
  const { isAuthorized } = useSelector(state => state.general)

  const currentVenue = localStorage.getItem('currentVenue') || undefined

  useEffect(() => {
    dispatch(getVenuesThunk())
    if (!currentVenue) {
      dispatch(setIsAuthorized(false))
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(setSelectedLanguage(findDefaultLanguage()))
  }, [])

  useEffect(() => {
    if (isAuthorized) {
      getAppPreferencesThunk(dispatch)
      const fetchProfile = async () => {
        try {
          const profile = await fetchSelfProfile()
          dispatch(setUserProfile(profile))
        } catch (e) {
          console.log(`Error while getting profile: ${e.message}`)
        }
      }
      fetchProfile()
    }
  }, [isAuthorized])

  const handleOffline = useCallback(() => {
    dispatch(toggleOfflineMode(true))
  }, [dispatch, toggleOfflineMode])

  const handleOnline = useCallback(() => {
    dispatch(toggleOfflineMode(false))
  }, [dispatch, toggleOfflineMode])

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [handleOffline, handleOnline])

  return (
    <ErrorBoundary>
      <AppPicker />
    </ErrorBoundary>
  )
}
