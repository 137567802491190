import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as ProfileIcon } from 'src/assets/ico-profile.svg'
import { ReactComponent as CommentIcon } from 'src/assets/ico-comment2.svg'
import { ReactComponent as TimedTakeoutIcon } from 'src/assets/ico-deffered_togo-24-n.svg'
import { ReactComponent as CloseIcon } from 'src/assets/btn-close-24.svg'
import { ReactComponent as NameIcon } from 'src/assets/ico-name-16.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/ico-phone-16.svg'

import { ReactComponent as TimeIcon } from 'src/assets/ico-time-24.svg'
import { ReactComponent as DiesIcon } from 'src/assets/ico-dies-16-1.svg'
import { ReactComponent as PoiIcon } from 'src/assets/ico-poi-24.svg'
import { getRoles } from 'src/state/roles'
import { showContect } from 'src/state/contect'

import { updatePayment } from 'src/state/payments/actions'
import { decoratePhone } from 'src/shared/utils/phone'
import { SvgIcon } from 'src/components/shared/ExMaterial'
import { getReadableDate } from 'src/utils'
import { getGeneralCard, setCardGeneral } from 'src/state/general'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import usePaymentApi from 'src/hooks/usePaymentApi'
import OrderList from './OrderList'
import Timer from './Timer'
import s from './Cashier.module.css'
import { isDeliveryOrders, isTakeoutOrders } from '../../../utils/payments'

const PaymentCard = ({ dishboard, payment, children, hideCountdown, className, onClick = () => {} }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const dispatch = useDispatch()
  const cardOpen = useSelector(getGeneralCard) === payment.id
  const [refund, setRefund] = useState(false)
  const unpaiders = useSelector(getRoles)

  const [{ paymentStatus }, { sendPaymentStatus }] = usePaymentApi(payment.id)

  const roleSearch = unpaiders.find(role => role.key === payment.userRole)
  const role = roleSearch ? roleSearch.value : ''

  const isUnpaider = unpaiders.filter(e => payment.userRole === e.key).length > 0

  if (cardOpen) console.log(payment)

  const renderPaymentDate = useCallback(dateTime => {
    const { time, date } = getReadableDate(dateTime)
    const [day, month] = date.split(' ')
    return (
      <>
        {date ? ` ${day} ${translations[month]}, ` : <></>}
        {time}
      </>
    )
  }, [])

  return (
    <>
      {cardOpen && (
        <div
          className={s.paymentCard_shadow}
          onClick={e => {
            dispatch(setCardGeneral(false))
            e.stopPropagation()
          }}
        >
          <div
            className={s.paymentCard}
            onClick={e => {
              e.stopPropagation()
              // dispatch(setCardGeneral(false))
            }}
          >
            {dishboard ? (
              <div className={s.paymentHeader2}>
                {isDeliveryOrders(payment) ? (
                  <DeliveryIcon width='48' height='48' />
                ) : !payment.timedTakeout ? (
                  <>
                    {isTakeoutOrders(payment) && <TogoIcon width='48' height='48' />}
                    {Boolean(payment.tableNum) && <TableIcon className={s.mleft8} width='48' height='48' />}
                  </>
                ) : (
                  <TimedTakeoutIcon width='48' height='48' />
                )}
                <div className={[s.paymentHeaderText, s.mleft8].join(' ')}>
                  {payment.tableNum ? payment.tableNum + ' / ' : ''}
                  {payment.takeoutNum}
                </div>
                <div className={s.buttons}>
                  <SvgIcon
                    fontSize='large'
                    onClick={() => dispatch(setCardGeneral(false))}
                    className={clsx(s.enabledButton, s.matButton)}
                  >
                    <CloseIcon fill={'#000000'} width='24' height='24' />
                  </SvgIcon>
                </div>
              </div>
            ) : (
              <Timer
                hideCountdown={!hideCountdown}
                timedTakeout={payment.timedTakeout}
                showTime
                className={[s.mtop0, isUnpaider ? s.unpaiderTimer : ''].join(' ')}
                paidAt={payment.paidAt}
              >
                {refund !== false ? (
                  translations.refund
                ) : (
                  <div className={s.flex}>
                    {isUnpaider && (
                      <div style={{ marginLeft: '8px' }} className={s.unpaiderFlex}>
                        <span style={{ marginLeft: '8px' }}>{role}</span>
                      </div>
                    )}
                    <div
                      onClick={e => {
                        e.stopPropagation()
                        dispatch(
                          showContect({
                            phone: decoratePhone(payment.userPhone),
                            delAdress: payment.userAddress,
                            delType: payment.delivery ? 'taxi' : false,
                            type: payment.userContactType,
                            userid: payment.userId,
                            name: payment.userName
                          })
                        )
                      }}
                      className={[s.timerTab].join(' ')}
                    >
                      <div className={s.paiderFont}>
                        {payment.userName ? payment.userName : translations.anonymousVisitor}
                      </div>
                      <ProfileIcon style={{ marginLeft: '8px' }} />
                    </div>
                  </div>
                )}
              </Timer>
            )}
            <div className={s.paymntWrap}>
              <div className={s.paymntHWrap}>
                <div className={s.paymntFlx1}>
                  <div className={s.paymntBadge}>{translations.guest}</div>
                  <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                    <NameIcon width='24' height='24' />
                    <div className={s.mleft16}>
                      {payment?.userName || payment?.delivery?.name || translations.anonymousVisitor}
                    </div>
                  </div>
                  <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                    <PhoneIcon width='24' height='24' />
                    <div className={s.mleft16}>
                      {payment.userPhone
                        ? decoratePhone(payment.userPhone)
                        : payment.delivery && payment.delivery.phone
                        ? decoratePhone(payment.delivery.phone)
                        : translations.anonymousVisitor}
                    </div>
                  </div>
                  {payment.timedTakeout && (
                    <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                      <TimeIcon width='24' height='24' />
                      <div className={s.mleft16}>{renderPaymentDate(payment.timedTakeout)}</div>
                    </div>
                  )}
                  {payment.userAddress && (
                    <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                      <PoiIcon width='24' height='24' />
                      <div className={s.mleft16}>{payment.userAddress ? payment.userAddress : ''}</div>
                    </div>
                  )}
                </div>
                {payment.delivery && (
                  <div className={s.paymntFlx1}>
                    <div className={[s.flex, s.aic, s.jcBetween, s.mbottom32].join(' ')}>
                      <div className={s.paymntBadge}>{translations.deliveryService}</div>
                      <div>
                        {payment.delivery.type && payment.delivery.type === 'novaposhta'
                          ? translations.novaPoshta
                          : translations.taxi}
                      </div>
                    </div>
                    {payment.delivery.type && payment.delivery.type === 'taxi' ? (
                      <div style={{ display: 'none' }}>
                        <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                          <PoiIcon width='24' height='24' />
                          <div className={s.mleft16}>
                            {payment.delivery && payment.delivery.car ? payment.delivery.car : ''}
                          </div>
                        </div>
                        <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                          <PoiIcon width='24' height='24' />
                          <div className={s.mleft16}>
                            {payment.delivery && payment.delivery.carnum ? payment.delivery.carnum : ''}
                          </div>
                        </div>
                        <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                          <PoiIcon width='24' height='24' />
                          <div className={s.mleft16}>
                            {payment.delivery && payment.delivery.drivername ? payment.delivery.drivername : ''}
                          </div>
                        </div>
                        <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                          <PoiIcon width='24' height='24' />
                          <div className={s.mleft16}>
                            {payment.delivery && payment.delivery.driverphone ? payment.delivery.driverphone : ''}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={[s.flex, s.aic, s.mbottom16].join(' ')}>
                          <DiesIcon width='24' height='24' />
                          <div className={s.mleft16}>
                            {payment.delivery && payment.delivery.department ? payment.delivery.department : ''}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className={[s.paymntHr, s.mtop16, s.mbottom16].join(' ')} />
              <div className={s.paymntBadge}>{translations.order}</div>
            </div>
            <div style={{ padding: '20px' }} className={s.paymentBoxWrapper}>
              {refund !== false && (
                <>
                  <div className={[s.paymentBoxPartScroll, s.paymentBoxPart1].join(' ')}>
                    <div className={[s.flex1, s.descFlex].join(' ')} />
                    {refund !== false && (
                      <div
                        style={{ backgroundColor: '#FFFFFF', color: 'black', marginTop: '16px' }}
                        onClick={() => {
                          setRefund(false)
                        }}
                        className={[s.button, s.notButton].join(' ')}
                      >
                        {translations.cancel}
                      </div>
                    )}
                  </div>
                  <div className={s.vr1} />
                </>
              )}
              {paymentStatus &&
                paymentStatus.integrationStarted &&
                !(paymentStatus.integrationConfirmed || paymentStatus.integrationCanceled) !== false && (
                  <>
                    <div className={[s.paymentBoxPartScroll, s.paymentBoxPart1].join(' ')}>
                      <div className={[s.flex1, s.descFlex].join(' ')} />

                      <div
                        style={{ backgroundColor: 'black', color: '#ffffff', marginTop: '16px' }}
                        onClick={() => {
                          sendPaymentStatus({ status: 'cashierConfirmed' })
                          dispatch(updatePayment({ id: payment.id, integrationStatus: 'finish' }))
                        }}
                        className={[s.button, s.notButton].join(' ')}
                      >
                        {translations.finishPayment}
                      </div>
                    </div>
                    <div className={s.vr1} />
                  </>
                )}
              <div className={[s.paymentBoxPartScroll, s.paymentBoxPart2].join(' ')}>
                {!dishboard && (
                  <div className={s.paymentHeader}>
                    {isDeliveryOrders(payment) ? (
                      <DeliveryIcon width='48' height='48' />
                    ) : !payment.timedTakeout ? (
                      <>
                        {isTakeoutOrders(payment) && <TogoIcon width='48' height='48' />}
                        {Boolean(payment.tableNum) && <TableIcon className={s.mleft8} width='48' height='48' />}
                      </>
                    ) : (
                      <TimedTakeoutIcon width='48' height='48' />
                    )}
                    <div className={[s.paymentHeaderText, s.mleft8].join(' ')}>
                      {payment.tableNum ? payment.tableNum + ' / ' : ''}
                      {payment.takeoutNum}
                    </div>
                    <div className={s.buttons}>
                      <SvgIcon
                        fontSize='large'
                        onClick={() => dispatch(setCardGeneral(false))}
                        className={clsx(s.enabledButton, s.matButton)}
                      >
                        <CloseIcon fill='#000000' width='24' height='24' />
                      </SvgIcon>
                    </div>
                  </div>
                )}
                {payment.comment && (
                  <div className={s.orderBox}>
                    <div className={[s.flex, s.flex1].join(' ')}>
                      <CommentIcon style={{ minWidth: '24px' }} />
                      <div className={[s.name, s.mleft20].join(' ')}>{payment.comment}</div>
                    </div>
                  </div>
                )}
                {payment.preorder && !isSameDay(payment.paidAt, payment.timedTakeout) && (
                  <div className={s.orderBox}>
                    <div className={[s.flex, s.flex1].join(' ')}>
                      <div className={[s.name, s.mleft20].join(' ')}>
                        {translations.preorderTo}
                        {renderPaymentDate(payment.timedTakeout)}
                      </div>
                    </div>
                  </div>
                )}

                {payment.delivery && (
                  <div className={s.orderBox}>
                    <div className={[s.flex, s.flex1].join(' ')}>
                      <div className={[s.name, s.mleft20].join(' ')}>
                        {translations.deliveryAddress(
                          payment.delivery.address,
                          decoratePhone(payment.delivery.phone),
                          payment.delivery.name
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <OrderList refund={refund} paymentId={payment.id} {...{ orders: payment.orders }} />
                <div className={[isUnpaider ? s.unpaiderTotal : s.total].join(' ')}>
                  <div className={s.totalLabel}>{translations.total}</div>
                  <div className={[s.mleft48, s.flexT].join(' ')}>{`${payment.amountBeforeDiscount / 100} ₴`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={className}
        onClick={() => {
          dispatch(setCardGeneral(payment.id))
          if (onClick) onClick()
        }}
      >
        {children}
      </div>
    </>
  )
}

export default PaymentCard

// TODO: check to use time in timezone and not in UTC
const isSameDay = (paidAt, duedate) => {
  if (!duedate) return true

  const paidAtTemp = new Date(paidAt)
  const duedateTemp = new Date(duedate)

  return paidAtTemp.setHours(0, 0, 0, 0) == duedateTemp.setHours(0, 0, 0, 0)
}
