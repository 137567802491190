import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTimerById, removeTimer } from 'src/state/timers'
import usePaymentApi from 'src/hooks/usePaymentApi'
import { useInterval } from 'src/hooks/useInterval'
import { ReactComponent as MoreIcon } from 'src/assets/ico-more.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TimedTakeoutIconSW } from 'src/assets/ico-deffered_togo-24-semiwhite.svg'
import d from '../../dishboard/Dishboard.module.css'
import PaymentCard from './PaymentCard'
import { DishboardBlock, getDurationSeconds, padWithZero, timeToDelta } from './Timer'
import s from './Cashier.module.css'
import { isDeliveryOrders, isTakeoutOrders } from '../../../utils/payments'

const PaymentInfoCard = ({ payment, time, cookingTime, onClick = () => {} }) => {
  const countdown = !!payment.timedTakeout
  const allowNegative = true
  const dispatch = useDispatch()
  const timer = useSelector(getTimerById)(`payment[${payment.id}]`)
  const [result, setResult] = useState(getDurationSeconds(time, countdown, allowNegative))

  const [, { sendPaymentStatus }] = usePaymentApi(payment.id)

  let m = Math.floor(result / 60)
  let ss = result % 60

  if (timeToDelta(time) > 0) {
    if (m >= 99) {
      m = 99
      ss = 99
    }
  }
  if (m > 99) {
    m = 99
  }

  useInterval(() => {
    const timeframe = getDurationSeconds(time, countdown, allowNegative)

    setResult(timeframe)
  }, 1000)

  // console.log(cookingTime * 60, timeToDelta(time))
  // console.log(!payment.timedTakeout)
  let card = 'blackCard'
  if (!payment.timedTakeout) {
    if (payment.orders && payment.orders.every(y => y.status.ready)) {
      card = 'greenCard'
    } else {
      card = 'yellowCard'
    }
  } else if (timeToDelta(time) > 0) {
    if (payment.orders && payment.orders.every(y => y.status.ready)) {
      card = 'greenCard'
    } else {
      card = 'redCard'
    }
  } else if (cookingTime * 60 > -timeToDelta(time)) {
    card = 'yellowCard'
  } else if (cookingTime * 60 + 120 > -timeToDelta(time)) {
    card = 'yellowCard'
  } else {
    card = 'blackCard'
  }

  return (
    <div className={[d.paymentCard, d[card]].join(' ')}>
      <PaymentCard dishboard className={d.firstBlock} {...{ payment }} key={`paymentcard-${payment.id}`}>
        <MoreIcon />
      </PaymentCard>
      <div className={d.vr} />
      <div onClick={onClick} className={d.paymentInfoWrapper}>
        {timer ? (
          <DishboardBlock
            time={timer.time}
            countdown
            callback={() => {
              sendPaymentStatus({ status: 'finished' })
              dispatch(removeTimer(`payment[${payment.id}]`))
            }}
          />
        ) : (
          ''
        )}
        <div className={d.paymentInfo}>
          <div className={d.time}>
            {payment.timedTakeout ? timeToDelta(time) > 0 && '-' : ''}
            {padWithZero(m)}:{padWithZero(ss)}
          </div>
          <div className={d.paymentTakeoutRow}>
            {isDeliveryOrders(payment) ? (
              <DeliveryIcon fill={'rgba(255, 255, 255, 0.7'} width='22' height='22' />
            ) : (
              <>
                {isTakeoutOrders(payment) && <TogoIcon stroke={'rgba(255, 255, 255, 0.7'} width='16' height='16' />}
                {Boolean(payment.tableNum) && (
                  <TableIcon fill={'rgba(255, 255, 255, 0.7'} className={s.mleft8} width='16' height='16' />
                )}
                {payment.timedTakeout ? <TimedTakeoutIconSW width='22' height='22' /> : ''}
              </>
            )}
            <div className={[d.takeoutText, s.mleft2].join(' ')}>
              {Boolean(payment.tableNum) && `${payment.tableNum} / `}
              {payment.takeoutNum}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentInfoCard
