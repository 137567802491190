import React, { useRef, useState } from 'react'
import styles from '../styles.module.scss'

const OrderCard = ({ orderNumber, isReady }) => {
  // const dispatch = useDispatch()
  const [] = useState(false)

  const ref = useRef()

  return (
    <div ref={ref} className={`${styles.orderWrapper} ${isReady ? styles.orderWrapperActive : ''}`}>
      <span className={styles.number}>{orderNumber}</span>
    </div>
  )
}
export default OrderCard
