import {
  ADD_ERROR,
  REMOVE_ERROR,
  SET_API_AVAILABLE,
  SET_ERRORS,
  SET_GOOGLE,
  SET_INTEGRATION,
  SET_NEED_RESTART,
  SET_PING,
  SET_PONG,
  SET_OFFLINE
} from './types'

export const setPing = content => ({
  type: SET_PING,
  payload: { content }
})
export const setPong = content => ({
  type: SET_PONG,
  payload: { content }
})
export const setApiAvailable = content => ({
  type: SET_API_AVAILABLE,
  payload: { content }
})
export const toggleOfflineMode = content => ({
  type: SET_OFFLINE,
  payload: { content }
})
export const setNeedRestart = content => ({
  type: SET_NEED_RESTART,
  payload: { content }
})
export const setGoogle = content => ({
  type: SET_GOOGLE,
  payload: { content }
})

export const setIntegration = content => ({
  type: SET_INTEGRATION,
  payload: { content }
})

export const setErrors = content => ({
  type: SET_ERRORS,
  payload: { content }
})

export const addError = content => ({
  type: ADD_ERROR,
  payload: { content }
})

export const removeError = content => ({
  type: REMOVE_ERROR,
  payload: { content }
})
