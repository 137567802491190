export const FETCH_PAID_PAYMENTS = 'FETCH_PAID_PAYMENTS'
export const ADD_PAYMENT = 'ADD_PAYMENT'
export const ADD_ORDER_MODIFIER = 'ADD_ORDER_MODIFIER'
export const REMOVE_ORDER_MODIFIER = 'REMOVE_ORDER_MODIFIER'
export const DELETE_PAYMENT = 'DELETE_PAYMENT'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT'
export const ACTIVATE_PAYMENT = 'ACTIVATE_PAYMENT'
export const DEACTIVATE_PAYMENT = 'DEACTIVATE_PAYMENT'
export const MARK_FIRST_TIME = 'MARK_FIRST_TIME'
