import {
  FETCH_PAID_PAYMENTS,
  ADD_PAYMENT,
  CONFIRM_PAYMENT,
  MARK_FIRST_TIME,
  ACTIVATE_PAYMENT,
  DEACTIVATE_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  ADD_ORDER_MODIFIER,
  REMOVE_ORDER_MODIFIER
} from './types'

const initialState = {
  loading: true,
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PAID_PAYMENTS: {
      const { payments } = action.payload
      const processed = payments.map(payment => {
        let activated = true

        if (payment.timedTakeout) {
          activated = false
        }

        if (payment.status['cashierConfirmed'] || payment.status['confirmed']) {
          // console.log(payment.status)
          return { ...payment, activated, cashier: true }
        } else if (payment.status['integrationCanceled']) return { ...payment, activated, integrationStatus: 'error' }
        else if (payment.status['integrationConfirmed'])
          return { ...payment, activated, integrationStatus: 'finish', cashier: true }
        else if (payment.status['integrationStarted'])
          return { ...payment, activated, integrationStatus: 'started', cashier: true }
        else {
          return { ...payment, activated }
        }
      })

      return {
        ...state,
        loading: false,
        list: processed
      }
    }

    case MARK_FIRST_TIME: {
      const { user } = action.payload
      const processed = state.list.map(payment => {
        if (payment.userId === user.userId) {
          return {
            ...payment,
            userName: user.userName,
            userEmail: user.userEmail,
            userPhone: user.userPhone,
            userContactType: user.userContactType
          }
        } else {
          return payment
        }
      })

      return {
        ...state,
        list: processed
      }
    }

    case ADD_PAYMENT: {
      const { payment } = action.payload
      let activated = true
      let result = state.list

      if (payment.timedTakeout) {
        activated = false
      }

      if (state.list.filter(p => p.id === payment.id).length > 0) {
        result = state.list.map(p => (p.id === payment.id ? payment : p))
        return {
          ...state,
          list: result
        }
      }

      // console.log(payment.status)
      if (payment.status['cashierConfirmed'] || payment.status['confirmed'])
        result = [...state.list, { ...payment, activated, cashier: true }]
      else if (payment.status['integrationCanceled'])
        result = [...state.list, { ...payment, activated, integrationStatus: 'error' }]
      else if (payment.status['integrationConfirmed'])
        result = [...state.list, { ...payment, activated, integrationStatus: 'finish', cashier: true }]
      else if (payment.status['integrationStarted'])
        result = [...state.list, { ...payment, activated, integrationStatus: 'started', cashier: true }]
      else {
        result = [...state.list, { ...payment, activated }]
      }

      return {
        ...state,
        list: result
      }
      // return [...state, { timedTakeout: tdd, ...payment }]
    }

    case DELETE_PAYMENT: {
      const { payment } = action.payload
      const result = state.list.filter(p => p.id !== parseInt(payment.id))
      return {
        ...state,
        list: result
      }
    }

    case UPDATE_PAYMENT: {
      const { payment } = action.payload
      const result = state.list.map(p => {
        const prevStatus = p.status || {}
        const newStatus = payment?.status || {}
        return p.id === payment.id ? { ...p, ...payment, status: { ...prevStatus, ...newStatus } } : p
      })
      return {
        ...state,
        list: result
      }
    }

    case ADD_ORDER_MODIFIER: {
      const { orderId, paymentId, modifier } = action.payload
      const result = state.list.map(p =>
        p.id === paymentId
          ? {
              ...p,
              integration: {
                ...p.integration,
                dishes: p.integration.dishes.map(x =>
                  x.dishId === orderId ? { ...x, modifier: x.modifier + ' ' + modifier } : x
                )
              },
              orders: p.orders.map(x => (x.dishId === orderId ? { ...x, modifier: x.modifier + ' ' + modifier } : x))
            }
          : p
      )
      return {
        ...state,
        list: result
      }
    }

    case REMOVE_ORDER_MODIFIER: {
      // const { orderId, paymentId } = action.payload
      // const result = state.map(p =>
      //   p.id === paymentId
      //     ? {
      //         ...p,
      //         integration: {
      //           ...p.integration,
      //           dishes: p.integration.dishes.map(x => (x.dishId === orderId ? { ...x, modifier: undefined } : x))
      //         },
      //         orders: p.orders.map(x => (x.dishId === orderId ? { ...x, modifier: undefined } : x))
      //       }
      //     : p
      // )
      // return result
      return state
    }

    case ACTIVATE_PAYMENT: {
      const { payment } = action.payload
      const result = state.list.map(e => (e.id === payment.id ? { ...e, activated: true } : e))
      return {
        ...state,
        list: result
      }
    }

    case DEACTIVATE_PAYMENT: {
      const { payment } = action.payload
      const result = state.list.map(e => (e.id === payment.id ? { ...e, activated: false } : e))
      return {
        ...state,
        list: result
      }
    }

    case CONFIRM_PAYMENT: {
      const { payment } = action.payload
      const result = state.list.map(e => (e.id === payment.id ? { ...e, cashier: true } : e))
      return {
        ...state,
        list: result
      }
    }

    default:
      return state
  }
}
