import { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../constants/url'
import { getGeneralVenue } from '../state/general'
import { getApiAvailability, getLastPong, getNeedRestart } from '../state/status/selectors'
import { authHeader, resendApi, fetchOrders, fetchStoplists } from '../utils/api'
import { setApiAvailable, setNeedRestart, setPong } from '../state/status/actions'

export default function useSetupCashierChannel() {
  const venue = useSelector(getGeneralVenue)
  const needRestart = useSelector(getNeedRestart)
  const lastPong = useSelector(getLastPong)
  const apiAvailable = useSelector(getApiAvailability)
  const dispatch = useDispatch()

  const [eventSource, setEventSource] = useState(null)
  const [connectFailed, setConnectFailed] = useState(false)
  const reconnectTimer = useRef(0)
  const apiAvailableTimer = useRef(0)
  const eventSourceURL = `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venue}/channel`

  const restart = useCallback(
    onRestart => {
      eventSource.close()
      setEventSource(new EventSource(eventSourceURL, authHeader))

      if (onRestart) {
        onRestart()
      }
      setConnectFailed(false)
      dispatch(setNeedRestart(false))
      dispatch(setPong(Date.now()))
    },
    [eventSource, dispatch]
  )

  useEffect(() => {
    if (needRestart) {
      restart(() => {
        fetchOrders(venue, dispatch)
        fetchStoplists(venue, dispatch)
      })
    }
  }, [needRestart, restart, venue, dispatch])

  useEffect(() => {
    clearTimeout(reconnectTimer.current)

    if (connectFailed) {
      reconnectTimer.current = setTimeout(() => {
        dispatch(setNeedRestart(true))
      }, 5 * 1000)
    }

    return () => {
      clearTimeout(reconnectTimer.current)
    }
  }, [connectFailed, dispatch, setNeedRestart])

  const start = useCallback(
    onStart => {
      const newEventSource = new EventSource(eventSourceURL, authHeader)
      setEventSource(newEventSource)

      if (onStart) {
        onStart()
      }
    },
    [eventSourceURL, setEventSource]
  )

  useEffect(() => {
    if (!apiAvailable) {
      clearTimeout(reconnectTimer.current)

      reconnectTimer.current = setTimeout(() => {
        window.location.reload()
      }, 8 * 1000)
    }

    return () => {
      clearTimeout(reconnectTimer.current)
    }
  }, [apiAvailable, dispatch])

  useEffect(() => {
    clearTimeout(apiAvailableTimer.current)
    dispatch(setApiAvailable(true))

    apiAvailableTimer.current = setTimeout(() => {
      dispatch(setApiAvailable(false))
    }, 8 * 1000)

    return () => {
      clearTimeout(apiAvailableTimer.current)
    }
  }, [lastPong, dispatch, connectFailed]) // lastPong is a trigger to clear timeout for reconnect

  useEffect(() => {
    if (!eventSource) {
      return
    }

    eventSource.onerror = event => {
      if (event.target.readyState === EventSource.CONNECTING) {
        console.log('Channel: Reconnecting...')
        eventSource.close()
        setConnectFailed(true)
      } else if (event.target.readyState === EventSource.CLOSED) {
        console.log('Channel: Connection failed permanently. Reconnecting...')
        restart(() => {
          resendApi(venue, dispatch)
        })
      }
    }
  }, [eventSource, venue, dispatch])

  const api = useMemo(
    () => ({
      start,
      restart
    }),
    [start, restart]
  )

  return [eventSource, api]
}
