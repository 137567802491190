import { Typography } from 'src/components/shared/ExMaterial'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from 'src/assets/btn-close-24.svg'
import s from './Style.module.css'
// {
//     type: 'banner'
//     autohide: 10, // время жизни сообщения в секундах, 0 перманентно (убирается по клику)
//     content: 'текст',
//     color: '#ffffff',
//     background: '#000000'
// }
// - Нет доступа (у роли нет виртуального экшена FRONTOFFICE_CASHIER)
// - Нет доступа к данному заведению (есть экшен, но не совпал венью)
// - неизвестная ошибка авторизации (что-то пошло не так)

const SnackBannerContainer = ({
  settings = { autohide: 5, content: 'default text', color: '#ffffff', background: '#000000' },
  handleRemove
}) => {
  useEffect(() => {
    if (settings && settings.autohide) {
      setTimeout(() => handleRemove(), settings.autohide * 1000)
    }
  }, [])
  //   const dishes = useStoreSelector(selectAllDishes)

  // console.log(store)

  return (
    // <Provider store={store}>
    <SnackBanner
      text={settings.content}
      color={settings.color}
      background={settings.background}
      closable={!settings.autohide}
      handleRemove={handleRemove}
    />
    // {/* </Provider> */}
  )
}

const SnackBanner = ({ text, color, background, closable, handleRemove }) => {
  return (
    <div style={{ color, background }} className={s.banner}>
      <Typography variant='body1' style={{ color }}>
        {text}
      </Typography>
      {closable ? <CloseIcon className={s.close} onClick={handleRemove}></CloseIcon> : ''}
    </div>
  )
}

export default SnackBannerContainer
