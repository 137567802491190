export const SET_VENUE_GENERAL = 'SET_VENUE_GENERAL'
export const SET_CARD_GENERAL = 'SET_CARD_GENERAL'
export const SET_SETTINGS_GENERAL = 'SET_SETTINGS_GENERAL'
export const SET_LOGIN_GENERAL = 'SET_LOGIN_GENERAL'
export const SET_STATS_GENERAL = 'SET_STATS_GENERAL'
export const SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'
export const TOGGLE_ORDER_BOARD_ACTIVE = 'TOGGLE_ORDER_BOARD_ACTIVE'
export const HANDLE_BRAND_INTEGRATION_STATUS = 'HANDLE_BRAND_INTEGRATION_STATUS'
