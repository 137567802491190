import React from 'react'
import styles from './styles.module.scss'
import ClickOutsideListener from '../shared/ClickOutsideListener'

export default function ModalWindow({ handleClose, children }) {
  return (
    <div className={`${styles.wrapper}`}>
      <ClickOutsideListener onClickOutside={handleClose}>{children}</ClickOutsideListener>
    </div>
  )
}
