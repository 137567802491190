import React, { useEffect } from 'react'
import StoplistHeader from '../Stoplist/StoplistHeader'
import OrderMoveContent from './OrderMoveContent'
import classes from './OrderMove.module.css'
import { useSelector } from 'react-redux'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import { useCashierSSE } from '../../../hooks/useCashierSSE'

const OrderMove = () => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  useCashierSSE()
  useTimerPing()
  useEffect(() => {
    document.title = 'Менеджер'
  }, [])
  const [isHeader, setHeader] = React.useState(true)
  const Title = () => (
    <div className={classes.title} color='inherit'>
      {translations.findPayment}
    </div>
  )

  return (
    <>
      {isHeader ? <StoplistHeader title={<Title />} /> : ''}
      <OrderMoveContent setHeader={setHeader} />
    </>
  )
}

export default OrderMove
