let lastClick = false

export const soundEffect = new Audio()

export const getLastClick = () => lastClick

export const updateLastClick = () => {
  lastClick = new Date().getTime()
}

soundEffect.src = '/audio/sound.mp3'
document.addEventListener('click', e => soundEffect.play() + soundEffect.pause(), { once: true })
