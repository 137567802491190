import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGeneralSettings, getGeneralVenue } from 'src/state/general'
import { getMessages } from 'src/state/msgs'
import { getPayments } from 'src/state/payments'
import { getGeneralErrors, removeError } from 'src/state/status'
import { getVenues } from 'src/state/venues'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getPaymentsLoading } from 'src/state/payments/selectors'
import Spinner from 'src/components/shared/Spinner'
import ManualPostingModal from 'src/components/modals/ManualPostingModal'
import { activatePayment } from 'src/state/payments/actions'
import translationsCashier from 'src/translations/cashier'
import { getSelectedLanguage } from 'src/state/general/selectors'
import { useCashierSSE } from 'src/hooks/useCashierSSE'
import useIntegrationApi from 'src/hooks/useIntegrationApi'
import usePaymentApi from 'src/hooks/usePaymentApi'
import { updateLastClick } from 'src/utils/soundEffect'
import { CashierPopup } from './CashierPopup'
import { CashierSettings } from './CashierSettings'
import { Contact } from './Contact'
import { Documentation } from './Documentation'
import { Header } from './Header'
import { LogPaymentList } from './LogPaymentList'
import { MessageOrder } from './MessageOrder'
import { PaymentList } from './PaymentList'
import s from './Cashier.module.css'

export const Cashier = () => {
  useEffect(() => {
    document.title = 'Кассир'
  }, [])
  useCashierSSE()
  useTimerPing()
  const venues = useSelector(getVenues)
  const venueSlug = useSelector(getGeneralVenue)
  const payments = useSelector(getPayments)
  const paymentsLoading = useSelector(getPaymentsLoading)
  const dispatch = useDispatch()
  const generalErrors = useSelector(getGeneralErrors)
  const settings = useSelector(getGeneralSettings)
  const msgs = useSelector(getMessages)
  const [isIntegration, setIsIntegration] = useState(false)
  const [history, showHistory] = useState(false)
  const [manualModalReason, setManualModalReason] = useState('')
  const [currentManualPayment, setCurrentManualPayment] = useState(null)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]

  const [popup, showPopup] = useState(false)

  const [, { integrateFinalOrder }] = useIntegrationApi()
  const [, { sendPaymentStatus }] = usePaymentApi()

  useEffect(() => {
    if (currentManualPayment) {
      if (currentManualPayment.integrationStatus === 'error') {
        setManualModalReason(translations.manualModalReason.integrationError)
      } else if (currentManualPayment.status.integrationCanceled) {
        setManualModalReason(translations.manualModalReason.integrationCancelled)
      } else {
        setManualModalReason(translations.manualModalReason.unpaider)
      }
    }
  }, [currentManualPayment])

  useEffect(() => {
    if (isIntegration) {
      if (payments.length > 0) {
        payments
          .filter(x => x.status.integrationStarted && !x.status.integrationConfirmed && !x.status.integrationCanceled)
          .forEach(x => {
            if (x.status && x.status.integrationStarted) {
              integrateFinalOrder({ order_id: x.info.integration_id, payment: x })
            }
          })
      }
    }
  }, [isIntegration])

  const handleManualModalClose = () => setCurrentManualPayment(null)
  const handleManualPayment = payment => setCurrentManualPayment(payment)

  const handleManualModalConfirm = useCallback(() => {
    dispatch(activatePayment(currentManualPayment))
    // sendPaymentStatus({ paymentId: currentManualPayment.id, status: 'cashierConfirmed' })
    sendPaymentStatus({ paymentId: currentManualPayment.id, status: 'cashierClosed' })
    handleManualModalClose()
  }, [currentManualPayment, sendPaymentStatus])

  const standalone = window.navigator.standalone === true

  const isMobile = window.innerWidth <= 525
  if (!venues || venues.length < 1) return null
  const venue = venues.filter(e => e.slug === venueSlug)[0]

  return (
    <div className={s.wrapper} onClick={updateLastClick}>
      {currentManualPayment && (
        <ManualPostingModal
          reason={manualModalReason}
          handleConfirm={handleManualModalConfirm}
          handleClose={handleManualModalClose}
        />
      )}

      {msgs.map((msg, index) => {
        return <MessageOrder venueSlug={venueSlug} paymentMessage={msg} index={index + 1} key={index} />
      })}
      <Contact />
      {generalErrors.map((x, i) => (
        <CashierPopup key={x && x.id ? x.id : i} onCloseButton={() => dispatch(removeError(x))}>
          {x.text}
        </CashierPopup>
      ))}
      <Header {...{ venue, standalone, popup, showPopup, setIsIntegration, history, showHistory }} />
      <div className={s.flex}>
        {paymentsLoading && <Spinner />}
        {popup ? <Documentation {...{ showPopup }} /> : null}
        {!isMobile ? (
          <>
            <div className={s.main + (' ' + s.historyMain)}>
              <PaymentList
                {...{
                  venueSlug,
                  payments,
                  handleManualPayment
                }}
              />
            </div>
            <div className={s.logWrapper}>
              <LogPaymentList {...{ venueSlug, payments }} />
            </div>
          </>
        ) : isMobile && history ? (
          <div className={[s.logWrapper, s.mobileHistory].join(' ')}>
            <LogPaymentList tabs {...{ venueSlug, payments }} />
          </div>
        ) : (
          <div className={s.main + (' ' + s.historyMain)}>
            <PaymentList
              {...{
                venueSlug,
                payments,
                handleManualPayment
              }}
            />
          </div>
        )}
        {settings === 1 ? <CashierSettings /> : ''}
      </div>
    </div>
  )
}
