import React, { useEffect } from 'react'
import StoplistHeader from '../Stoplist/StoplistHeader'
import StatsContent from './StatsContent'
import classes from './Stats.module.scss'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { useCashierSSE } from '../../../hooks/useCashierSSE'
const Stats = ({ ...props }) => {
  useCashierSSE()
  useTimerPing()
  useEffect(() => {
    document.title = 'Менеджер'
  }, [])

  const Title = () => (
    <div className={classes.title} color='inherit'>
      <div style={{ textTransform: 'none' }} className={classes.flex1}>
        <div className={classes.topText}>Статистика</div>
      </div>
    </div>
  )

  return (
    <>
      <StoplistHeader title={<Title />} />
      <StatsContent />
    </>
  )
}

export default Stats
