import { CONTECT_SHOW, CONTECT_HIDE } from './types'

export const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTECT_SHOW: {
      const { content } = action.payload
      return content
    }

    case CONTECT_HIDE: {
      return false
    }

    default:
      return state
  }
}
