import { FETCH_VENUES_SUCCESS, FETCH_VENUES_START, FETCH_VENUES_ERROR, DISABLE_VENUE } from './types'

const initialState = {
  list: [],
  loading: false,
  error: null
}

export default function venuesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VENUES_START: {
      return {
        ...state,
        error: null,
        loading: true
      }
    }
    case FETCH_VENUES_SUCCESS: {
      const { venues } = action.payload
      return {
        ...state,
        list: venues,
        loading: false
      }
    }
    case FETCH_VENUES_ERROR: {
      const { errorMessage } = action.payload
      return {
        ...state,
        loading: false,
        error: errorMessage
      }
    }

    case DISABLE_VENUE: {
      const status = action.payload
      const currentVenue = localStorage.getItem('currentVenue')
      return state.map(e => {
        if (e.slug === currentVenue) {
          return { ...e, disabled: status }
        } else {
          return e
        }
      })
    }

    default:
      return state
  }
}
