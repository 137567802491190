import { ADD_ROLES } from './types'

export const initialState = []

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ROLES: {
      const { roles } = action.payload
      return roles
    }

    default:
      return state
  }
}
