import general from './reducers'

export {
  setVenueGeneral,
  setCardGeneral,
  setSettingsGeneral,
  setLoginGeneral,
  setStatsGeneral,
  toggleOrderBoardActive,
  handleIntegrationStatus
} from './actions'
export {
  getGeneral,
  getGeneralVenue,
  getGeneralCard,
  getGeneralSettings,
  getGeneralLogin,
  getGeneralStats,
  getBrandIntegrationStatus
} from './selectors'

export default general
