import { useMemo, useState } from 'react'
import { useInterval } from './useInterval'

export default function useCurrentTime() {
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')
  const [time, setTime] = useState('')

  useInterval(
    () => {
      const date = new Date()
      const h = date.getHours()
      const m = date.getMinutes()
      const s = date.getSeconds()

      setHours(h < 10 ? `0${h}` : h)
      setMinutes(m < 10 ? `0${m}` : m)
      setSeconds(s < 10 ? `0${s}` : s)
      setTime(`${h}:${m}:${s}`)
    },
    1000,
    [setHours, setMinutes, setSeconds, setTime]
  )

  const state = useMemo(
    () => ({
      hours,
      minutes,
      seconds,
      time
    }),
    [hours, minutes, seconds, time]
  )

  return state
}
