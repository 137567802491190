import React from 'react'
import s from './Cashier.module.css'

const Loader = () => {
  return (
    <div className={s['lds-ring']}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
