import { TOGGLE_STOPLISTONLY } from './types'

export const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_STOPLISTONLY: {
      return !state
    }

    default:
      return state
  }
}
