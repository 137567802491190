import { fetchVenuesError, fetchVenuesStart, fetchVenuesSuccess } from './actions'
import request from '../../api/request'
import { setIsAuthorized } from '../general/actions'
import { setNeedRestart } from '../status/actions'

export const getVenuesThunk = () => async dispatch => {
  dispatch(fetchVenuesStart())
  try {
    const result = await request('/venues')
    if (result.data) {
      dispatch(fetchVenuesSuccess(result.data))
    }
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      dispatch(fetchVenuesError('Auth error!'))
      dispatch(setIsAuthorized(false))
    } else if ([404, 400].includes(e.response.status) === false) {
      dispatch(fetchVenuesError('Network error'))
      dispatch(setNeedRestart(true))
    }
  }
}
