import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { CardMedia } from 'src/components/shared/ExMaterial'
import { getOptionsets } from '../../../state/dish/selectors'
import { getDishById } from '../../../state/dishes'
import { getStoplist, getStoppedDish } from '../../../state/stoplist'
import { t } from '../../../utils'
import Badge from '../Stoplist/Badge'
import classes from './DishCard.module.css'
import { getSelectedLanguage } from 'src/state/general/selectors'

const DishCard = ({ dishId, onClick }) => {
  const stoplist = useSelector(getStoplist)
  const dish = useSelector(getDishById)(dishId)
  const stoppedDish = useSelector(getStoppedDish)(dishId)
  const optionsets = useSelector(getOptionsets)
  const selectedLanguage = useSelector(getSelectedLanguage)

  useEffect(() => {
    document.title = 'Менеджер'
  }, [])

  const title = (() => (dish.label ? dish.label : t(dish.name, selectedLanguage)))()

  const link = `/dish/${dish.id}`

  const options = optionsets
    .filter(e => dish.recipeId === e.recipeId)
    .map(e => e.options)
    .reduce((acc, val) => acc.concat(val), [])
    .filter(option => stoplist.options.filter(e => e === option.modifierId).length > 0)

  return (
    <Link
      to={link}
      className={[classes.card, classes.title, stoppedDish ? classes.stoplisted : ''].join(' ')}
      onClick={() => {
        // onClick()
      }}
    >
      <div className={classes.flex}>
        <div className={classes.flexD}>
          <div className={stoppedDish ? classes.fontSmall : classes.fontSmall2}>
            {t(dish.categoryName, selectedLanguage)}
            {dish.subcategoryName && ` > ${t(dish.subcategoryName, selectedLanguage)}`}
          </div>
          <div className={classes.font}>{title}</div>
        </div>
      </div>
      <div style={{ marginBottom: '4px' }} className={classes.flex}>
        {stoppedDish?.permanent ? <Badge variant='orange' value={'Перманентно'} /> : ''}
        {options ? <Badge variant={stoppedDish ? 'orange' : 'white'} value={options.length} /> : ''}
      </div>
    </Link>
  )
}

export default DishCard
