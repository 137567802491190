import { CONTECT_SHOW, CONTECT_HIDE } from './types'

export const showContect = content => ({
  type: CONTECT_SHOW,
  payload: { content }
})

export const hideContect = _ => ({
  type: CONTECT_HIDE
})
