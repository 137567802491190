import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getReadableDate } from 'src/utils'
import { historyStatus, historyStatusIsError } from 'src/components/cashier/CashierView/LogPaymentList'
import { ReactComponent as ByhandIconRed } from 'src/assets/ico-byhand-red.svg'
import { ReactComponent as ByhandIcon } from 'src/assets/ico-byhand.svg'
import { ReactComponent as TimedTakeoutIcon } from 'src/assets/ico-deffered_togo-24-n.svg'
import { ReactComponent as TimedTakeoutIconW } from 'src/assets/ico-deffered_togo-24-white.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as IntegrationIcon } from 'src/assets/ico-integration-ok.svg'
import { ReactComponent as IntegrationErrorIcon } from 'src/assets/ico-integration.svg'
import { ReactComponent as NameIcon } from 'src/assets/ico-name-16.svg'
import { ReactComponent as PhoneIcon } from 'src/assets/ico-phone-16.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as RefundIcon } from 'src/assets/refund.svg'
import { decoratePhone } from 'src/shared/utils/phone'
import { getRoles } from 'src/state/roles'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import s from './ShortPayment.module.css'
import { isDeliveryOrders, isTakeoutOrders } from '../../utils/payments'

const RefundBadge = ({ children }) => <div className={s.refundBadge}>возврат{children}</div>

export const ShortPayment = ({
  payment,
  isMoney,
  isVenue,
  isStatuses,
  variant,
  isRefund,
  isUnpaider,
  onClick = () => {}
}) => {
  const history = useHistory()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const tt = getReadableDate(payment.timedTakeout)
  const month = tt.date.replace(/[^a-z]/g, '')
  const pa = getReadableDate(payment.paidAt)
  const unpaiders = useSelector(getRoles)

  let statusClass = ''
  if (isStatuses) {
    statusClass =
      (payment.status.integrationStarted || payment.integrationStatus === 'started') &&
      !payment.status.cashierConfirmed &&
      !payment.status.integrationConfirmed
        ? payment.status.integrationStarted ||
          payment.integrationStatus === 'started' ||
          payment.status.integrationCanceled ||
          payment.integrationStatus === 'error'
          ? s.logPaymentStarted
          : s.logPaymentInactive
        : s.logPaymentInactive
  }
  if (variant === 'black') {
    statusClass += ' ' + s.logPaymentInactive
  }
  let isTrueUnpaider = false
  if (isUnpaider) {
    isTrueUnpaider = unpaiders.filter(e => payment.userRole === e.key).length > 0
  }

  const isPaid = !isStatuses && !payment.isPaid ? s.unpaidContainer : ''

  const renderPaymentDate = useCallback(dateTime => {
    const { time, date } = getReadableDate(dateTime)
    const [day, month] = date.split(' ')
    return (
      <>
        {date ? ` ${day} ${translations[month]}, ` : <></>}
        {time}
      </>
    )
  }, [])

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick(payment)
        }
      }}
      className={[s.paymentContainerWrapper, isPaid, statusClass].join(' ')}
    >
      {isRefund && <RefundBadge />}
      <div className={[s.paymentContainer, !isTrueUnpaider ? s.extraPadding : s.childrenPadding].join(' ')}>
        <div className={[s.paymentP1, s.mbot8, isTrueUnpaider ? s.unpaiderHeader : ''].join(' ')}>
          <div className={s.paymentP2}>
            <NameIcon className={s.stroking} />
            <div className={[s.paymentText1, s.mleft4].join(' ')}>
              {payment.userName
                ? payment.userPhone
                  ? payment.userName.length > 8
                    ? payment.userName.slice(0, 7) + '…'
                    : payment.userName
                  : payment.userName
                : translations.withoutName}
            </div>
          </div>
          <div className={s.paymentP2}>
            <PhoneIcon className={s.stroking} />
            <div className={[s.paymentText1, s.mleft4].join(' ')}>
              {decoratePhone(payment.userPhone) || translations.withoutPhone}
            </div>
          </div>
        </div>
        <div className={[s.paymentP1, s.mbot8].join(' ')}>
          <div className={s.paymentP2}>
            {isDeliveryOrders(payment) ? (
              <DeliveryIcon className={s.filling} width='24' height='24' />
            ) : !payment.timedTakeout ? (
              <>
                {isTakeoutOrders(payment) && <TogoIcon className={s.stroking} width='24' height='24' />}
                {Boolean(payment.tableNum) && (
                  <TableIcon className={[s.mleft8, s.filling].join(' ')} width='24' height='24' />
                )}
              </>
            ) : !!payment.isPaid || variant === 'black' ? (
              <TimedTakeoutIcon width='24' height='24' />
            ) : (
              <TimedTakeoutIconW width='24' height='24' />
            )}
            <div className={[s.paymentText2, s.mleft4].join(' ')}>
              {payment.tableNum ? payment.tableNum + ' / ' : ''}
              {payment.takeoutNum}
            </div>
          </div>
          <div className={s.paymentWrap}>
            {isMoney ? <div className={s.paymentText2}>{payment.amountBeforeDiscount / 100} ₴</div> : ''}
            <>
              {historyStatus(payment) === 'integration' || historyStatus(payment) === 'started' ? (
                <IntegrationIcon
                  width={16}
                  height={16}
                  fill={historyStatus(payment) === 'started' ? '#00E2FF' : 'inherit'}
                  className={s.filling}
                  style={{ marginLeft: '8px' }}
                />
              ) : historyStatus(payment) === 'error' ? (
                <IntegrationErrorIcon width={16} height={20} style={{ marginLeft: '8px' }} />
              ) : historyStatus(payment) === 'cashier' && !historyStatusIsError(payment) ? (
                <ByhandIcon className={s.stroking} width={16} height={20} style={{ marginLeft: '8px' }} />
              ) : historyStatus(payment) === 'cashier' ? (
                <ByhandIconRed className={s.stroking} width={16} height={20} style={{ marginLeft: '8px' }} />
              ) : (
                ''
              )}
            </>
          </div>
        </div>
        <div className={s.paymentP1}>
          <div className={s.paymentText1}>
            {payment.timedTakeout ? renderPaymentDate(payment.timedTakeout) : pa.time}
          </div>
          {isVenue ? <div className={s.paymentText1}>{payment.venuesName}</div> : ''}
        </div>
      </div>
    </div>
  )
}
