import { SHOW_SNACKBAR, HIDE_SNACKBAR } from './types'

export const showSnackbar = content => ({
  type: SHOW_SNACKBAR,
  payload: { content }
})

export const hideSnackbar = _ => ({
  type: HIDE_SNACKBAR
})
