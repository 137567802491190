import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import translationsDishboard from 'src/translations/dishboard'
import { ReactComponent as HlebIcon } from 'src/assets/ico-ordersBoard.svg'
import { getSelectedLanguage } from 'src/state/general/selectors'
import OrderCard from './OrderCard'
import { getDishboardPayments } from 'src/state/payments'
import { useInterval } from 'src/hooks/useInterval'
import { toggleOrderBoardActive } from 'src/state/general/actions'

const OrdersBoard = () => {
  let actualPayments = useSelector(getDishboardPayments)
  const dispatch = useDispatch()
  let payments = []
  let paymentsHere = actualPayments.filter(x => x.tableNum)
  let paymentsTakeout = actualPayments.filter(x => !x.tableNum && !x.delivery)
  let paymentsDelivery = actualPayments.filter(x => x.delivery)
  if (localStorage.getItem('__runner_settings_check4') === 'true') {
    payments = paymentsHere
  }
  if (localStorage.getItem('__runner_settings_check5') === 'true') {
    payments = [...payments, ...paymentsTakeout]
  }
  if (localStorage.getItem('__runner_settings_check6') === 'true') {
    payments = [...payments, ...paymentsDelivery]
  }
  if (localStorage.getItem('__runner_settings_check2') !== 'true') {
    payments = payments.filter(x => !x.orders.every(y => y.status.ready))
  }
  if (localStorage.getItem('__runner_settings_check1') === 'true') {
    payments = payments.filter(x => x.timedTakeout)
  }
  payments = payments
    .filter(x => x.status && !x.status.finished)
    .sort((b, a) => {
      const atime = a.timedTakeout ? Date.now() - new Date(a.timedTakeout) : new Date(a.status.confirmed)
      const btime = b.timedTakeout ? Date.now() - new Date(b.timedTakeout) : new Date(b.status.confirmed)
      return atime - btime
    })
    .sort((a, b) => {
      if (!a.timedTakeout && !b.timedTakeout) {
        return new Date(a.status.confirmed) - new Date(b.status.confirmed)
      }
    })
  if (localStorage.getItem('__runner_settings_check3') === 'true') {
    payments = payments.filter(
      x =>
        x.comment.indexOf('Готовить напитки сразу') >= 0 ||
        x.comment.indexOf('Готувати напої одразу') >= 0 ||
        x.comment.indexOf('Make drinks immediately') >= 0
    )
  }

  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsDishboard[selectedLanguage]
  const [maxVisibleElements, setMaxVisibleElements] = useState(0)
  const [currentConfirmedPage, setCurrentConfirmedPage] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [currentReadyPage, setCurrentReadyPage] = useState(1)
  const [isCloseIconVisible, setIsCloseIconVisible] = useState(false)
  const containerRef = useRef(null)
  const timeoutRef = useRef(null)

  const confirmedPayments = payments.filter(
    payment => payment.status?.confirmed && !payment?.orders?.every(order => order?.status?.ready)
  )

  const readyPayments = payments.filter(
    payment => payment.status?.confirmed && payment?.orders?.every(order => order?.status?.ready)
  )

  useInterval(
    () => {
      if (currentConfirmedPage * maxVisibleElements < confirmedPayments.length) {
        setCurrentConfirmedPage(currentConfirmedPage + 1)
      } else {
        setCurrentConfirmedPage(1)
      }

      if (currentReadyPage * maxVisibleElements < readyPayments.length) {
        setCurrentReadyPage(currentReadyPage + 1)
      } else {
        setCurrentReadyPage(1)
      }
    },
    7500,
    [currentConfirmedPage, maxVisibleElements, confirmedPayments, currentReadyPage, readyPayments]
  )

  useLayoutEffect(() => {
    document.addEventListener('fullscreenchange', onFullScreenChange)
    document.addEventListener('click', handleCloseIconVisible)
    return () => {
      document.removeEventListener('fullscreenchange', onFullScreenChange)
      document.removeEventListener('click', handleCloseIconVisible)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleCloseIconVisible)
    return () => {
      document.removeEventListener('click', handleCloseIconVisible)
    }
  }, [timeoutRef.current])

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const container = containerRef.current
      const colsDivision = container.offsetWidth / 293
      const rowsDivision = container.offsetHeight / 154
      const maxCol = colsDivision % 1 > 0.9 ? Math.round(colsDivision) : Math.floor(colsDivision)
      const maxRows = rowsDivision % 1 > 0.9 ? Math.round(rowsDivision) : Math.floor(rowsDivision)

      const maxEl = maxCol * maxRows

      if (maxEl !== maxVisibleElements) {
        setMaxVisibleElements(maxEl - 1)
      }
    }
  }, [containerRef])

  const openFullscreen = elem => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen()
    }
  }

  const handleCloseIconVisible = () => {
    setIsCloseIconVisible(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setIsCloseIconVisible(false)
    }, 10000)
  }

  const onFullScreenClicked = () => {
    const elem = document.getElementById('order-board-container')
    openFullscreen(elem)
  }
  const onFullScreenChange = () => {
    document.fullscreen ? setIsFullScreen(true) : setIsFullScreen(false)
  }

  const handleCloseView = () => {
    dispatch(toggleOrderBoardActive(false))
    localStorage.setItem('viewType', 'dishBoard')
  }

  const renderMoreButton = (arr, page) => {
    if (maxVisibleElements * page < arr.length) {
      return (
        <div className={styles.moreWrapper}>
          {arr.length - page * maxVisibleElements} {translations.more}..
        </div>
      )
    }
  }

  const renderFullWidthIcon = () => {
    return (
      <div className={styles.fullWidthIconWrapper}>
        <div
          onClick={e => {
            e.stopPropagation()
            !isFullScreen ? onFullScreenClicked() : document.exitFullscreen()
          }}
        ></div>
      </div>
    )
  }

  const renderCloseIcon = () => {
    return (
      <div style={{ opacity: isCloseIconVisible ? 1 : 0 }} className={styles.closeIconWrapper}>
        <div onClick={handleCloseView}></div>
      </div>
    )
  }

  const renderConfirmedPayments = () => {
    if (maxVisibleElements < confirmedPayments.length) {
      return confirmedPayments
        .filter((p, i) => {
          return currentConfirmedPage === 1
            ? i < maxVisibleElements
            : i >= maxVisibleElements * (currentConfirmedPage - 1) && i < maxVisibleElements * currentConfirmedPage
        })
        .map(payment => <OrderCard key={payment.id} orderNumber={payment.takeoutNum} />)
    }

    return confirmedPayments.map(payment => <OrderCard key={payment.id} orderNumber={payment.takeoutNum} />)
  }

  const renderReadyPayments = () => {
    if (maxVisibleElements < readyPayments.length) {
      return readyPayments
        .filter((p, i) => {
          return currentReadyPage === 1
            ? i < maxVisibleElements
            : i >= maxVisibleElements * (currentReadyPage - 1) && i < maxVisibleElements * currentReadyPage
        })
        .map(payment => <OrderCard key={payment.id} orderNumber={payment.takeoutNum} isReady />)
    }

    return readyPayments.map(payment => <OrderCard key={payment.id} orderNumber={payment.takeoutNum} isReady />)
  }

  return (
    <div id='order-board-container' className={styles.wrapper}>
      {renderFullWidthIcon()}
      {renderCloseIcon()}
      <div className={styles.header}>
        <div>
          <HlebIcon />
          <h2>{translations.preparing}</h2>
        </div>
        <div>
          <h2>{translations.ready}</h2>
          <HlebIcon />
        </div>
      </div>
      <div className={styles.ordersWrapper}>
        <div className={styles.ordersSection}>
          <div ref={containerRef} className={styles.ordersInnerView}>
            {renderConfirmedPayments()}
            {renderMoreButton(confirmedPayments, currentConfirmedPage)}
          </div>
        </div>
        <div className={styles.ordersSection}>
          <div className={styles.ordersInnerView}>
            {renderReadyPayments()}
            {renderMoreButton(readyPayments, currentReadyPage)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(OrdersBoard)
