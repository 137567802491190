import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { config } from 'src/constants/url'
import { useDispatch } from 'react-redux'

import { setIsAuthorized } from 'src/state/general/actions'

/**
 * This function makes request to API to
 * allow API set token in cookies
 * @param {string} token
 * @returns { response | error }
 */
export async function setupTokenAuthInCookies(token) {
  return await axios(`${config.API_URL3}/${window.busytable.brandSlug}/auth/token/set`, {
    method: 'POST',
    data: {},
    withCredentials: true,
    headers: {
      'x-url': window.location.href,
      verified: 'yes',
      token
    }
  })
}

/**
 * This function is repsonsible for SSE channel creation and listening during login
 * @param {integer} venue
 * @param {string} app
 * @returns { messages, banner, token, setBanner }
 */
export function useSocketAuth(venue, app) {
  const socketRef = useRef(false)
  const dispatch = useDispatch()
  const [messages, setMessages] = useState(null)
  const [banner, setBanner] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    /**
     * Do nothing if cannot get selected venue
     */
    const parsedVenueID = parseInt(venue, 10)
    if (isNaN(parsedVenueID) || parsedVenueID <= 0) {
      return () => {
        closeChannel()
      }
    }

    /**
     * Channel events handlers
     */
    function handleEventChannelError(event) {
      if (event.target.readyState === socketRef.current.CONNECTING) {
        console.log('Channel: Reconnecting...')
      } else if (event.target.readyState === socketRef.current.CLOSED) {
        closeChannel()
      }
    }

    function handleChannelEventNonce(event) {
      const nonce = JSON.parse(event.data)

      setMessages(nonce)
    }

    function handleChannelEventSetToken(event) {
      const token = JSON.parse(event.data)

      async function proceedWithAuthorization() {
        const res = await setupTokenAuthInCookies(token)

        if (res) {
          dispatch(setIsAuthorized(true))
          window.location.reload()
        }
      }

      proceedWithAuthorization()
    }

    function handleChannelEventMessage(event) {
      const payload = JSON.parse(event.data)

      setBanner(payload)
    }

    /**
     * @todo move strings to enums
     */
    function setupSSEChannelListeners() {
      socketRef.current.addEventListener('nonce', handleChannelEventNonce)
      socketRef.current.addEventListener('setToken', handleChannelEventSetToken)
      socketRef.current.addEventListener('message', handleChannelEventMessage)
    }

    function removeSSEChannelListeners() {
      socketRef.current.removeEventListener('nonce', handleChannelEventNonce)
      socketRef.current.removeEventListener('setToken', handleChannelEventSetToken)
      socketRef.current.removeEventListener('message', handleChannelEventMessage)

      socketRef.current.close()
    }

    function setupChannelEventHandlers() {
      socketRef.current.onopen = setupSSEChannelListeners
      socketRef.current.closeEvents = removeSSEChannelListeners
      socketRef.current.onerror = handleEventChannelError
    }

    function createEventSource() {
      const params = { withCredentials: true }
      const currentApp = app === 'stoplist' ? 'manager' : app
      const authChannel = `${config.API_URL3}/${window.busytable.brandSlug}/auth/channel/${currentApp}/${venue}`
      socketRef.current = new EventSource(authChannel, params)
      socketRef.current.__url = authChannel

      setupChannelEventHandlers()
    }

    function closeChannel() {
      if (socketRef?.current) {
        socketRef.current.close()
      }
    }

    createEventSource()

    return () => {
      closeChannel()
    }
  }, [venue])

  /** @todo optimize this hook input and output */
  return { messages, banner, token, setBanner }
}
