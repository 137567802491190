import { FETCH_PAID_ORDERS, CONFIRM_ORDER, ADD_ORDER } from './types'

/* State Shape
{

}
*/

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PAID_ORDERS: {
      const { orders } = action.payload
      return orders
    }

    case CONFIRM_ORDER: {
      const { order } = action.payload
      return state.map(e => (e.id === order.id ? { ...e, status: 'ready' } : e))
    }

    case ADD_ORDER: {
      const { order } = action.payload
      return [...state, order]
    }

    default:
      return state
  }
}
