import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getTopCategoriesWithNestedDishes } from 'src/state/categories'
import { getOptionsets } from 'src/state/dish'
import { getDishesIds, getDishesOriginal } from 'src/state/dishes'
import { getStoplist } from 'src/state/stoplist/selectors'
import { getStoplistOnly, toggleStoplistOnly } from 'src/state/stoplistonly'
import { getVenueBySlug, getVenues } from 'src/state/venues'
import { filterDuplicates, t } from 'src/utils'
import Badge from './Badge'
import StoplistContent from './StoplistContent'
import StoplistHeader from './StoplistHeader'
import classes from './Stoplist.module.scss'
import { Switch } from 'src/components/shared/ExMaterial'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import { useCashierSSE } from '../../../hooks/useCashierSSE'

const Stoplist = () => {
  useCashierSSE()
  useTimerPing()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const dispatch = useDispatch()
  const venueSlug = localStorage.getItem('currentVenue')
  const { catId } = useParams()
  // const catId = 1
  const stoplist = useSelector(getStoplist)
  const optionsets = useSelector(getOptionsets)
  const venues = useSelector(getVenues)
  const venue = useSelector(getVenueBySlug)(venueSlug)
  const allDishes = useSelector(getDishesIds)
  const allDishesOriginal = useSelector(getDishesOriginal)
  const topCategories = useSelector(getTopCategoriesWithNestedDishes)(venue.menuId)

  const initialCheck =
    venues && venues.length > 0 && venues.filter(v => v.slug === venueSlug).length > 0
      ? !venues.filter(v => v.slug === venueSlug)[0].disabled
      : true

  const [state, setState] = React.useState({
    checkedA: initialCheck
  })

  useEffect(() => {
    setState({ ...state, checkedA: initialCheck })
  }, [venues])

  useEffect(() => {
    document.title = 'Менеджер'
  }, [])

  const catIdParsed = catId ? (catId === 'stoplist' ? catId : parseInt(catId)) : false
  const stoplistOnly = useSelector(getStoplistOnly)
  if (!topCategories.length) return <StoplistHeader {...{ venueSlug }} />

  // const getAllDishes = () => topCategories.flatMap(x => x.dishIds)

  const getDishesById = dishes => {
    return allDishesOriginal.filter(x => dishes.some(y => parseInt(y) === parseInt(x.id)))
  }

  const getDishesInCategory = id => {
    const category = topCategories.find(x => x.id === Number(id))
    return category ? category.dishIds : []
  }

  const getOptionByDish = dish => {
    return optionsets
      .filter(e => dish.recipeId === e.recipeId)
      .map(e => e.options)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(option => stoplist.options.filter(e => e === option.modifierId).length > 0)
  }

  const getOptionsFunc = forceStoplist => {
    const dishIds = catIdParsed ? getDishesInCategory(catIdParsed) : allDishes
    return !(forceStoplist || stoplistOnly)
      ? []
      : getDishesById(dishIds)
          .filter(x => getOptionByDish(x).length > 0)
          .map(x => x.id)
  }
  const getDishesFunc = forceStoplist => {
    const dishIds = catIdParsed ? getDishesInCategory(catIdParsed) : allDishes

    if (stoplistOnly || forceStoplist) {
      const dishesInStoplist = dishIds.filter(x => stoplist.dishes.some(y => parseInt(y.dishId) === parseInt(x)))
      const dishIdsWithoutDuplicates = dishesInStoplist
      return dishIdsWithoutDuplicates
    } else {
      return dishIds
    }
  }

  const currentDishes = filterDuplicates(getDishesFunc().concat(getOptionsFunc()))
  const handleChange = name => event => {
    dispatch(toggleStoplistOnly())
  }

  const stoplistedDishesLength = getDishesFunc(true).length
  // const stoplistedOptionsLength = getOptionsFunc(true).length

  const getName = () => {
    const result = topCategories.filter(e => e.id === catIdParsed)
    if (result.length > 0) {
      return t(result[0].name, selectedLanguage)
    }
    return translations.allCategory
  }
  if (!venues || venues.length === 0) {
    return null
  }
  const SubToolbar = () => (
    <div className={classes.subToolbar}>
      <div style={{ display: 'flex' }} color='inherit'>
        <Badge
          style={{ color: 'white' }}
          variant={'black'}
          value={
            <div className={classes.form}>
              <strong>{stoplistedDishesLength}</strong> {translations.dish}
            </div>
          }
        />
        <Badge
          style={{ color: 'white' }}
          variant={'black'}
          value={
            <div className={classes.form}>
              <strong>{stoplist.options.length}</strong> {translations.mods}
            </div>
          }
        />
      </div>
      <div className={classes.slonly}>
        {translations.slOnly}
        <Switch
          className={classes.switch}
          checked={stoplistOnly}
          onChange={handleChange('checkedA')}
          value={stoplistOnly}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </div>
    </div>
  )

  const Title = () => (
    <div className={classes.title} color='inherit'>
      <div style={{ textTransform: 'none' }} className={classes.flex1}>
        <div className={classes.topText}>{translations.stopList}</div>
        <div className={classes.bottomText}>{catIdParsed ? getName() : translations.allDishes}</div>
      </div>
    </div>
  )
  return (
    <>
      <StoplistHeader {...{ venueSlug }} title={<Title />} subToolbar={<SubToolbar />} />
      <StoplistContent {...{ venueSlug, venue, currentDishes }} />
    </>
  )
}

export default Stoplist

export const IconType = type => {
  switch (type) {
    case 'beverages':
      return '☕'
    case 'breakfast':
      return '🍳'
    case 'burger':
      return '🍔'
    case 'salad':
      return '🥒'
    case 'bakery':
      return '🥐'
    case 'bread':
      return '🍞'
    case 'alcohol':
      return '🍷'
    default:
      return '☕'
  }
}
