import { FETCH_VENUES_SUCCESS, FETCH_VENUES_START, FETCH_VENUES_ERROR, DISABLE_VENUE } from './types'

export const fetchVenuesSuccess = venues => ({
  type: FETCH_VENUES_SUCCESS,
  payload: { venues }
})

export const fetchVenuesStart = () => ({
  type: FETCH_VENUES_START
})

export const fetchVenuesError = message => ({
  type: FETCH_VENUES_ERROR,
  errorMessage: message
})

export const disableVenue = status => ({
  type: DISABLE_VENUE,
  payload: status
})
