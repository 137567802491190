import React from 'react'
import { useSelector } from 'react-redux'
import Order from './Order'
import s from './Cashier.module.css'
import load from 'src/components/shared/Load'
import { t, combineSameDishes, findId } from 'src/utils'
import { getStoplist } from 'src/state/stoplist/selectors'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getSelectedLanguage } from 'src/state/general/selectors'

const OrderList = ({ orders, paymentId, payment, setTotal, total, refund, isIntegration }) => {
  const stoplist = useSelector(getStoplist)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const paymentOrders = orders
  const dishes = useSelector(getDishesEntities)
  const [showModal, setShowModal] = React.useState(-1)
  if (Object.keys(dishes).length === 0) return null
  const filteredOrders = combineSameDishes(paymentOrders)

  const orderList = filteredOrders
    .sort((a, b) => {
      const aDish = dishes[a.dishId]
      const bDish = dishes[b.dishId]

      if (!aDish || !bDish) return 0 // временное решение, убрать

      return aDish.categoryId - bDish.categoryId || a.takeout - b.takeout
    })
    .map((order, index, arr) => {
      // console.log(order)
      const dish = index > 0 ? dishes[arr[index - 1].dishId] : false
      const dish1 = dishes[arr[index].dishId]
      let additionalName = ''
      if (dish1 && (index === 0 || dish?.categoryId !== dish1?.categoryId)) {
        // console.log(cats)
        // console.log(dish1)
        additionalName = t(dish1?.categoryName, selectedLanguage)
      }
      if (dish1 && (index === 0 || dish?.subcategoryId !== dish1?.subcategoryId)) {
        const subName = t(dish1?.subcategoryName, selectedLanguage)
        if (subName) {
          additionalName = t(dish1?.categoryName, selectedLanguage) + ' > ' + subName
        }
      }
      if (order) {
        // console.log(order.dishId)
        const isStoplisted = stoplist.dishes.filter(d => parseInt(d.dishId) === parseInt(order.dishId)).length > 0
        // console.log(order.dishId, stoplist.dishes)
        return (
          <div
            onClick={() => {
              // console.log('clik')
              // console.log(cats[0].length.undf())
            }}
            key={index + ' orderPaymentId' + paymentId}
            className={[isStoplisted === true ? s.stopBox : '', s.tobeeven].join(' ')}
          >
            {additionalName.length > 0 ? <span className={[s.subcat, s.catTabs].join(' ')}>{additionalName}</span> : ''}
            <Order
              refund={refund}
              payment={payment}
              isIntegration={isIntegration}
              isStoplisted={isStoplisted}
              showModal={index === showModal}
              setShowModal={q => {
                if (q === -1) {
                  setShowModal(-1)
                } else if (index !== showModal) {
                  setShowModal(index)
                } else {
                  setShowModal(-1)
                }
              }}
              order={order}
            />
            {/* <div>...</div> */}
          </div>
        )
      }
      return ''
    })

  return load(dishes, <div className={s.ordersContainer}>{orderList}</div>)
}

export default OrderList
