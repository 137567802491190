import { ADD_MSG, REMOVE_MSG } from './types'

export const removeMsg = msg => ({
  type: REMOVE_MSG,
  payload: { msg }
})

export const addMsg = msg => ({
  type: ADD_MSG,
  payload: { msg }
})
