import React from 'react'
import { useSelector } from 'react-redux'
import translationsCashier from 'src/translations/cashier'
import { getSelectedLanguage } from 'src/state/general/selectors'
import styles from '../styles.module.scss'
import ModalWindow from '../ModalWindow'

const ManualPostingModal = ({ reason = '', handleClose, handleConfirm }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)

  const translations = translationsCashier[selectedLanguage]

  return (
    <ModalWindow handleClose={handleClose}>
      <div className={styles.content}>
        <h2 className={styles.header}>{translations.manualModalHeader}</h2>
        <p className={styles.description}>{translations.manualModalDescription(reason)}</p>
        <div className={styles.footer}>
          <button onClick={handleConfirm}>{translations.manualModalConfirmButton}</button>
          <button onClick={handleClose}>{translations.manualModalPostButton}</button>
        </div>
      </div>
    </ModalWindow>
  )
}
export default ManualPostingModal
