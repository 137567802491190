import React from 'react'
import classes from './Stoplist.module.scss'

const Badge = props => {
  if (props.value === 0) return null
  return <div className={[classes.form2, classes[props.variant]].join(' ')}>{props.value}</div>
}

// export default connect(mapStateToProps, mapDispatchToProps)(Stoplist)
export default Badge
