const strings = {
  hlbn: {
    defaultVenue: 'ХЛЕБНЫЙ'
  },
  holy: {
    defaultVenue: 'HOLY BURGER'
  },
  yt: {
    defaultVenue: 'YELLOW TRUCK'
  },
  holyraw: {
    defaultVenue: 'HOLYBURGER special'
  },
  'no brand': {
    defaultVenue: 'NO BRAND'
  }
}

// const handler = {
//   get: function (target, name) {
//     return target.hasOwnProperty(name) ? target[name] : target['no brand']
//   }
// }

// const p = new Proxy(strings, handler)

export default strings[window.busytable.brandSlug] ? strings[window.busytable.brandSlug] : strings['no brand']
