import clsx from 'clsx'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import 'url-search-params-polyfill'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchMoveOrder, fetchRefundPayment } from 'src/api'
import { ReactComponent as SearchIcon } from 'src/assets/ico-search.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as CloseIcon } from 'src/assets/btn-close-24.svg'
import { ReactComponent as RecycleIcon } from 'src/assets/ico-recycle.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { decoratePhone } from 'src/shared/utils/phone'
import { getOptionsets } from 'src/state/dish'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getVenues } from 'src/state/venues'
import { combineSameDishes, getReadableDate, optionsToTextWithPrice, t } from 'src/utils'
import { Button, Paper } from 'src/components/shared/ExMaterial'
import { ShortPayment } from 'src/components/shared/ShortPayment'
import { getGeneralVenue } from 'src/state/general'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import useDebounce from '../../../hooks/useDebounce'
import { useRestApi } from '../../../hooks/useRestApi'
import Spinner from '../../shared/Spinner'
import { ReactComponent as RefundIcon } from '../../../assets/refund.svg'
import s from './OrderMove.module.css'

const OrderMoveContent = ({ setHeader }) => {
  const history = useHistory()
  const location = useLocation()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const venueSlug = useSelector(getGeneralVenue)
  const searchParams = new URLSearchParams(location.search)
  const [payments, setPayments] = useState([])
  const venues = useSelector(getVenues)
  const [days, setDays] = useState(searchParams.get('days') || 1)
  const [venue, setVenue] = React.useState(null)
  const [searchVal, setSearchVal] = React.useState(searchParams.get('searchVal') || '')
  const [searchType, setSearchType] = React.useState(searchParams.get('searchType') || '0')
  const [selectedPayment, setSelectedPayment] = React.useState(null)
  const [markedPayment, setMarkedPayment] = React.useState(null)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const [isDialog, showDialog] = React.useState(0)
  const [dialogType, setDialogType] = React.useState(0)
  const [refund, setRefund] = useState(0)
  const [refundReason, setRefundReason] = useState('')
  const [refundError, setRefundError] = useState(null)

  const debouncedSearchTerm = useDebounce(searchVal, 500)

  const [searchState, searchApi] = useRestApi('/search')

  useEffect(() => {
    const str = debouncedSearchTerm
    const dataObj = {
      phone: searchType === '0' ? str : undefined,
      summ: searchType === '1' ? (Number.isNaN(parseFloat(str)) ? 0 : (parseFloat(str) * 100).toFixed(0)) : undefined,
      tableNum: searchType === '2' ? str : undefined,
      takeoutNum: searchType === '3' ? parseInt(str) : undefined,
      days: +days
    }

    if (debouncedSearchTerm.length > 0) {
      searchApi.sendRequest({
        data: dataObj,
        method: 'POST'
      })
    }

    const params = new URLSearchParams(location.search)
    params.set('searchType', searchType)
    params.set('searchVal', debouncedSearchTerm)
    params.set('days', days)
    window.history.replaceState(
      null,
      null,
      history.createHref({ pathname: location.pathname, search: params.toString() })
    )
  }, [debouncedSearchTerm, searchType, days, searchApi.sendRequest])

  useEffect(() => {
    if (searchState.data?.length) {
      setPayments(searchState.data.filter(x => !x.errorMessage))
    }
  }, [searchState.data])

  const venuePredicate = venues.filter(e => e.slug === venueSlug)
  if (venuePredicate.length < 1) {
    return translations.loading
  }
  const processedShow = payments.sort((a, b) => new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime())
  const unpaidShow = processedShow.filter(x => !x.isPaid)
  const paidShow = processedShow.filter(x => !!x.isPaid)
  // .filter(e => isSearched(e, searchVal, searchType))

  const handlePaymentClick = payment => {
    setMarkedPayment(payment)
    setIsConfirmed(false)
    setDialogType(0)
  }

  const handleCloseDialog = () => {
    setSelectedPayment(null)
    setMarkedPayment(null)
  }

  const handleSelectRefund = () => {
    setDialogType(1)
    setRefund(markedPayment.amountBeforeDiscount / 100)
    setHeader(false)
    setSelectedPayment(markedPayment)
    setMarkedPayment(null)
  }

  const handleSelectMove = () => {
    setDialogType(2)
    setHeader(false)
    setSelectedPayment(markedPayment)
    console.log(markedPayment)
    setMarkedPayment(null)
  }

  const handleCloseDialogRefund = () => {
    showDialog(0)
  }

  const handleCloseDialogRefundAndFetch = async () => {
    console.log('www')
    showDialog(0)
    fetchRefundPayment(
      {
        currentVenue: venueSlug,
        paymentId: parseInt(selectedPayment.paymentsId),
        sum: refund * 100,
        refundReason,
        orders: []
      },
      e => {
        console.log(e)
        if (e && e.error) {
          setRefundError(e.error)
        } else {
          showDialog(3)
        }
      }
    )
    setIsConfirmed(false)
    setHeader(true)
  }

  return (
    <div>
      {refundError !== null ? (
        <div className={s.dialogWrapper}>
          <div className={s.dialog}>
            <div className={s.dialogHeader}>{translations.errorTransferOrder}</div>
            <div className={s.dialogBody}>{refundError && refundError.message}</div>
            <div className={s.buttons}>
              <div
                onClick={() => {
                  setRefundError(null)
                }}
                className={clsx(s.enabledButton, s.matButton)}
                variant='contained'
                color='primary'
              >
                {translations.close}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isDialog === 3 ? (
        <div className={s.dialogWrapper}>
          <div className={s.dialog}>
            <div className={s.dialogHeader}>{translations.refundPayment}</div>
            <div className={s.dialogBody}>
              {translations.confirmationRefundFirstPart} <strong>№ {selectedPayment.takeoutNum}</strong>{' '}
              {translations.confirmationRefundSecondPart}
            </div>
            <div className={s.buttons}>
              <a
                href={history.createHref({ pathname: `refund-details/${selectedPayment.paymentsId}` })}
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  showDialog(0)
                  setIsConfirmed(false)
                  setSelectedPayment(null)
                  setHeader(true)
                }}
                className={s.matButton}
              >
                {translations.receipt}
              </a>
              <div
                onClick={() => {
                  showDialog(0)
                  setIsConfirmed(false)
                  setSelectedPayment(null)
                  setHeader(true)
                  window.location.reload()
                }}
                className={clsx(s.enabledButton, s.matButton)}
              >
                {translations.close}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isDialog === 2 ? (
        <div className={s.dialogWrapper}>
          <div className={s.dialog}>
            <div className={s.dialogHeader}>{translations.transferOrderDone}</div>
            <div className={s.dialogBody}>
              {translations.order} <strong>№ {selectedPayment.takeoutNum}</strong>{' '}
              {translations.successTransferOrderFrom} <strong>{selectedPayment.venuesName}</strong> {translations.in}{' '}
              <strong>
                {venues.find(x => x.id === venue) ? t(venues.find(x => x.id === venue).name, selectedLanguage) : ''}
              </strong>
            </div>
            <div className={s.buttons}>
              <div
                onClick={() => {
                  showDialog(0)
                  setIsConfirmed(false)
                  setSelectedPayment(null)
                  setHeader(true)
                  window.history.back()
                }}
                className={clsx(s.enabledButton, s.matButton)}
                variant='contained'
                color='primary'
              >
                {translations.close}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isDialog === 1 ? (
        <div className={s.dialogWrapper}>
          <div className={s.dialog}>
            <div className={s.dialogHeader}>
              <div>{translations.return}</div>
              <div>{refund}</div>
            </div>
            <div className={s.dialogBody}>{translations.reasonForRefund}</div>
            <textarea onChange={e => setRefundReason(e.target.value)} className={s.textarea} value={refundReason} />
            <div className={s.buttons}>
              <Button
                onClick={handleCloseDialogRefund}
                className={clsx(s.disabledButton, s.matButton)}
                variant='contained'
                color='primary'
              >
                {translations.back}
              </Button>
              <Button
                onClick={() => {
                  if (!!refundReason) handleCloseDialogRefundAndFetch()
                }}
                className={clsx(!refundReason ? s.disabledButton : s.enabledButton, s.matButton)}
                variant='contained'
                color='primary'
              >
                {translations.return}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <Paper style={{ margin: '0px', padding: '8px', backgroundColor: '#f8f8f8' }} className={s.root2}>
        <div className={s.checkContainer}>
          <select value={searchType} onChange={_ => setSearchType(_.target.value)}>
            <option value={0}>{translations.byPhone}</option>
            <option value={1}>{translations.bySum}</option>
            <option value={2}>{translations.byTable}</option>
            <option value={3}>{translations.byOrderNumber}</option>
          </select>
          <select value={days} onChange={_ => setDays(_.target.value)}>
            <option value={1}>{translations.forOneDay}</option>
            <option value={3}>{translations.forThreeDay}</option>
            <option value={7}>{translations.forLastWeek}</option>
            <option value={30}>{translations.forLastMonth}</option>
          </select>
        </div>
        <div className={s.searchContainer}>
          <div className={s.leftAdorn}>
            <SearchIcon fill={'rgba(0, 0, 0, 0.54)'} />
          </div>
          <div onClick={() => setSearchVal('')} className={s.rightAdorn}>
            {searchVal.length > 0 && <CloseIcon fill={'#000000'} />}
          </div>
          <input
            placeholder={translations.explainForTransferOrder}
            onChange={async _ => {
              const str = _.target.value
              setSearchVal(str)
            }}
            className={s.searchInput}
            value={searchVal}
            style={{ textAlign: searchVal.length > 0 ? 'right' : 'left' }}
          />
        </div>
      </Paper>
      {markedPayment ? (
        <div className={[s.paymentBottomFixed, isConfirmed ? s.paymentBottom2 : ''].join(' ')}>
          <div className={[s.bottomWrapper, s.bottom2].join(' ')}>
            {(markedPayment.status && markedPayment.status.refundStarted) || markedPayment.userRole !== 'user' ? (
              markedPayment.status.refundStarted && (
                <a
                  href={history.createHref({ pathname: `refund-details/${markedPayment?.paymentsId}` })}
                  target='_blank'
                  rel='noreferrer'
                  className={s.refundReceiptButton}
                >
                  <RefundIcon className={s.refundIcon} width={20} height={20} />
                  {translations.refundReceipt}
                </a>
              )
            ) : (
              <div onClick={handleSelectRefund} className={s.botText}>
                {translations.refundPayment}
              </div>
            )}
            <div onClick={handleSelectMove} className={s.botText}>
              {translations.transferPayment}
            </div>
            <div onClick={handleCloseDialog} className={s.botText}>
              {translations.cancel}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className={s.body}>
        {searchState.loading && <Spinner />}
        {selectedPayment ? (
          <RenderPopup
            {...{
              selectedPayment,
              dialogType,
              setIsConfirmed,
              isConfirmed,
              showDialog,
              venue,
              onClose: handleCloseDialog,
              refund,
              setRefund,
              setVenue
            }}
          />
        ) : (
          <div className={s.infoContainer}>
            {paidShow.length > 0 ? <div className={s.paidSection}>{translations.orderWithPayment}</div> : ''}
            {paidShow.map(payment => {
              return (
                <ShortPayment
                  isUnpaider
                  isRefund={payment.status && payment.status.refundStarted}
                  isMoney
                  isVenue
                  variant={markedPayment && markedPayment.paymentsId === payment.paymentsId ? 'black' : ''}
                  key={payment.paymentsId}
                  payment={payment}
                  onClick={handlePaymentClick}
                />
              )
            })}
            {unpaidShow.length > 0 ? <div className={s.paidSection}>{translations.orderWithoutPayment}</div> : ''}
            {unpaidShow.map(payment => (
              <ShortPayment
                variant={markedPayment && markedPayment.paymentsId === payment.paymentsId ? 'black' : ''}
                isMoney
                isVenue
                key={payment.paymentsId}
                payment={payment}
                onClick={handlePaymentClick}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const RenderPopup = ({
  selectedPayment,
  setIsConfirmed,
  isConfirmed,
  onClose,
  showDialog,
  venue,
  dialogType,
  refund,
  setRefund,
  setVenue
}) => {
  const venues = useSelector(getVenues)
  const dishes = useSelector(getDishesEntities)
  const optionsets = useSelector(getOptionsets)
  const tt = getReadableDate(selectedPayment.timedTakeout)
  const pa = getReadableDate(selectedPayment.paidAt)
  const filteredOrders = combineSameDishes(selectedPayment.orders)
  const [isActive, setIsActive] = useState(false)
  const [focused, setFocused] = useState(false)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  return (
    <div className={s.paymentBody}>
      <div className={s.selHeader}>
        <div className={s.selTop}>
          {selectedPayment.tableNum && (
            <div className={s.paymentTable}>
              <TableIcon className={s.tableIcon} width='23' height='19' fill={'#ffffff'} />
              {selectedPayment.tableNum}
            </div>
          )}

          {selectedPayment.takeoutNum && (
            <div className={s.paymentTable}>
              {selectedPayment.delivery ? (
                <DeliveryIcon className={s.tableIcon} width='24' height='24' fill={'#ffffff'} />
              ) : (
                <TogoIcon className={s.tableIcon} width='29' height='19' stroke={'#ffffff'} />
              )}
              {selectedPayment.takeoutNum}
            </div>
          )}
        </div>
        <div className={s.selTop2}>
          <div>
            {decoratePhone(selectedPayment.userPhone) || translations.withoutPhone},{' '}
            {selectedPayment.userName || translations.withoutName}
          </div>
          <div>{selectedPayment.timedTakeout ? `${tt.date || translations.today} ${tt.time}` : pa.time}</div>
        </div>
      </div>
      {selectedPayment.delivery ? (
        <div className={s.orderBox}>
          <div className={[s.flex, s.flex1].join(' ')}>
            <div className={[s.name, s.mleft20].join(' ')}>
              {selectedPayment.delivery.type && selectedPayment.delivery.type === 'novaposhta'
                ? translations.novaPoshtaDeliveryAddress(
                    selectedPayment.delivery.address,
                    selectedPayment.delivery.department,
                    decoratePhone(selectedPayment.delivery.phone),
                    selectedPayment.delivery.name
                  )
                : translations.deliveryAddress(
                    selectedPayment.delivery.address,
                    selectedPayment.delivery.phone,
                    selectedPayment.delivery.name
                  )}
            </div>
          </div>
        </div>
      ) : null}
      <div className={s.selBody}>
        {selectedPayment.comment && (
          <div className={[s.selOrder, s.selComment].join(' ')}>{selectedPayment.comment}</div>
        )}
        {filteredOrders.length &&
          filteredOrders.map((order, index, arr) => {
            const dish = index > 0 ? dishes[arr[index - 1].dishId] : false
            const dish1 = dishes[arr[index].dishId]
            let additionalName = ''
            if (dish1 && (index === 0 || dish.categoryId !== dish1.categoryId)) {
              // console.log(cats)
              // console.log(dish1)
              additionalName = t(dish1.categoryName, selectedLanguage)
            }
            if (dish1 && (index === 0 || dish.subcategoryId !== dish1.subcategoryId)) {
              const subName = t(dish1.subcategoryName, selectedLanguage)
              if (subName) {
                additionalName = t(dish1.categoryName, selectedLanguage) + ' > ' + subName
              }
            }
            if (order) {
              const dish = dishes[order.dishId]
              if (!dish) return null
              const dishName = dish.label ? dish.label : t(dish.name, selectedLanguage)
              const options = order.selectedOptions || order.options || []

              const title = dish ? (
                isEmpty(options) ? (
                  dishName
                ) : (
                  <span>
                    {dishName},{' '}
                    <span style={{ textDecoration: 'underline' }}>{optionsToTextWithPrice(options, optionsets)}</span>
                  </span>
                )
              ) : (
                ''
              )
              return (
                <div key={index} className={s.selOrder}>
                  {additionalName.length > 0 ? (
                    <span className={[s.subcat, s.catTabs].join(' ')}>{additionalName}</span>
                  ) : (
                    ''
                  )}
                  <div className={s.selOrderListWrapper}>
                    <div className={s.selOrderList}>
                      <div className={order.count > 1 ? s.bigcount : s.count}>{order.count}</div>
                      <div
                        style={{
                          marginLeft: '8px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        className={s.x}
                      >
                        ✕{' '}
                      </div>
                      <div className={[s.name, s.mleft8].join(' ')}>
                        {title}
                        <span>
                          {order.takeout ? (
                            <TogoIcon
                              style={{ marginLeft: '16px', position: 'relative', top: '4px' }}
                              width='33'
                              height='22'
                              stroke={'#FF785A'}
                            />
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={s.price}>{`${order.dishPrice / 100} ₴`}</div>
                  </div>
                </div>
              )
            }
            return ''
          })}
        <div className={s.selTotal}>
          <div>{translations.total}:</div>
          <div className={s.selTotalPrice}>{selectedPayment.amountBeforeDiscount / 100}</div>
        </div>
        {dialogType === 1 ? (
          <div className={[s.selTotal, s.setRefund].join(' ')}>
            <div>{translations.totalRefund}:</div>
            <div className={s.wideRow}>
              {selectedPayment.amountBeforeDiscount / 100 !== refund ? (
                <RecycleIcon
                  className={s.recIcon}
                  onClick={() => setRefund(selectedPayment.amountBeforeDiscount / 100)}
                />
              ) : (
                ''
              )}
              {!isActive ? (
                <div
                  onClick={() => setIsActive(true)}
                  className={[s.selTotalPrice, s.selTotalFrame, isActive ? s.selTotalFrameSelected : ''].join(' ')}
                >
                  {selectedPayment.amountBeforeDiscount / 100}
                </div>
              ) : (
                <input
                  onFocus={_ => setFocused(true)}
                  onBlur={_ => setFocused(false)}
                  className={[s.selTotalPrice, s.selTotalFrame, isActive ? s.selTotalFrameSelected : ''].join(' ')}
                  type='number'
                  value={refund}
                  onChange={e => setRefund(e.target.value)}
                />
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={s.bottomSpacer}></div>
      </div>
      {!focused || dialogType !== 1 ? (
        <div className={[s.paymentBottom, isConfirmed ? s.paymentBottom2 : ''].join(' ')}>
          {isConfirmed && (
            <div>
              <div>{translations.transfer}</div>
              <div>{translations.from}</div>
              <input className={s.fullWidthSelect} disabled value={selectedPayment.venuesName} />
              <div>{translations.to}</div>
              <select className={s.fullWidthSelect} value={venue} onChange={_ => setVenue(+_.target.value)}>
                {!venue && (
                  <option value='' selected>
                    {translations.selectVenueToTransferOrderHint}
                  </option>
                )}
                {venues
                  .filter(v => v.id !== parseInt(selectedPayment.venuesId))
                  .map((p, i) => {
                    return (
                      <option value={p.id} key={p.id}>
                        {t(p.name, selectedLanguage)}
                      </option>
                    )
                  })}
              </select>
            </div>
          )}
          <div className={s.bottomWrapper}>
            <div
              onClick={() => {
                if (isConfirmed) {
                  setIsConfirmed(false)
                } else {
                  onClose()
                }
              }}
            >
              <CloseIcon fill={'#000000'} />
            </div>
            {selectedPayment.isPaid ? (
              <button
                disabled={isConfirmed && !venue}
                onClick={() => {
                  if (dialogType === 1) {
                    showDialog(1)
                  } else if (isConfirmed) {
                    if (venue) {
                      fetchMoveOrder(venue, selectedPayment.paymentsId)
                      // TODO: implement on dialog close these callbacks
                      showDialog(2)
                    }
                  } else {
                    setIsConfirmed(true)
                  }
                }}
                className={[s.button2, dialogType === 1 ? s.button2Refund : s.button2Active].join(' ')}
              >
                {dialogType === 1
                  ? translations.refundPaymentButton
                  : isConfirmed
                  ? translations.transfer
                  : `${translations.transfer}...`}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default OrderMoveContent
