import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDishboardPayments } from 'src/state/payments'
import { getGeneralErrors, removeError } from 'src/state/status'
import { getVenues } from 'src/state/venues'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getOptionsets } from 'src/state/dish'
import { combineSameDishes, t } from 'src/utils'
import { OrderComponent } from 'src/components/cashier/CashierView/Timer'
import { getCategories } from 'src/state/categories/selectors'
import { addTimer, getTimerById, removeTimer } from 'src/state/timers'
import { getGeneralSettings, getGeneral, setSettingsGeneral } from 'src/state/general'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import { getMessages } from 'src/state/msgs'
import OrdersBoard from 'src/containers/OrdersBoard'
import { CashierPopup } from '../cashier/CashierView/CashierPopup'
import { Contact } from '../cashier/CashierView/Contact'
import { Header } from '../cashier/CashierView/Header'
import PaymentCard from '../cashier/CashierView/PaymentCard'
import { RunnerSettings } from '../runner/Runner'
import { MessageOrder } from '../cashier/CashierView/MessageOrder'
import { useCashierSSE } from '../../hooks/useCashierSSE'
import PaymentInfoCard from '../cashier/CashierView/PaymentInfoCard'
import d from './Dishboard.module.css'

const DishboardContentWrapper = () => {
  useEffect(() => {
    document.title = 'Dishboard'
  }, [])
  const venues = useSelector(getVenues)
  const cats = useSelector(getCategories)
  useCashierSSE()
  useTimerPing()
  if (!venues || venues.length < 1) return null
  if (!cats || cats.length < 1) return null
  return <DishboardContent venues={venues} cats={cats} />
}

const DishboardContent = ({ venues }) => {
  const msgs = useSelector(getMessages)
  const venueSlug = localStorage.getItem('currentVenue')
  const dispatch = useDispatch()
  const generalErrors = useSelector(getGeneralErrors)
  const generalSettings = useSelector(getGeneral)
  const settings = useSelector(getGeneralSettings)
  const [check1, setCheck1] = useState(localStorage.getItem('__runner_settings_check1') === 'true' || false)
  const [check2, setCheck2] = useState(localStorage.getItem('__runner_settings_check2') === 'true' || false)
  const [check3, setCheck3] = useState(localStorage.getItem('__runner_settings_check3') === 'true' || false)
  const [check4, setCheck4] = useState(localStorage.getItem('__runner_settings_check4') === 'true' || false)
  const [check5, setCheck5] = useState(localStorage.getItem('__runner_settings_check5') === 'true' || false)
  const [check6, setCheck6] = useState(localStorage.getItem('__runner_settings_check6') === 'true' || false)
  const [zone, setZone] = useState(localStorage.getItem('zone') || 'Кухня')
  const initialTheme = localStorage.getItem('theme')
  const cats = useSelector(getCategories)
  const [theme, setTheme] = useState(initialTheme ? JSON.parse(initialTheme) : { name: 'default', value: '96e9da' })
  const initialTabs = localStorage.getItem('activeTabs')
    ? localStorage.getItem('activeTabs') === 'Всё🚗'
      ? localStorage.getItem('activeTabs')
      : JSON.parse(localStorage.getItem('activeTabs'))
    : []
  const catsNew = cats.filter(
    c => c.menuId === (venues.find(e => e.slug === venueSlug) ? venues.find(e => e.slug === venueSlug).menuId : null)
  )
  const [activeTabs, setActiveTabs] = useState(
    'Всё🚗' === initialTabs ? cats : catsNew.filter(x => initialTabs.find(y => y.id === x.id))
  )
  const [isHeader, setHeader] = useState(false)
  const [history, showHistory] = useState(false)
  const [popup, showPopup] = useState(false)
  useEffect(() => {
    if (check1 !== localStorage.getItem('__runner_settings_check1'))
      localStorage.setItem('__runner_settings_check1', check1)
  }, [check1])
  useEffect(() => {
    if (check2 !== localStorage.getItem('__runner_settings_check2'))
      localStorage.setItem('__runner_settings_check2', check2)
  }, [check2])
  useEffect(() => {
    if (check3 !== localStorage.getItem('__runner_settings_check3'))
      localStorage.setItem('__runner_settings_check3', check3)
  }, [check3])
  useEffect(() => {
    if (check4 !== localStorage.getItem('__runner_settings_check4'))
      localStorage.setItem('__runner_settings_check4', check4)
  }, [check4])
  useEffect(() => {
    if (check5 !== localStorage.getItem('__runner_settings_check5'))
      localStorage.setItem('__runner_settings_check5', check5)
  }, [check5])
  useEffect(() => {
    if (check6 !== localStorage.getItem('__runner_settings_check6'))
      localStorage.setItem('__runner_settings_check6', check6)
  }, [check6])
  useEffect(() => {
    localStorage.setItem('zone', zone)
  }, [zone])

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(theme))
  }, [theme])
  const standalone = window.navigator.standalone === true
  if (!venues || venues.length < 1) return null
  const venue = venues.filter(e => e.slug === venueSlug)[0]

  if (localStorage.getItem('viewType') === 'ordersBoard' || generalSettings.orderBoardActive) {
    return <OrdersBoard />
  }

  return (
    <div className={d.wrapper}>
      {msgs.map((msg, index) => (
        <MessageOrder venueSlug={venueSlug} paymentMessage={msg} index={index + 1} key={index} />
      ))}
      <Contact />
      {generalErrors.map((x, i) => (
        <CashierPopup key={x && x.id ? x.id : i} onCloseButton={() => dispatch(removeError(x))}>
          {x.text}
        </CashierPopup>
      ))}
      <Header
        {...{
          zone,
          venue,
          standalone,
          popup,
          showPopup,
          history,
          showHistory,
          setHeader,
          isHeader
        }}
        foldable
        isDishboard
      />

      {/* {settings ? <CashierSettings setSettings={setSettings} /> : ''} */}
      {settings === 1 ? (
        <RunnerSettings
          venue={venue}
          check1={check1}
          setCheck1={setCheck1}
          check2={check2}
          setCheck2={setCheck2}
          check3={check3}
          setCheck3={setCheck3}
          check4={check4}
          setCheck4={setCheck4}
          check5={check5}
          setCheck5={setCheck5}
          check6={check6}
          setCheck6={setCheck6}
          zone={zone}
          theme={theme}
          setTheme={setTheme}
          setZone={setZone}
          setActiveTabs={setActiveTabs}
          onClose={() => dispatch(setSettingsGeneral(0))}
          activeTabs={activeTabs}
        />
      ) : (
        <div className={d.flex}>
          <div className={[d.main, d.historyMain].join(' ')}>
            <PaymentList activeTabs={activeTabs} />
          </div>
        </div>
      )}
    </div>
  )
}

const PaymentList = ({ activeTabs }) => {
  let actualPayments = useSelector(getDishboardPayments)
  let payments = []
  let paymentsHere = actualPayments.filter(x => x.tableNum)
  let paymentsTakeout = actualPayments.filter(x => !x.tableNum && !x.delivery)
  let paymentsDelivery = actualPayments.filter(x => x.delivery)
  // let paymentsTT = actualPayments.filter(x => x.timedTakeout)
  // let paymentsReady = actualPayments.filter(x => !x.orders.every(y => y.status.ready))
  // let payments = useSelector(getDishboardPayments)
  // console.log(actualPayments, paymentsHere, paymentsTakeout, paymentsDelivery)
  if (localStorage.getItem('__runner_settings_check4') === 'true') {
    // payments = payments.filter(x => !x.tableNum)
    payments = paymentsHere
  }
  if (localStorage.getItem('__runner_settings_check5') === 'true') {
    // payments = payments.filter(x => !x.takeout)
    payments = [...payments, ...paymentsTakeout]
  }
  if (localStorage.getItem('__runner_settings_check6') === 'true') {
    // payments = payments.filter(x => x.delivery === null)
    payments = [...payments, ...paymentsDelivery]
  }
  if (localStorage.getItem('__runner_settings_check2') !== 'true') {
    payments = payments.filter(x => !x.orders.every(y => y.status.ready))
    // payments = [...payments, ...paymentsReady]
  }
  if (localStorage.getItem('__runner_settings_check1') === 'true') {
    payments = payments.filter(x => x.timedTakeout)
    // payments = [...payments, ...paymentsTT]
  }
  payments = payments
    .filter(x => x.status && !x.status.finished)
    // .filter(x => x.status && x.takeoutNum === 3056)
    .sort((b, a) => {
      const atime = a.timedTakeout ? Date.now() - new Date(a.timedTakeout) : new Date(a.status.confirmed)
      const btime = b.timedTakeout ? Date.now() - new Date(b.timedTakeout) : new Date(b.status.confirmed)
      // if (a.timedTakeout && b.timedTakeout) {
      //   return btime - atime
      // }
      return atime - btime
    })
    .sort((a, b) => {
      if (!a.timedTakeout && !b.timedTakeout) {
        return new Date(a.status.confirmed) - new Date(b.status.confirmed)
      }
    })
  if (localStorage.getItem('__runner_settings_check3') === 'true') {
    payments = payments.filter(
      x =>
        x.comment.indexOf('Готовить напитки сразу') >= 0 ||
        x.comment.indexOf('Готувати напої одразу') >= 0 ||
        x.comment.indexOf('Make drinks immediately') >= 0
    )
  }
  // console.log(payments)
  // console.log(viableCategories)

  // if (!payments.length) {
  //   return <div className={d.loaderWrapper} />
  // }

  return (
    <div>
      {payments.map(payment => {
        return <PaymentWrapper key={payment.id} payment={payment} activeTabs={activeTabs} />
      })}
    </div>
  )
}

const PaymentWrapper = ({ payment, activeTabs }) =>
  useMemo(() => <Payment payment={payment} activeTabs={activeTabs} />, [payment, activeTabs])

const Payment = ({ payment, activeTabs = [] }) => {
  const dispatch = useDispatch()
  // const payment2 = useSelector(state => getPaymentById(state, payment.id))
  // console.log(payment2)
  // return payment2 && payment2.id ? 'ok' : 'notok'
  const timer = useSelector(getTimerById)(`payment[${payment.id}]`)
  const dishes = useSelector(getDishesEntities)
  const optionsets = useSelector(getOptionsets)
  const viableOrders = payment.orders.filter(x => activeTabs.some(cat => cat.dishIds.some(y => y === x.dishId))) //useSelector(state => getPaymentViableOrders(state, payment.id, activeTabs)) //payment.orders.filter(x => x.dishId < 150).sort((a, b) => a.id - b.id)
  const notViableOrders = payment.orders.filter(x => !activeTabs.some(cat => cat.dishIds.some(y => y === x.dishId))) // useSelector(state => getPaymentUnViableOrders(state, payment.id, activeTabs)) //payment.orders.filter(x => x.dishId >= 150)
  const selectedLanguage = useSelector(getSelectedLanguage)
  // console.log(viableOrders)
  if (viableOrders.length === 0) return null
  // return viableOrders ? 'ok' : 'notok'
  const cookingTime = viableOrders
    .map(order => {
      return order.dishId && dishes[order.dishId] && dishes[order.dishId].waitingTime
        ? dishes[order.dishId].waitingTime
        : 0
    })
    .reduce((a, c) => a + c, 0)
  return (
    <div className={d.payment} key={`payemnt-${payment.id}`}>
      {/* paymentInfoCard */}
      <PaymentInfoCard
        onClick={() => {
          if (localStorage.getItem('__runner_settings_check2') === 'true') {
            if (payment.orders.every(y => y.status.ready)) {
              // console.log('all orders ready')
              if (timer) {
                dispatch(removeTimer(`payment[${payment.id}]`))
              } else {
                dispatch(addTimer(`payment[${payment.id}]`))
              }
            }
          }
        }}
        payment={payment}
        cookingTime={cookingTime}
        time={payment.timedTakeout ? new Date(payment.timedTakeout) : new Date(payment.status.confirmed)}
      />
      {/* ordersCard */}
      {/* {payment.orders.length} */}
      <div className={d.blockRow}>
        {payment.comment ? (
          <div
            className={[
              d.blockCard,
              payment.orders && payment.orders.every(y => y.status.ready) ? d.greenCard : d.notBlackCard
            ].join(' ')}
          >
            <div className={d.blockCardContent}>
              <div className={[d.comment].join(' ')}>
                {payment.comment.slice(0, 100)}
                {payment.comment.length > 100 ? '…' : ''}
              </div>
            </div>
          </div>
        ) : null}
        <OrderListComponent {...{ viableOrders, cookingTime, payment }} />
        {notViableOrders.length > 0 ? (
          <PaymentCard
            className={[d.blockCard, d.notBlackCard2, d.notViableOrders].join(' ')}
            {...{ payment }}
            key={`paymentcard-${payment.id}`}
          >
            <div className={d.blockCardContent}>
              {notViableOrders.map((order, index) => {
                const dish = dishes[order.dishId]
                if (!dish) return null
                const dishName = dish.label ? dish.label : t(dish.name, selectedLanguage)
                return (
                  <span
                    className={order.status && order.status.ready ? d.greenDish : ''}
                    key={index + ' orderPaymentId' + order.id}
                  >
                    {dishName}
                    {', '}
                  </span>
                )
              })}
            </div>
          </PaymentCard>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

const OrderListComponent = ({ viableOrders, payment, cookingTime }) => {
  const filteredOrders = combineSameDishes(viableOrders)
  // console.log(filteredOrders)
  return filteredOrders.map((order, index) => (
    <RenderOrderListComponent
      key={' orderPaymentId' + order.ids.join(' ')}
      order={order}
      index={index}
      comment={payment.comment}
      timedTakeout={payment.timedTakeout}
      cookingTime={cookingTime}
    />
  ))
}

const RenderOrderListComponent = ({ order, index, comment, timedTakeout, cookingTime }) =>
  useMemo(
    () => (
      <OrderComponent
        {...{
          order,
          index,
          comment,
          timedTakeout,
          cookingTime
        }}
      />
    ),
    [order, index, comment, timedTakeout, cookingTime]
  )

const Dishboard = () => useMemo(() => <DishboardContentWrapper />, [])

export default Dishboard
