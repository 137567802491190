import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import request from 'src/api/request'
import { useRestResponse } from './useRestResponse'
import { setIsAuthorized } from '../state/general/actions'

export const useRestApi = endpoint => {
  const dispatch = useDispatch()
  const [restResponseState, restResponseApi] = useRestResponse()
  const { receiveError, receiveResponse, setLoading } = restResponseApi

  const sendRequest = useCallback(
    async options => {
      setLoading(true)
      try {
        const response = await request(endpoint, options)
        receiveResponse(response.data)
        return response
      } catch (error) {
        if ([401, 403].includes(error?.response?.status)) {
          dispatch(setIsAuthorized(false))
        }
        receiveError(error?.response?.data || 'Unexpected error')
      }
    },
    [endpoint, receiveError, receiveResponse, setLoading]
  )

  const api = useMemo(
    () => ({
      ...restResponseApi,
      sendRequest
    }),
    [restResponseApi, sendRequest]
  )

  return [restResponseState, api]
}
