import React from 'react'
import styles from './styles.module.scss'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-24.svg'

const Slot = ({ startTime, activeDeliveriesOfSlot, isStopped, onClick, isLoading, endTime }) => {
  let cls = ''

  if (activeDeliveriesOfSlot?.length && isStopped) {
    cls = styles.stop
  } else if (!activeDeliveriesOfSlot?.length) {
    cls = styles.empty
  }

  const renderLoader = () => {
    if (isLoading) {
      return (
        <div className={styles.load}>
          <div />
          <div />
          <div />
          <div />
        </div>
      )
    }
  }

  return (
    <div className={styles.slot}>
      {startTime} - {endTime}
      <a onClick={onClick} className={`${styles.slotButton} ${cls} ${isStopped ? styles.stoppedSLot : ''}`}>
        <div className={styles.slotButtonContent}>
          {activeDeliveriesOfSlot?.map((_, index) => (
            <DeliveryIcon key={index} fill={!isStopped ? 'black' : 'gray'} />
          ))}
        </div>

        <div>{renderLoader()}</div>
      </a>
    </div>
  )
}

export default Slot
