import {
  ADD_DISHES,
  TOGGLE_STOPLIST,
  TOGGLE_EDIT,
  ADD_SELECTED_OPTIONS,
  ADD_OPTIONSETS,
  ADD_INGREDIENTS,
  ADD_SELECTED_DISH
} from './types'

export const addDishes = dishes => ({
  type: ADD_DISHES,
  payload: { dishes }
})

export const addOptionsets = optionsets => ({
  type: ADD_OPTIONSETS,
  payload: { optionsets }
})

export const addIngredients = ingredients => ({
  type: ADD_INGREDIENTS,
  payload: { ingredients }
})

export const toggleStoplist = ({ id, stoplist }) => ({
  type: TOGGLE_STOPLIST,
  payload: { id: id, stoplist: stoplist }
})

export const addSelectedOptions = payload => ({
  type: ADD_SELECTED_OPTIONS,
  payload
})

export const addSelectedDish = payload => ({
  type: ADD_SELECTED_DISH,
  payload
})

export const toggleEdit = payload => ({
  type: TOGGLE_EDIT,
  payload
})
