import React from 'react'
import s from './Cashier.module.css'
import { isEmpty } from 'ramda'
import { t, findId, optionsToTextWithPrice } from 'src/utils'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as MoreIcon } from 'src/assets/ico-more-24.svg'
import { ReactComponent as CommentIconW } from 'src/assets/ico-comment-int-white.svg'
import { ReactComponent as CommentIconF } from 'src/assets/ico-comment-int-fill.svg'
import { fetchAddStoplistDish } from 'src/api'
import { useDispatch, useSelector } from 'react-redux'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getOptionsets } from 'src/state/dish'
import { addOrderModifier, removeOrderModifier, updatePayment } from 'src/state/payments/actions'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'

const Order = ({ order, setTotal, total, payment, showModal, setShowModal, isStoplisted, isIntegration }) => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const dishes = useSelector(getDishesEntities)
  const optionsets = useSelector(getOptionsets)
  const dish = dishes[order.dishId]
  if (!dish) return null
  const dishName = dish.label ? dish.label : t(dish.name, selectedLanguage)
  const title = dish ? (
    isEmpty(order.selectedOptions) || order.selectedOptions === undefined ? (
      dishName
    ) : (
      <span>
        {dishName},{' '}
        <span style={{ textDecoration: 'underline' }}>{optionsToTextWithPrice(order.selectedOptions, optionsets)}</span>
      </span>
    )
  ) : (
    ''
  )

  return (
    <div className={s.orderBox}>
      <div onClick={() => setShowModal(-1)} style={{ alignItems: 'center' }} className={[s.flex, s.flex1].join(' ')}>
        {isIntegration && payment.comment ? (
          <div style={{ marginRight: '16px' }}>{order.modifier ? <CommentIconF /> : <CommentIconW />}</div>
        ) : (
          ''
        )}
        <div className={order.count > 1 ? s.bigcount : s.count}>{order.count}</div>
        <div
          style={{
            marginLeft: '8px',
            fontSize: '12px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className={s.x}
        >
          ✕{' '}
        </div>
        <div style={{ color: isStoplisted ? 'rgba(0,0,0,.5)' : '' }} className={[s.name, s.mleft8].join(' ')}>
          {title}
          <span>
            {order.takeout ? (
              <TogoIcon
                style={{ marginLeft: '16px', position: 'relative', top: '4px' }}
                width='33'
                height='22'
                stroke={'#FF785A'}
              />
            ) : (
              ''
            )}
          </span>
          {isStoplisted ? <span className={s.stopBadge}>{translations.isOver}</span> : ''}
        </div>
      </div>
      <div className={s.price}>{`${order.dishPrice / 100} ₴`}</div>
      <MoreIcon style={{ marginLeft: '8px' }} onClick={setShowModal} />
      {showModal ? (
        <div className={s.stopModal} style={{ color: isStoplisted ? '' : '#000000' }}>
          <div
            onClick={() => {
              if (!isStoplisted) {
                // API REQUEST
                fetchAddStoplistDish(localStorage.getItem('currentVenue'), { dishId: order.dishId })
                setShowModal(-1)
              }
            }}
          >
            {translations.addToStopList}
          </div>
          {isIntegration && payment.comment && !order.modifier ? (
            <div
              onClick={() => {
                if (!order.modifier) {
                  // API REQUEST
                  dispatch(addOrderModifier(order.dishId, payment.id, payment.comment))
                  // dispatch(updatePayment({ ...payment, modifier: payment.comment }))
                  setShowModal(-1)
                }
                //  else {
                //   dispatch(removeOrderModifier(order.dishId, payment.id))
                //   // dispatch(updatePayment({ ...payment, modifier: payment.comment }))
                //   setShowModal(-1)
                // }
              }}
            >
              {translations.addComment}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Order
