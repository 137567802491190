import {
  SET_CARD_GENERAL,
  SET_LOGIN_GENERAL,
  SET_SETTINGS_GENERAL,
  SET_STATS_GENERAL,
  SET_VENUE_GENERAL,
  SET_IS_AUTHORIZED,
  SET_USER_PROFILE,
  SET_SELECTED_LANGUAGE,
  TOGGLE_ORDER_BOARD_ACTIVE,
  HANDLE_BRAND_INTEGRATION_STATUS
} from './types'

export const initialState = {
  venue: localStorage.getItem('currentVenue') || '',
  card: '',
  settings: false,
  login: null,
  stats: [],
  isAuthorized: false,
  user: null,
  selectedLanguage: 'uk',
  orderBoardActive: false,
  brandIntegrationEnabled: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_VENUE_GENERAL: {
      //console.log(SET_VENUE_GENERAL)
      const { venue } = action.payload
      const obj = { ...state, venue }
      //console.log(obj, venue)
      return obj
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        user: action.user
      }
    }

    case SET_CARD_GENERAL: {
      //console.log(SET_CARD_GENERAL)
      const { card } = action.payload
      const obj = { ...state, card: card }
      // //console.log(obj, venue)
      return obj
    }

    case SET_SETTINGS_GENERAL: {
      //console.log(SET_SETTINGS_GENERAL)
      const { settings } = action.payload
      const obj = { ...state, settings }
      // //console.log(obj, venue)
      return obj
    }

    case SET_LOGIN_GENERAL: {
      //console.log(SET_LOGIN_GENERAL)
      const { login } = action.payload
      const obj = { ...state, login }
      // //console.log(obj, venue)
      return obj
    }

    case SET_IS_AUTHORIZED: {
      return {
        ...state,
        isAuthorized: action.isAuthorized
      }
    }

    case SET_STATS_GENERAL: {
      //console.log(SET_STATS_GENERAL)
      const { stats } = action.payload
      const obj = { ...state, stats }
      // //console.log(obj, venue)
      return obj
    }

    case SET_SELECTED_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.selectedLanguage
      }
    }

    case TOGGLE_ORDER_BOARD_ACTIVE: {
      return {
        ...state,
        orderBoardActive: action.status
      }
    }

    case HANDLE_BRAND_INTEGRATION_STATUS: {
      return {
        ...state,
        brandIntegrationEnabled: action.isActive
      }
    }

    default:
      return state
  }
}
