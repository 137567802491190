import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addError, setIntegration } from '../state/status'
import usePaymentApi from './usePaymentApi'
import { getGeneralVenue } from '../state/general'
import { EVENT_ACTIONS, EVENT_CATEGORY, integrationFetch, postEventV3 } from '../api'
import { updatePayment } from '../state/payments/actions'

export default function useIntegrationApi() {
  const venueSlug = useSelector(getGeneralVenue)
  const dispatch = useDispatch()

  const [ip, setIp] = useState(localStorage.getItem('_integration_ip') || '')
  const [autointegration, setAutointegration] = useState(localStorage.getItem('_integration_autoint') || false)
  const [login, setLogin] = useState(localStorage.getItem('_integration_login') || '')
  const [password, setPassword] = useState(localStorage.getItem('_integration_password') || '')
  const [url, setUrl] = useState(localStorage.getItem('_integration_url') || '')
  const [pin, setPin] = useState(localStorage.getItem('_integration_pin') || '')

  const [, { sendPaymentStatus }] = usePaymentApi()

  useEffect(() => {
    localStorage.setItem('_integration_autoint', autointegration || '')
    localStorage.setItem('_integration_ip', ip || '')
    localStorage.setItem('_integration_login', login || '')
    localStorage.setItem('_integration_password', password || '')
    localStorage.setItem('_integration_url', url || '')
    localStorage.setItem('_integration_pin', pin || '')
  }, [autointegration, ip, password, login, url, pin])

  useEffect(() => {
    setAutointegration(Boolean(localStorage.getItem('_integration_autoint')))
    setIp(localStorage.getItem('_integration_ip') || '')
    setLogin(localStorage.getItem('_integration_login') || '')
    setPassword(localStorage.getItem('_integration_password') || '')
    setUrl(localStorage.getItem('_integration_url') || '')
    setPin(localStorage.getItem('_integration_pin') || '')
  }, [])

  const integrateRequest = useCallback(
    async ({ integrationData, payment }) => {
      try {
        return await integrationFetch('/postOrder', null, 'POST', integrationData, errorObj => {
          // dispatch(addError(JSON.stringify(errorObj)))
          const errorStr = `Произошла ошибка, кассовый аппарат не отвечаетт. Проинформируйте @integration_support об этой ошибке.\n ${JSON.stringify(
            errorObj
          )}`
          dispatch(setIntegration(false))
          sendPaymentStatus({ paymentId: payment.id, status: 'integrationCanceled' })
          postEventV3(EVENT_ACTIONS.INTEGRATION_ERROR, EVENT_CATEGORY.INTEGRATION, `venue:${venueSlug} ${errorStr}`)
        })
      } catch (err) {
        const errorStr = `Произошла ошибка, кассовый аппарат не отвечает. Проинформируйте @integration_support об этой ошибке.\n ${JSON.stringify(
          err
        )}`
        postEventV3(EVENT_ACTIONS.INTEGRATION_ERROR, EVENT_CATEGORY.INTEGRATION, `venue:${venueSlug} ${errorStr}`)
        dispatch(addError(errorStr))
        dispatch(setIntegration(false))
      }
    },
    [dispatch, venueSlug, sendPaymentStatus]
  )

  const integrateCheckOrder = useCallback(async (order_id, cb) => {
    const result = await integrationFetch('/checkOrderStatus', null, 'POST', {
      order_id
    })
    if (cb) {
      cb(result)
    }
    return result
  }, [])

  const integrateFinalOrder = useCallback(
    async ({ order_id, payment }) => {
      const intervalSec = 5
      let ticks = 120 / intervalSec
      const winInterval = setInterval(() => {
        integrateCheckOrder(order_id, res2 => {
          if (res2.data.status === 'fail') {
            sendPaymentStatus({ paymentId: payment.id, status: 'integrationCanceled' })
            const errstr = `Касса вернула код ошибки: ${res2.data.order_status_code}, ${res2.data.detail} Проинформируйте @integration_support об этой ошибке.`
            postEventV3(EVENT_ACTIONS.INTEGRATION_ERROR, EVENT_CATEGORY.INTEGRATION, `venue:${venueSlug} ${errstr}`)
            dispatch(addError(errstr))
            dispatch(updatePayment({ id: payment.id, integrationStatus: 'error' }))
            dispatch(setIntegration(false))
            clearInterval(winInterval)
          }
          if (parseInt(res2.data.order_status_code) > 1) {
            sendPaymentStatus({ paymentId: payment.id, status: 'integrationCanceled' })
            const errstr = `Касса вернула код ошибки: ${res2.data.order_status_code}, ${res2.data.detail} Перезагрузите кассу, и, если проблема сохраняется, проинформируйте @integration_support об этой ошибке.`
            postEventV3(EVENT_ACTIONS.INTEGRATION_ERROR, EVENT_CATEGORY.INTEGRATION, `venue:${venueSlug} ${errstr}`)
            dispatch(addError(errstr))
            dispatch(updatePayment({ id: payment.id, integrationStatus: 'error' }))
            dispatch(setIntegration(false))
            clearInterval(winInterval)
          } else if (parseInt(res2.data.order_status_code) === 1) {
            sendPaymentStatus({ paymentId: payment.id, status: 'integrationConfirmed' })
            dispatch(updatePayment({ id: payment.id, integrationStatus: 'finish' }))
            clearInterval(winInterval)
          }
          ticks -= 1
          if (ticks <= 0) {
            dispatch(setIntegration(false))
            dispatch(updatePayment({ id: payment.id, integrationStatus: 'error' }))
            sendPaymentStatus({ paymentId: payment.id, status: 'integrationCanceled' })
            clearInterval(winInterval)
          }
        })
      }, intervalSec * 1000)
    },
    [dispatch, venueSlug, sendPaymentStatus]
  )

  const integrateStartOrder = useCallback(
    async ({ res, payment }) => {
      if (res.data.status === 'fail') {
        const errstr = `error: ${res.data.error}, ${res.data.detail}`
        dispatch(addError(errstr))
        postEventV3(EVENT_ACTIONS.INTEGRATION_ERROR, EVENT_CATEGORY.INTEGRATION, `venue:${venueSlug} ${errstr}`)
        dispatch(setIntegration(false))
        sendPaymentStatus({ paymentId: payment.id, status: 'integrationCanceled' })
        dispatch(updatePayment({ id: payment.id, integrationObj: res.data, integrationStatus: 'error' }))
      } else {
        sendPaymentStatus({ paymentId: payment.id, status: 'integrationStarted', integrationId: res.data.order_id })
        dispatch(updatePayment({ id: payment.id, integrationObj: res.data, integrationStatus: 'started' }))
        // const res2 = await integrateCheckOrder(res.data.order_id)
        // integrateWaitOrder({ res2, payment, dispatch, venueSlug })
        await integrateFinalOrder({ order_id: res.data.order_id, payment, dispatch, venueSlug })
      }
    },
    [dispatch, venueSlug, sendPaymentStatus]
  )

  const integrateOrder = useCallback(
    async payment => {
      const integrationData = { ...payment.integration, user_pin: pin }

      if ('errors' in integrationData) {
        const { errors } = integrationData
        const errorMessage = `errors: ${typeof errors === 'string' ? errors : errors.join(' ')}.`
        dispatch(addError(errorMessage))
        dispatch(setIntegration(false))
        sendPaymentStatus({ status: 'integrationCanceled', paymentId: payment.id })
        return
      }

      try {
        const res = await integrateRequest({ integrationData, venueSlug, dispatch, payment })
        await integrateStartOrder({ res, payment, dispatch, venueSlug })
      } catch (e) {
        console.log(e)
        return e.response
      }
    },
    [dispatch, sendPaymentStatus, integrateRequest, integrateStartOrder, venueSlug, pin]
  )

  const state = useMemo(
    () => ({
      settings: {
        url,
        login,
        password,
        pin,
        autointegration,
        ip
      }
    }),
    [ip, pin, login, password, url, autointegration]
  )

  const api = useMemo(
    () => ({
      integrateOrder,
      integrateFinalOrder,
      setIp,
      setPin,
      setLogin,
      setPassword,
      setUrl,
      setAutointegration
    }),
    [integrateOrder]
  )

  return [state, api]
}
