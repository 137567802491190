import { ADD_MODIFIERS } from './types'

const state = []

const initialState = state

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_MODIFIERS: {
      const { items } = action.payload
      return [...items]
    }

    default:
      return state
  }
}
