const translationsDishboard = {
  ru: {
    settings: 'Настройки',
    showCategory: 'Показать категории',
    typeOfOrders: 'Типы заказа',
    additionally: 'Дополнительно',
    onlyAtTime: 'Только на время',
    waitForOrderComplete: 'Ожидать завершение заказа',
    justCookNow: 'Только "Готовить сразу"',
    nameStation: 'Название станции',
    colorStation: 'Цвет станции',
    close: 'Закрыть',
    here: 'Здесь',
    takeAway: 'С собой',
    delivery: 'Доставка',
    preparing: 'Готовится',
    ready: 'Готово',
    more: 'еще'
  },
  uk: {
    settings: 'Налаштування',
    showCategory: 'Показати категорії',
    typeOfOrders: 'Типи замовлень',
    additionally: 'Додатково',
    onlyAtTime: 'Тільки на певний час',
    waitForOrderComplete: 'Чекати на завершення замовлення',
    justCookNow: 'Тільки "Готувати одразу"',
    nameStation: 'Назва станції',
    colorStation: 'Колір станції',
    close: 'Закрити',
    here: 'Тут',
    takeAway: 'З собою',
    delivery: 'Доставка',
    preparing: 'Готується',
    ready: 'Готово',
    more: 'ще'
  }
}
export default translationsDishboard
