import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { fetchAddStoplistDish, fetchRemoveStoplistDish } from 'src/api'
import { ReactComponent as CloseIcon } from 'src/assets/btn-close-24.svg'
import { getOptionsets } from 'src/state/dish/selectors'
import { getDishById } from 'src/state/dishes'
import { getStoplist } from 'src/state/stoplist/selectors'
import OptionCard from '../DishCard/OptionCard'
import { t } from 'src/utils'
import classes from './Stoplist.module.scss'
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  CssBaseline,
  Paper,
  FormControlLabel,
  Checkbox
} from 'src/components/shared/ExMaterial'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import { useCashierSSE } from '../../../hooks/useCashierSSE'

const StopDish = () => {
  useCashierSSE()
  useTimerPing()
  const venueSlug = localStorage.getItem('currentVenue')
  const history = useHistory()
  const { dishId } = useParams()
  const optionsets = useSelector(getOptionsets)
  const stoplist = useSelector(getStoplist)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const [permanent, setPermanent] = React.useState(false)

  useEffect(() => {
    const isDish = stoplist.dishes.find(x => parseInt(x.dishId) === parseInt(dishId))
    setPermanent(isDish ? !!isDish.permanent : false)
  }, [stoplist])

  const dish = useSelector(getDishById)(parseInt(dishId))

  // React.useEffect(() => {}, [dish])

  if (!dish) {
    return 'Loading...'
  }

  const handleChange = event => {
    setPermanent(event.target.checked)
  }

  // console.log(dish)
  // console.log(optionsets, dish)
  const dishOptions = optionsets.filter(e => e.recipeId === dish.recipeId)
  // console.log(dishOptions)
  const isStoplisted = stoplist.dishes.filter(e => dish.id === parseInt(e.dishId))
  const disabledOptions = dishOptions
    .map(sets =>
      sets.options
        ? sets.options.map(option => {
            return { ...option, parentName: t(sets.name, selectedLanguage) + ': ' + t(option.name, selectedLanguage) }
          })
        : []
    )
    // .flat(1)
    .reduce((acc, val) => acc.concat(val), []) // flat(1)
    .filter(e => stoplist.options.some(option => option.modifierId === e.modifierId))
  const enabledOptions = dishOptions
    .map(sets =>
      sets.options
        ? sets.options.map(option => {
            return { ...option, parentName: t(sets.name, selectedLanguage) + ': ' + t(option.name, selectedLanguage) }
          })
        : []
    )
    // .flat(1)
    .reduce((acc, val) => acc.concat(val), []) // flat(1)
    .filter(e => !stoplist.options.some(option => option.modifierId === e.modifierId))

  return (
    <>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            color='inherit'
            onClick={() => {
              history.goBack()
            }}
            edge='start'
            style={{ padding: '4px' }}
          >
            <CloseIcon width='32' height='32' />
          </IconButton>
          <Button className={classes.title} color='inherit'>
            {t(dish.name, selectedLanguage)}
          </Button>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <Paper style={{ position: 'relative' }} className={classes.root}>
        {/* <Menu handleCats={handleCats} activeCats={activeCats} /> */}
        <FormControlLabel
          label={translations.permanent}
          control={
            <Checkbox disabled={isStoplisted.length > 0} color='primary' checked={permanent} onChange={handleChange} />
          }
        />
        <div className={classes.card1}>{translations.modifiers}</div>
        {disabledOptions.map((dish, index, arr) => (
          <OptionCard
            venueSlug={venueSlug}
            id={dish.modifierId}
            iid={dish.id}
            name={dish.parentName}
            stoplist={true}
            key={dish.id}
          />
        ))}
        {enabledOptions.map((dish, index, arr) => (
          <OptionCard
            venueSlug={venueSlug}
            id={dish.modifierId}
            iid={dish.id}
            name={dish.parentName}
            stoplist={false}
            key={dish.id}
          />
        ))}
        <div
          onClick={() => {
            console.log(isStoplisted, 'ISSTOPPED')
            if (isStoplisted.length > 0) {
              fetchRemoveStoplistDish(venueSlug, { dishId: dishId })
            } else {
              fetchAddStoplistDish(venueSlug, { dishId: dishId, permanent: !!permanent })
            }
          }}
          className={classes.card}
        >
          {isStoplisted.length > 0 ? translations.removeFromStopList : translations.addToStopList}
        </div>
      </Paper>
    </>
  )
}

// export default connect(mapStateToProps, mapDispatchToProps)(Stoplist)
export default StopDish

export const IconType = type => {
  switch (type) {
    case 'beverages':
      return '☕'
    case 'breakfast':
      return '🍳'
    case 'burger':
      return '🍔'
    case 'salad':
      return '🥒'
    case 'bakery':
      return '🥐'
    case 'bread':
      return '🍞'
    case 'alcohol':
      return '🍷'
    default:
      return '☕'
  }
}
