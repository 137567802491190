import React, { useEffect, useRef } from 'react'

const ClickOutsideListener = props => {
  const { children, onClickOutside, customStyles } = props

  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSettings)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSettings)
    }
  }, [])

  const handleClickOutsideSettings = event => {
    if (!wrapperRef?.current?.contains(event.target) && onClickOutside) {
      onClickOutside()
    }
  }

  return (
    <div style={{ width: 'fit-content', ...customStyles }} ref={wrapperRef}>
      {children}
    </div>
  )
}
export default ClickOutsideListener
