import { REMOVE_MSG, ADD_MSG } from './types'

/* State Shape
{

}
*/

const initialState = [
  // {
  //   takeout: 4865,
  //   msg: 'Жду снаружи',
  //   tempId: 2224
  // }
]

export default function(state = initialState, action) {
  switch (action.type) {
    case REMOVE_MSG: {
      const { msg } = action.payload
      return state.filter(m => m.tempId !== msg.tempId)
    }

    case ADD_MSG: {
      const { msg } = action.payload
      return [...state, { ...msg, tempId: new Date().getTime() }]
    }

    default:
      return state
  }
}
