import clsx from 'clsx'
import React from 'react'
import classes from './Cashier.module.css'

const Label = ({ className = '', color = 'secondary', children, style, ...rest }) => {
  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color
        },
        className
      )}
      {...rest}
    >
      {children}
    </span>
  )
}

export default Label
