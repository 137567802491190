import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addModifierToStoplistAsync, removeModifierFromStoplistAsync } from 'src/api'
import { getModifiers } from 'src/state/modifiers'
import { getStoplist } from 'src/state/stoplist'
import StoplistHeader from './Stoplist/StoplistHeader'
import classes from './Stoplist/Stoplist.module.scss'
import { Button, Paper } from '../shared/ExMaterial'
import { getGeneralVenue } from 'src/state/general'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import { useCashierSSE } from '../../hooks/useCashierSSE'

export const ModifiersStoplist = () => {
  useCashierSSE()
  useTimerPing()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  useEffect(() => {
    document.title = 'Менеджер'
  }, [])
  const venueSlug = useSelector(getGeneralVenue)
  const dispatch = useDispatch()
  const modifiers = useSelector(getModifiers)
  const stoplist = useSelector(getStoplist)
  const [stoplistOnly, setStoplistOnly] = useState(false)

  const inStoplist = modifierId => stoplist.options.some(option => option.modifierId === modifierId)

  const handleClick = modifierId => () => {
    if (inStoplist(modifierId)) {
      removeModifierFromStoplistAsync({ modifierId, venueSlug, dispatch })
    } else {
      addModifierToStoplistAsync({ modifierId, venueSlug, dispatch })
    }
  }

  const Title = () => (
    <div className={classes.title} color='inherit'>
      <div style={{ textTransform: 'none' }} className={classes.flex1}>
        <div className={classes.topText}>{translations.stopList}</div>
        <div className={classes.bottomText}>{translations.modifiers}</div>
      </div>
    </div>
  )

  return (
    <>
      <StoplistHeader venueSlug={venueSlug} title={<Title />} subToolbar={null} />

      <Paper className={classes.root}>
        {modifiers
          .filter(x => (stoplistOnly ? inStoplist(x.id) : true))
          .map(modifier => (
            <Button
              className={clsx(classes.card, classes.title, inStoplist(modifier.id) && classes.stoplisted)}
              key={modifier.id}
              onClick={handleClick(modifier.id)}
            >
              <div className={classes.flex}>
                <div className={classes.font}>{modifier.label}</div>
              </div>
              <div className={classes.flex}></div>
            </Button>
          ))}
      </Paper>
    </>
  )
}
