import {
  SET_PING,
  SET_PONG,
  SET_GOOGLE,
  SET_INTEGRATION,
  SET_ERRORS,
  ADD_ERROR,
  REMOVE_ERROR,
  SET_API_AVAILABLE,
  SET_NEED_RESTART,
  SET_OFFLINE
} from './types'

export const initialState = {
  ping: false,
  pong: Date.now(),
  google204: false,
  needRestart: false,
  apiAvailable: true,
  isOffline: false,
  integration: false,
  errors: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PING: {
      const { content } = action.payload
      return { ...state, ping: content }
    }
    case SET_PONG: {
      const { content } = action.payload
      return { ...state, pong: content }
    }
    case SET_API_AVAILABLE: {
      const { content } = action.payload
      return { ...state, apiAvailable: content }
    }
    case SET_OFFLINE: {
      const { content } = action.payload
      return { ...state, isOffline: content }
    }
    case SET_NEED_RESTART: {
      const { content } = action.payload
      return { ...state, needRestart: content }
    }
    case SET_ERRORS: {
      const { content } = action.payload
      return { ...state, errors: content }
    }
    case ADD_ERROR: {
      const { content } = action.payload
      return { ...state, errors: [...state.errors, { text: content, id: Date.now() }] }
    }
    case REMOVE_ERROR: {
      const { content } = action.payload
      return { ...state, errors: state.errors.filter(x => x.id !== content.id) }
    }
    case SET_INTEGRATION: {
      const { content } = action.payload
      return { ...state, integration: content }
    }
    case SET_GOOGLE: {
      const { content } = action.payload
      return { ...state, google: content }
    }

    default:
      return state
  }
}
