import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as ByhandIcon } from 'src/assets/ico-byhand.svg'
import { ReactComponent as CommentIcon } from 'src/assets/ico-comment2.svg'
import { ReactComponent as TimedTakeoutIcon } from 'src/assets/ico-deffered_togo-24-n.svg'
import { ReactComponent as DeliveryIcon } from 'src/assets/ico-delivery-1.svg'
import { ReactComponent as IntegrationIcon } from 'src/assets/ico-integration-ok.svg'
import { ReactComponent as IntegrationErrorIcon } from 'src/assets/ico-integration.svg'
import { ReactComponent as ProfileIcon } from 'src/assets/ico-profile.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { decoratePhone } from 'src/shared/utils/phone'
import { showContect } from 'src/state/contect'
import { getOptionsets } from 'src/state/dish'
import { getDishes } from 'src/state/dishes'
import { activatePayment, deactivatePayment } from 'src/state/payments/actions'
import { getRoles } from 'src/state/roles'
import { getCookingTime, getReadableDate, isSameDay } from 'src/utils'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import { useInterval } from 'src/hooks/useInterval'
import { getDishesEntities } from 'src/state/dishes/selectors'
import { getBrandIntegrationStatus } from 'src/state/general'
import usePaymentApi from 'src/hooks/usePaymentApi'
import useIntegrationApi from 'src/hooks/useIntegrationApi'
import { soundEffect, getLastClick } from 'src/utils/soundEffect'
import Loader from './Loader'
import OrderList from './OrderList'
import Timer from './Timer'
import s from './Cashier.module.css'
import { isDeliveryOrders, isTakeoutOrders } from '../../../utils/payments'

const ACTIVATION_ADVANCE = 5 * 60 * 1000

// TODO: check to use time in timezone and not in UTC

const Payment = ({ payment, handleManualPayment }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const dispatch = useDispatch()
  const unpaiders = useSelector(getRoles)
  const dishes = useSelector(getDishes)
  const dishesEntities = useSelector(getDishesEntities)
  const optionsets = useSelector(getOptionsets)
  const [refund, setRefund] = useState(false)
  const brandIntegrationActive = useSelector(getBrandIntegrationStatus)
  const roleSearch = unpaiders.find(role => role.key === payment.userRole)
  const role = roleSearch ? roleSearch.value : ''

  const [
    {
      settings: { autointegration }
    },
    { integrateOrder }
  ] = useIntegrationApi()
  const [
    { paymentPending, manualIntegration, paymentStatus, paymentIntegrationStatus },
    { sendPaymentStatus, setPaymentPending, setManualIntegration }
  ] = usePaymentApi(payment.id)

  const handleClosePayment = useCallback(() => {
    sendPaymentStatus({ status: 'cashierClosed' })
  }, [sendPaymentStatus])

  useInterval(() => {
    const someTime = ACTIVATION_ADVANCE + getCookingTime(dishesEntities, payment.orders).max * 1000

    if (payment.timedTakeout && new Date(payment.timedTakeout) - new Date() < someTime && !payment.activated) {
      dispatch(activatePayment(payment))
    }
  }, 1000)

  useEffect(() => {
    const lastClick = getLastClick()
    if (lastClick) {
      soundEffect.play()
    }
  }, [])

  useEffect(() => {
    const someTime = ACTIVATION_ADVANCE + getCookingTime(dishesEntities, payment.orders).max * 1000

    if (payment.timedTakeout && new Date(payment.timedTakeout) - new Date() >= someTime) {
      dispatch(deactivatePayment(payment))
    }
  }, [])

  useEffect(() => {
    if (paymentIntegrationStatus === 'finish' && !paymentStatus.cashierClosed && paymentStatus.integrationConfirmed) {
      handleClosePayment()
    }
  }, [handleClosePayment, paymentIntegrationStatus, paymentStatus])

  const isUnpaider = unpaiders.filter(e => payment.userRole === e.key).length > 0

  const needToClosePayment = manualIntegration !== true && autointegration && paymentStatus.integrationConfirmed

  const renderPaymentDate = useCallback(dateTime => {
    const { time, date } = getReadableDate(dateTime)
    const [day, month] = date.split(' ')
    return (
      <>
        {date ? ` ${day} ${translations[month]}, ` : <></>}
        {time}
      </>
    )
  }, [])

  const handleActive = () => {
    if (
      brandIntegrationActive &&
      (isUnpaider ||
        (!manualIntegration && (paymentIntegrationStatus === 'error' || paymentStatus.integrationCanceled)))
    ) {
      if (handleManualPayment) {
        handleManualPayment(payment)
      }
      return
    }

    setPaymentPending(true)

    if (!manualIntegration && (paymentIntegrationStatus !== 'error' || !paymentStatus.integrationCanceled)) {
      integrateOrder(payment).then(() => {
        setPaymentPending(false)
      })
    } else {
      dispatch(activatePayment(payment))

      // sendPaymentStatus({ status: 'cashierConfirmed' })
      sendPaymentStatus({ status: 'cashierClosed' })
    }
  }

  return (
    <div className={s.paymentBox}>
      {!payment.activated && (
        <div
          className={s.activatedStatusFade}
          role='button'
          onClick={() => {
            dispatch(activatePayment(payment))
          }}
        />
      )}
      {paymentPending && (
        <div className={s.takeOver}>
          <Loader />
        </div>
      )}
      <Timer
        className={refund !== false ? s.refundTimer : ''}
        hideCountdown={refund !== false}
        showTime
        isUnpaider={isUnpaider}
        timedTakeout={payment.timedTakeout}
        className={[
          isUnpaider
            ? s.unpaiderTimer
            : !manualIntegration
            ? paymentIntegrationStatus === 'error'
              ? s.refundTimer
              : s.paiderTimedTimer
            : ''
        ].join(' ')}
        paidAt={payment.paidAt}
      >
        {refund !== false ? (
          translations.refund
        ) : (
          <div className={s.leftFlex}>
            {isUnpaider && (
              <div style={{ marginLeft: '8px' }} className={s.unpaiderFlex}>
                <span style={{ marginLeft: '8px' }}>{role}</span>
              </div>
            )}
            {(paymentIntegrationStatus === 'error' && !manualIntegration) ||
              (paymentStatus && paymentStatus.integrationCanceled && !paymentStatus.cashierConfirmed && (
                <div className={s.intErr}>
                  <IntegrationErrorIcon fill='#ffffff' width={23} height={24} />
                  <div className={s.intErrText}>{translations.errorOfIntegration}</div>
                </div>
              ))}
            <div
              onClick={e => {
                console.log(payment)
                e.stopPropagation()
                dispatch(
                  showContect({
                    phone: decoratePhone(payment.userPhone),
                    delAdress: payment.userAddress,
                    delType: payment.delivery ? 'taxi' : false,
                    type: payment.userContactType,
                    userid: payment.userId,
                    name: payment.userName
                  })
                )
              }}
              className={[s.timerTab].join(' ')}
            >
              <div className={s.paiderFont}>{payment.userName ? payment.userName : translations.anonymousVisitor}</div>
              <ProfileIcon style={{ marginLeft: '8px' }} />
            </div>
          </div>
        )}
      </Timer>
      <div className={s.paymentBoxWrapper}>
        <div className={[s.paymentBoxPart, s.paymentBoxPart1].join(' ')}>
          <div className={[needToClosePayment ? s.noFlex : s.flex1, s.descFlex].join(' ')} />
          {payment.timedTakeout && (
            <div
              onClick={() => {
                dispatch(deactivatePayment(payment))
              }}
              className={s.whiteButton}
              style={{ marginTop: '12px' }}
            >
              {translations.putAside}
            </div>
          )}
          {refund !== false && (
            <div onClick={() => {}} className={s.refundButton}>
              {translations.refund}
            </div>
          )}
          {manualIntegration !== true && !paymentStatus.integrationConfirmed && !isUnpaider && (
            <div onClick={() => setManualIntegration(true)} className={s.whiteButton} style={{ marginTop: '12px' }}>
              {translations.manually}
            </div>
          )}
          {(manualIntegration === true || paymentIntegrationStatus === 'error' || isUnpaider) && refund === false && (
            <div onClick={handleActive} className={[s.button].join(' ')}>
              <ByhandIcon />
              {translations.paymentHasBeenMade}
            </div>
          )}
          {manualIntegration !== true &&
            !isUnpaider &&
            paymentIntegrationStatus !== 'error' &&
            !paymentStatus.integrationConfirmed && (
              <div onClick={handleActive} className={[s.timedTakeoutButton].join(' ')}>
                <IntegrationIcon width={40} height={37} fill={'#ffffff'} />
                {translations.ready}
              </div>
            )}
          {needToClosePayment && (
            <div onClick={handleClosePayment} className={s.closePaymentButton}>
              {translations.close}
            </div>
          )}
          {refund !== false && (
            <>
              <div className={[s.flex1, s.descFlex].join(' ')} />
              <div
                style={{ backgroundColor: '#FFFFFF', color: 'black', marginTop: '16px' }}
                onClick={() => {
                  setRefund(false)
                }}
                className={[s.button, s.notButton].join(' ')}
              >
                {translations.cancel}
              </div>
            </>
          )}
        </div>
        <div className={s.vr1} />
        <div className={[s.paymentBoxPart, s.paymentBoxPart2].join(' ')}>
          <div className={s.paymentHeader}>
            {isDeliveryOrders(payment) ? (
              <DeliveryIcon width='48' height='48' />
            ) : !payment.timedTakeout ? (
              <>
                {isTakeoutOrders(payment) && <TogoIcon width='48' height='48' />}
                {Boolean(payment.tableNum) && <TableIcon className={s.mleft8} width='48' height='48' />}
              </>
            ) : (
              <TimedTakeoutIcon width='48' height='48' />
            )}
            <div className={[s.paymentHeaderText, s.mleft8].join(' ')}>
              {payment.tableNum ? payment.tableNum + ' / ' : ''}
              {payment.takeoutNum}
            </div>
          </div>

          {payment.comment && (
            <div className={s.orderBox}>
              <div className={[s.flex, s.flex1].join(' ')}>
                <CommentIcon style={{ minWidth: '24px' }} />
                <div className={[s.name, s.mleft20].join(' ')}>{payment.comment}</div>
              </div>
            </div>
          )}
          {payment.preorder && !isSameDay(payment.paidAt, payment.timedTakeout) && (
            <div className={s.orderBox}>
              <div className={[s.flex, s.flex1].join(' ')}>
                <div className={[s.name, s.mleft20].join(' ')}>
                  {translations.preorderTo}
                  {renderPaymentDate(payment.timedTakeout)}
                </div>
              </div>
            </div>
          )}
          {payment.delivery && (
            <div className={s.orderBox}>
              <div className={[s.flex, s.flex1].join(' ')}>
                <div className={[s.name, s.mleft20].join(' ')}>
                  {payment.delivery.type && payment.delivery.type === 'novaposhta'
                    ? translations.novaPoshtaDeliveryAddress(
                        payment.delivery.address,
                        payment.delivery.department,
                        decoratePhone(payment.delivery.phone),
                        payment.delivery.name
                      )
                    : translations.deliveryAddress(
                        payment.delivery.address,
                        decoratePhone(payment.delivery.phone),
                        payment.delivery.name
                      )}
                </div>
              </div>
            </div>
          )}
          <OrderList
            refund={refund}
            paymentId={payment.id}
            isIntegration={!manualIntegration}
            payment={payment}
            {...{ orders: payment.orders, dishes, optionsets }}
          />
          <div className={[isUnpaider ? s.unpaiderTotal : s.total].join(' ')}>
            <div className={s.totalLabel}>{translations.total}</div>
            <div className={[s.mleft48, s.flexT].join(' ')}>{`${payment.amountBeforeDiscount / 100} ₴`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
