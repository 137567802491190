import React, { useState } from 'react'
import s from './Cashier.module.css'
import { ReactComponent as TimedTakeoutIcon } from 'src/assets/ico-hand.svg'
import { useDispatch, useSelector } from 'react-redux'
import { removeMsg } from 'src/state/msgs'
import PaymentCard from './PaymentCard'
import { getOptionsets } from 'src/state/dish'
import { getPayments } from 'src/state/payments'
import Timer from './Timer'
import { getDishes } from 'src/state/dishes'

export const MessageOrder = ({ index, paymentMessage, venueSlug }) => {
  const dispatch = useDispatch()
  const dishes = useSelector(getDishes)
  const optionsets = useSelector(getOptionsets)
  const payments = useSelector(getPayments).filter(p => p.takeoutNum === paymentMessage.takeout)
  const d = paymentMessage.dateSent
  return (
    <div
      style={{
        top: `${(index * 32) % (window.innerHeight - 280)}px`,
        left: `${(index * 32) % (window.innerWidth - 420)}px`
      }}
      className={s.msgOrder}
    >
      <Timer hideCountdown={true} showTime paidAt={d} />
      <div className={s.messageBody}>
        <div style={{ margin: '24px 26px 0px 26px' }} className={s.topInfo}>
          <div>{timedTogoRender({ takeoutNum: paymentMessage.takeout })}</div>
          <div>Сообщение от гостя</div>
        </div>
        <div className={s.messageContent}>{paymentMessage.msg}</div>
      </div>
      <div className={s.bottomButtons}>
        <div onClick={() => dispatch(removeMsg(paymentMessage))} className={s.butOn}>
          ОК
        </div>
        {payments.length > 0 ? (
          <PaymentCard
            acceptCb={() => dispatch(removeMsg(paymentMessage))}
            accept
            hideCountdown
            venueSlug={venueSlug}
            {...{ payment: payments[0], dishes, optionsets }}
          >
            <div className={s.butOn}>Открыть заказ</div>
          </PaymentCard>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

const timedTogoRender = p => (
  <div className={[s.typeIcons, s.table, s.mtop8, s.mbottom16].join(' ')}>
    <TimedTakeoutIcon className={s.togoIcon} width='47' height='31' fill={'black'} />
    <div className={[s.flex, s.fColumn, s.mleft8].join(' ')}>
      <div className={s.paymentNum}>{p.takeoutNum}</div>
    </div>
  </div>
)
