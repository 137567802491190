/** @todo move this to enum */
const supportedApplications = ['cashier', 'dishboard', 'manager', 'stoplist']

export const selectedApplicationByDomain = supportedApplications.find(application => {
  return window.location.hostname.split('.')[0].endsWith(application)
})

export const SERVICES_TYPE = {
  HERE: 'here',
  TAKEAWAY: 'takeaway',
  DELIVERY_TAXI: 'delivery_taxi',
  DELIVERY_NOVAPOSHTA: 'delivery_novaposhta',
  DELIVERY_OWN: 'delivery_own',
  DELIVERY_UKLON: 'delivery_uklon'
}
export const languages = {
  uk: 'Українська',
  ru: 'Русский'
}
