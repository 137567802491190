import React, { useState } from 'react'
import styles from './styles.module.scss'
import Slot from './Slot'
import { SERVICES_TYPE } from 'src/globals/enumerations'

const Slots = ({
  createServicesStoplistItem,
  deleteServicesStoplistItemById,
  datesRangeForActiveDay,
  setStoplistItems,
  activePayments,
  stoplistItems,
  timeStep,
  currentVenue
}) => {
  // const [isLoading, setIsLoading] = useState(false)
  const [currentLoadingItemIndex, setCurrentLoadingItemIndex] = useState(null)

  return (
    <div className={styles.slots}>
      {datesRangeForActiveDay?.map((date, index) => {
        const intervalMaxTime = new Date(date).setMinutes(new Date(date).getMinutes() + (timeStep || 60))
        const formattedDate = new Date(date).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })
        const formattedDateEnd = new Date(intervalMaxTime).toLocaleTimeString('ru-RU', {
          hour: '2-digit',
          minute: '2-digit'
        })

        const stopListItem = stoplistItems.find(
          item => date >= new Date(item.startingAt).getTime() && intervalMaxTime <= new Date(item.endingAt)
        )
        const activeDeliveriesOfSlot = activePayments.filter(
          payment => date >= new Date(payment?.timedTakeout).getTime() && date <= new Date(payment?.timedTakeout)
        )
        async function onSlotClick() {
          try {
            if (currentLoadingItemIndex) {
              return
            }
            setCurrentLoadingItemIndex(index)

            if (stopListItem) {
              await deleteServicesStoplistItemById(currentVenue, stopListItem?.id)
              setStoplistItems(stoplistItems.filter(item => item.id !== stopListItem?.id))
            } else {
              const resp = await createServicesStoplistItem(
                currentVenue,
                SERVICES_TYPE.DELIVERY_OWN,
                new Date(intervalMaxTime).toISOString(),
                new Date(date).toISOString()
              )
              // console.log(resp)
              setStoplistItems([...stoplistItems, resp])
            }
          } catch (err) {
            console.log(err)
          } finally {
            setCurrentLoadingItemIndex(null)
          }
        }

        return (
          <Slot
            key={index}
            isLoading={currentLoadingItemIndex === index}
            startTime={formattedDate}
            endTime={formattedDateEnd}
            activeDeliveriesOfSlot={activeDeliveriesOfSlot}
            isStopped={!!stopListItem}
            onClick={onSlotClick}
          />
        )
      })}
      {/* <div className={styles.slot}>{finishTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div> */}
    </div>
  )
}

export default Slots
