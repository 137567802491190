import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDishes, getOptionsets } from '../../../state/dish'
import { getStoplist } from '../../../state/stoplist'
import { getStoplistOnly } from '../../../state/stoplistonly'
import DishCard from '../DishCard'
import StoplistOverlay from './StoplistOverlay'
import classes from './Stoplist.module.scss'
import { Paper } from 'src/components/shared/ExMaterial'

const StoplistContent = ({ venueSlug, currentDishes, ...props }) => {
  const [selectedDish, setDish] = React.useState(false)
  const { catId } = useParams()

  const dishes = currentDishes
  const stoplist = useSelector(getStoplist)
  const optionsets = useSelector(getOptionsets)
  const catIdParsed = catId ? (catId === 'stoplist' ? catId : parseInt(catId)) : false
  const stoplistOnly = useSelector(getStoplistOnly)

  if (!dishes) {
    return null
  }

  const filteredDishes = dishes
  //   .filter(dish => (catIdParsed ? catIdParsed === dish.catId : true))
  //   .map(dish => {
  //     let stoplistField = false
  //     const filteredOptions = optionsets
  //       .filter(e => dish.recipeId === e.recipeId)
  //       .map(e => e.options)
  //       .reduce((acc, val) => acc.concat(val), [])
  //       .filter(option => stoplist.options.filter(e => e === option.modifierId).length > 0)
  //     if (stoplist.dishes.filter(e => e === dish.id).length > 0) {
  //       stoplistField = true
  //     }
  //     return {
  //       ...dish,
  //       stoplist: stoplistField,
  //       options: filteredOptions
  //     }
  //   })
  //   .filter(e => (stoplistOnly ? e.stoplist : true))
  //   .filter(dish => (venueSlug !== '4' ? dish.extended_menu !== true : true))

  return (
    <>
      <Paper style={{ marginTop: '100px' }} className={classes.root}>
        {filteredDishes.map(dishId => (
          <DishCard {...{ dishId }} key={dishId} onClick={() => setDish(dishId)} />
        ))}
        {selectedDish && <StoplistOverlay onClick={d => setDish(false)} dish={selectedDish} />}
      </Paper>
    </>
  )
}

export default StoplistContent
