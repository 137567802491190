import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as CrossIcon } from 'src/assets/ico-cross.svg'
import { ReactComponent as SearchIcon } from 'src/assets/ico-search.svg'
import { ShortPayment } from 'src/components/shared/ShortPayment'
import { config } from 'src/constants/url'
import { universalFetch } from 'src/utils/api'
import s from './Cashier.module.css'
import PaymentCard from './PaymentCard'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'

const getReadableDate = datetime => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]
  const d = new Date(datetime)
  const date = d.getDate() === new Date().getDate() ? '' : `${d.getDate()} ${months[d.getMonth()]}`
  const time = `${d.getHours()}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}`
  return { date, time }
}
export const isCashier = x => {
  const isCompleted = x.status.cashierClosed
  const isError = x.status.integrationCanceled || x.integrationStatus === 'error'
  const isStarted = x.status.integrationStarted || x.integrationStatus === 'started'
  if (isCompleted) {
    return true
  }
  if (isError) {
    return false
  }
  if (isStarted) {
    return true
  }
  return false
}

export const historyStatusIsError = x => {
  return x.status.integrationCanceled || x.integrationStatus === 'error'
}

export const historyStatus = x => {
  // const isCompleted = x.status.cashierConfirmed || x.status.integrationConfirmed || x.integrationStatus === 'finish'
  // if (isCompleted) {
  //   return 'confirmed'
  // }

  if (x.status.cashierConfirmed) {
    return 'cashier'
  }
  if (x.status.integrationConfirmed) {
    return 'integration'
  }
  const isError = x.status.integrationCanceled || x.integrationStatus === 'error'
  if (isError) {
    return 'error'
  }
  const isStarted = x.status.integrationStarted || x.integrationStatus === 'started'
  if (isStarted) {
    return 'started'
  }
  return 'unconfirm'
}

export const LogPaymentList = ({ payments, venueSlug, tabs }) => {
  const [searchVal, setSearch] = React.useState('')
  const [isSearch, setIsSearch] = React.useState(false)
  const [yesterdayOrders, setYesterdayOrders] = React.useState([])
  const [isYest, setIsYest] = React.useState(false)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]

  const isSearched = x => {
    if (searchVal.length < 4) return true

    return (
      `${x.takeoutNum}`.indexOf(searchVal) >= 0 ||
      (x.userPhone && `${x.userPhone}`.indexOf(searchVal) >= 0) ||
      (x.transactionId && `${x.transactionId}`.indexOf(searchVal) >= 0)
    )
  }

  const paymentsProcessed = isYest
    ? yesterdayOrders && yesterdayOrders.allOrders
      ? yesterdayOrders.allOrders.filter(isCashier).filter(isSearched)
      : []
    : payments.filter(isCashier).filter(isSearched)

  const processedShow = paymentsProcessed
    .sort((a, b) => new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime())
    .sort((a, b) => new Date(a.timedTakeout).getTime() - new Date(b.timedTakeout).getTime())
    .map(payment => {
      return (
        <PaymentCard {...{ venueSlug, payment }} key={`paymentcard-${payment.id}`}>
          <ShortPayment isRefund={payment.status && payment.status.refundStarted} isStatuses payment={payment} />
        </PaymentCard>
      )
    })

  return (
    <div className={s.logPaymentWrap}>
      <div className={s.logHeader}>
        {isSearch === true ? (
          <div className={s.iconHeader4}>
            <SearchIcon
              fill={`#797979`}
              className={s.absSearch}
              onClick={() => {
                setIsSearch(true)
              }}
              width='16'
            />
            <input
              placeholder={`${translations.search}`}
              className={s.inputSearch}
              value={searchVal}
              onChange={e => setSearch(e.target.value)}
            />
            <div
              className={s.iconHeader2}
              onClick={() => {
                setIsSearch(false)
                setSearch('')
              }}
            >
              <CrossIcon />
            </div>
          </div>
        ) : (
          <>
            <div className={s.mleft16}>{translations.history}</div>
            {tabs ? (
              <div className={s.selsel}>
                <div
                  onClick={() => {
                    setIsYest(true)
                    const yested = new Date(Date.now() - 24 * 3600 * 1000).toISOString().slice(0, 10).split('-')
                    const tod = new Date(Date.now()).toISOString().slice(0, 10).split('-')
                    universalFetch({
                      url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venueSlug}/orders/previous/${yested[1]}.${yested[2]}.${yested[0]}/${tod[1]}.${tod[2]}.${tod[0]}`,
                      auth: true,
                      cb: data => setYesterdayOrders(data)
                    })
                  }}
                  className={[s.mleft16, isYest ? s.selOpt : ''].join(' ')}
                >
                  {translations.yesterday}
                </div>
                <div
                  onClick={() => {
                    setYesterdayOrders([])
                    setIsYest(false)
                  }}
                  className={[s.mleft16, !isYest ? s.selOpt : ''].join(' ')}
                >
                  {translations.today}
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={[s.logIconWrapper, s.mright16].join(' ')}>
              <div onClick={() => setIsSearch(!isSearch)} className={s.logicon}>
                <SearchIcon fill={isSearch ? '#ffffff' : '#9A9A9A'} />
              </div>
            </div>
          </>
        )}
      </div>
      {processedShow}
    </div>
  )
}
