import { setIsAuthorized, setStatsGeneral } from 'src/state/general/actions'
import { addOptionsets } from '../state/dish'
import { fetchVenuesSuccess } from '../state/venues'
import { addDishes } from '../state/dishes'
import { addCategories } from '../state/categories'
import { addRoles } from '../state/roles'
import { addModifiers } from '../state/modifiers'
import { fetchStoplistDish, fetchStoplistOption } from '../state/stoplist'
import { fetchPaidPayments } from '../state/payments'
import { setNeedRestart } from '../state/status/actions'
import request from '../api/request'
import { getVenuesThunk } from '../state/venues/thunkActions'

export const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const universalFetch = async ({ endpoint, cb, method, data, notFrontOffice }, dispatch, retry) => {
  try {
    const result = await request(endpoint, {
      method,
      data,
      isFrontOffice: !notFrontOffice
    })

    if (cb) {
      cb(result.data)
    }
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 403) {
      console.log('Auth error!')
      dispatch && dispatch(setIsAuthorized(false))
    } else if ([404, 400].includes(e.response.status) === false) {
      console.log(e, 'Network error')
      dispatch && dispatch(setNeedRestart(true))

      if (!retry) {
        universalFetch({ endpoint, cb, method, data, notFrontOffice }, dispatch, true)
      }
    }
  }
}

export const fetchOrders = (venue, dispatch) =>
  universalFetch({
    endpoint: `/${venue}/orders`,
    cb: data => dispatch(fetchPaidPayments(data || []))
  })

export const fetchStoplists = async (venue, dispatch) => {
  await universalFetch({
    endpoint: `/${venue}/dishes-stoplist`,
    cb: data => dispatch(fetchStoplistDish(data))
  })
  await universalFetch({
    endpoint: `/${venue}/modifiers-stoplist`,
    cb: data => dispatch(fetchStoplistOption(data))
  })
}

export const resendApi = (venue, dispatch) => {
  universalFetch(
    {
      endpoint: '/optionsets',
      notFrontOffice: true,
      cb: data => dispatch(addOptionsets(data))
    },
    dispatch
  )

  dispatch(getVenuesThunk())

  universalFetch(
    {
      endpoint: `/${venue}/dishes`,
      cb: data => dispatch(addDishes(data))
    },
    dispatch
  )
  universalFetch({
    endpoint: '/categories',
    cb: data => dispatch(addCategories(data))
  })
  universalFetch({
    endpoint: '/roles',
    notFrontOffice: true,
    cb: data => dispatch(addRoles(data))
  })

  if (venue) {
    universalFetch({
      endpoint: `/${venue}/stats/daily`,
      cb: data => dispatch(setStatsGeneral(data))
    })
    universalFetch({
      endpoint: `/${venue}/modifiers`,
      cb: data => dispatch(addModifiers(data))
    })

    fetchStoplists(venue, dispatch)

    fetchOrders(venue, dispatch)
  }
}
