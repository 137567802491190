export const ADD_CATS = 'ADD_CATS'
export const ADD_SUBCATS = 'ADD_SUBCATS'
export const ADD_DISHES = 'ADD_DISHES'
export const ADD_OPTIONSETS = 'ADD_OPTIONSETS'
export const ADD_INGREDIENTS = 'ADD_INGREDIENTS'
export const TOGGLE_STOPLIST = 'TOGGLE_STOPLIST'
export const TOGGLE_EDIT = 'TOGGLE_EDIT'
export const ADD_SELECTED_OPTIONS = 'ADD_SELECTED_OPTIONS'
export const ADD_SELECTED_DISH = 'ADD_SELECTED_DISH'
export const REMOVE_SELECTED_DISH = 'REMOVE_SELECTED_DISH'
