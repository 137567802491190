import {
  FETCH_STOPLIST_DISH,
  FETCH_STOPLIST_OPTION,
  ADD_STOPLIST_DISHES_SSE,
  ADD_MODIFIER_TO_STOPLIST,
  REMOVE_MODIFIER_FROM_STOPLIST
} from './types'

// TODO: maybe convert to object with id's {(:id): {}}
/* State Shape
[

]
*/
const state = {
  dishes: [],
  // dishes: [85, 5],
  // options: [1, 7]
  options: []
}

const initialState = state

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_STOPLIST_DISH: {
      const { stoplist } = action.payload
      return { ...state, dishes: stoplist }
    }

    case FETCH_STOPLIST_OPTION: {
      const { stoplist } = action.payload
      return { ...state, options: stoplist }
    }

    case ADD_STOPLIST_DISHES_SSE: {
      const { stoplist } = action.payload
      return { ...state, dishes: stoplist }
    }

    case ADD_MODIFIER_TO_STOPLIST: {
      const { item } = action.payload
      return { ...state, options: [...state.options, item] }
    }

    case REMOVE_MODIFIER_FROM_STOPLIST: {
      const options = state.options.filter(option => option.modifierId !== action.id)
      return { ...state, options: options }
    }

    default:
      return state
  }
}
