import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReactComponent as CrossIcon } from 'src/assets/ico-cross.svg'
import { getDishById } from 'src/state/dish'
import { getStoplist } from 'src/state/stoplist'
import { getVenueBySlug, getVenues } from 'src/state/venues'
import classes from './StoplistOverlay.module.css'

const StolistOverlay = ({ venueSlug, onClick, dish, ...props }) => {
  return (
    <div className={classes.overlay}>
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>
            <div className={classes.toptext}>Категория</div>
            <div className={classes.bottomText}>Название блюда</div>
          </div>
          <div onClick={onClick} className={classes.closeIcon}>
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StolistOverlay
