import React, { useState } from 'react'
import { ReactComponent as CloseDocIcon } from 'src/assets/ico-close-88.svg'
import { ReactComponent as CashierDocIcon } from 'src/assets/ico-doc-88.svg'
import { ReactComponent as GroupIcon2 } from 'src/assets/ico-group-2.svg'
import { ReactComponent as GroupIcon } from 'src/assets/ico-group.svg'
import { ReactComponent as RegistrationDocIcon } from 'src/assets/ico-reg.svg'
import s from './Cashier.module.css'

var QRCode = require('qrcode.react')

export const Documentation = ({ showPopup }) => {
  console.log('Documentation -> Documentation')

  const [documentation, showDocumentation] = useState(false)

  const standalone = window.navigator.standalone === true

  return (
    <div
      style={{
        height: standalone ? 'calc(100vh - 100px)' : 'inherit',
        top: standalone ? '100px' : '60px'
      }}
      className={s.docPopup}
    >
      <div className={s.doc}>
        {documentation !== false && documentation < 3 ? (
          <div className={s.docMain}>
            <div className={s.docHr}></div>
            <div style={{ display: 'flex', minHeight: '610px', maxWidth: 'calc(100vw - 16px)' }}>
              <div className={s.docNavWrap}>
                <div
                  className={[s.docNav, documentation === 0 ? s.active : ''].join(' ')}
                  onClick={() => {
                    showDocumentation(0)
                  }}
                >
                  Раннер
                </div>
                <div
                  className={[s.docNav, documentation === 1 ? s.active : ''].join(' ')}
                  onClick={() => {
                    showDocumentation(1)
                  }}
                >
                  Кассир
                </div>
                <div
                  className={[s.docNav, documentation === 2 ? s.active : ''].join(' ')}
                  onClick={() => {
                    showDocumentation(2)
                  }}
                >
                  Менеджер
                </div>
              </div>
              <div className={s.docContent}>
                {documentation === 0 ? <Text1 /> : documentation === 1 ? <Text2 /> : <Text3 />}
              </div>
            </div>
            <div className={s.docHr}></div>
          </div>
        ) : documentation === 4 ? (
          <div className={s.docMain}>
            <div className={s.docHr}></div>
            <div style={{ display: 'flex', minHeight: '610px', maxWidth: 'calc(100vw - 16px)' }}>
              <div className={[s.docNavWrap4, s.newDocText].join(' ')}>
                <Text4 />
                <div>
                  Некоторые телефоны <span className={s.newDocWhite}>на Андроиде</span> не распознают ссылку в коде. В
                  этом случае нужно воспользоваться QR-ридером в <span className={s.newDocWhite}>Viber</span>.
                </div>
                <div className={s.docQR}>
                  <QRCode
                    size={219}
                    value={
                      window.mode === 'develop'
                        ? 'https://dev.khlebniy.com.ua/register'
                        : 'http://khlebniy.com.ua/register'
                    }
                  />
                </div>
                <div>
                  Если возникли любые проблемы с регистрацией, или пожелания что добавить или исправить – пишите в
                  телеграм{' '}
                  <span className={s.newDocWhite}>{window.busytable.brandSlug === 'hlbn' ? `@khlbn_support` : ''}</span>
                </div>
              </div>
            </div>
            <div className={s.docHr}></div>
          </div>
        ) : (
          <div className={s.docMainContent}>
            <GroupIcon />
            <div className={s.docMainContentAbs}>
              <GroupIcon2 />
            </div>
          </div>
        )}
      </div>
      <div className={s.docPopupNav}>
        <RegistrationDocIcon
          fill={documentation !== 4 ? 'transparent' : '#00E2FF'}
          stroke={documentation !== 4 ? '#00E2FF' : '#000000'}
          onClick={() => {
            showDocumentation(documentation !== 4 ? 4 : false)
          }}
          className={s.iconHeader3}
        />
        <CashierDocIcon
          fill={documentation !== false && documentation < 3 ? '#00E2FF' : 'transparent'}
          stroke={documentation !== false && documentation < 3 ? '#000000' : '#00E2FF'}
          onClick={() => {
            showDocumentation(documentation !== false ? false : 0)
          }}
          className={s.iconHeader3}
        />
        {/* <div className={s.docPopupNavGroup}>
              <ArrowBackIcon style={{ color: '#00E2FF', fontSize: 48, marginRight: '32px' }} width='24' />
              <ArrowForwardIcon style={{ color: '#00E2FF', fontSize: 48 }} width='24' />
            </div> */}
        <CloseDocIcon
          onClick={() => {
            showPopup(false)
          }}
          className={s.iconHeader3}
        />
      </div>
    </div>
  )
}

const Text1 = () => {
  return (
    <ul className={s.docText}>
      <li>Всегда оставлять гостю один номерок</li>
      <li>Если номерков больше, спросить, какие номерки можно забрать</li>
      <li>Если гость говорит "все", сказать "оставлю один, чтобы вы могли заказать в приложении"</li>
      <li>Нужно хорошо знать приложение, чтобы отвечать на вопросы гостей</li>
    </ul>
  )
}
const Text2 = () => {
  return (
    <ul className={s.docText}>
      <li>Выдавая номерок, кассир обращает внимание гостя на возможность заказать через приложение</li>
      <li>Если гость заказывал за столиком, заказы "с собой" приносим на номерок</li>
      <li>Если у заказа не указан номер стола, гость заберёт заказ самостоятельно</li>
    </ul>
  )
}
const Text3 = () => {
  return (
    <ul className={s.docText}>
      <li>Необходимо заносить блюда в стоплист. Стоплист обнуляется ночью сам.</li>
      <li>
        Если необходимо вернуть деньги, следует написать номер заказа и сумму в тг{' '}
        {window.busytable.brandSlug === 'hlbn' ? `@khlbn_support` : ''}
      </li>
      <li>
        О несоответствиях в меню или недостатках приложения необходимо писать в тг{' '}
        {window.busytable.brandSlug === 'hlbn' ? `@khlbn_support` : ''}
      </li>
      <li>Весь персонал, работающий с гостями, должен быть хорошо знаком с приложением</li>
    </ul>
  )
}

const Text4 = () => {
  return (
    <div className={[s.newDocText, s.docBlocks].join(' ')}>
      <div className={s.newDocHeader}>Регистрация в базе неплательщиков </div>
      <div>1. Со своего личного телефона навести стандартную Камеру на QR код. Откроется форма регистрации. </div>
      <div>
        2. Заполнить и отправить открывшуюся по коду анкету. Указать тип неплательщика, имя, фамилию. Код для
        регистрации <span className={s.newDocWhite}>london2020</span>{' '}
      </div>
      <div>
        3. Через меню <span className={s.newDocWhite}>“Связаться с нами”</span> написать в поддержку для подтверждения
        заявки, выбрав Telegram.
      </div>
    </div>
  )
}
