import { idsFromEntities, objFromArray } from '../../utils'
import { ADD_DISHES } from './types'

const initialState = {
  original: [],
  entities: {},
  ids: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_DISHES: {
      const { items } = action.payload
      const entities = objFromArray(items)
      return {
        original: items,
        entities: entities,
        ids: idsFromEntities({ entities })
      }
    }

    default:
      return state
  }
}
