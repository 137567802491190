import React, { useState } from 'react'
import styles from './styles.module.scss'
import { Button } from 'src/components/shared/ExMaterial'
import translationsManager from 'src/translations/manager'
import { getSelectedLanguage } from 'src/state/general/selectors'
import { useSelector } from 'react-redux'
import { getTipsStatsAsync } from 'src/api'

const getDate = extraDays => {
  const date = new Date(extraDays ? new Date().getTime() + 24 * extraDays * 60 * 60 * 1000 : new Date())

  date.setUTCHours(0, 0, 0, 0)

  return date.toISOString()
}

const TipsStats = () => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const [result, setResult] = useState(null)
  const [fromDate, setFromDate] = useState(getDate())
  const [dueDate, setDueDate] = useState(getDate(1))
  const [isLoading, setIsLoading] = useState(false)
  const venueSlug = localStorage.getItem('currentVenue')

  const getStats = async () => {
    try {
      setIsLoading(true)
      const resp = await getTipsStatsAsync(venueSlug, fromDate, dueDate)
      setResult(resp)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.outerWrapper}>
      <h4>{translations.tipsStats}</h4>
      {result && (
        <>
          <h5>
            {translations.totalCount}: {`${result ? result.paymentsCount : 0}`}
          </h5>
          <h5>
            {translations.tipsTotal}: {`${result ? result.paymentsSum : 0}грн.`}
          </h5>
        </>
      )}
      <div className={styles.wrapper}>
        <div className={styles.inputWrapper}>
          <span>{translations.fromDate}:</span>
          <input
            value={fromDate ? fromDate.split('T')[0] : ''}
            onChange={e => {
              setFromDate(new Date(e.nativeEvent.target.value).toISOString())
            }}
            type='date'
          />
        </div>
        <div className={styles.inputWrapper}>
          <span>{translations.dueDate}:</span>
          <input
            value={dueDate ? dueDate.split('T')[0] : ''}
            onChange={e => {
              setDueDate(new Date(e.nativeEvent.target.value).toISOString())
            }}
            type='date'
          />
        </div>
      </div>

      <Button
        className={`${isLoading ? styles.loadingButton : ''} ${!fromDate || !dueDate ? styles.disabledButton : ''}`}
        variant='contained'
        color='primary'
        onClick={getStats}
      >
        {translations.applyButton}
      </Button>
    </div>
  )
}
export default TipsStats
