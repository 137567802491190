import { useCallback, useMemo, useState } from 'react'
import request from '../api/request'

export default function useOrderApi(orderIds = []) {
  const venue = localStorage.getItem('currentVenue')
  const [orderState, setOrderState] = useState({})

  const sendOrderStatus = useCallback(
    (info = { status: null, integrationId: null }) => {
      const { status, integrationId, ...rest } = info
      orderIds.forEach(async id => {
        setOrderState(prevState => ({
          ...prevState,
          [id]: {
            ...prevState[id],
            loading: true,
            data: null,
            error: null
          }
        }))
        try {
          const response = await request(`/${venue}/setStatus`, {
            method: 'POST',
            data: {
              orderId: id,
              ...(info.status ? { status: info.status } : {}),
              ...(info.integrationId ? { integrationId: info.integrationId } : {}),
              ...rest
            }
          })
          setOrderState(prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              data: response.data,
              loading: false
            }
          }))
        } catch (error) {
          setOrderState(prevState => ({
            ...prevState,
            [id]: {
              ...prevState[id],
              error,
              loading: false
            }
          }))
        }
      })
    },
    [orderIds, venue, setOrderState]
  )

  const state = useMemo(
    () => ({
      orderState
    }),
    [orderState]
  )

  const api = useMemo(
    () => ({
      sendOrderStatus
    }),
    [sendOrderStatus]
  )

  return [state, api]
}
