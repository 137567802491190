const _isUkraineNumber = str => str.startsWith('380')
const _formatUkraine = str =>
  str.length >= 3 + 2 + 3 + 4
    ? `+${str.substr(0, 3)} (${str.substr(3, 2)}) ${str.substr(5, 3)} ${str.substr(8, 4)}`
    : str

const _availablePhoneCheckers = [{ checker: _isUkraineNumber, formatter: _formatUkraine }]

export const decoratePhone = str => {
  if (str) {
    if (typeof str !== 'string') {
      str = str.toString()
    }
    const isFoundLocale = _availablePhoneCheckers.find(x => x.checker(str))
    return isFoundLocale ? isFoundLocale.formatter(str) : str
  } else {
    return ''
  }
}
