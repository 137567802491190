import { findId } from '../../utils'

export const getCats = store => store.menu.cats
export const getEdit = store => store.menu.edit
export const getSelectedOptions = store => store.menu.selectedOptions
export const getSelectedDish = store => store.menu.selectedDish
export const getSubcats = store => store.menu.subcats
export const getDishes = store => store.menu.dishes
// export const getOptions = store => store.menu.options
export const getPopular = store => store.menu.popular
export const getOptionsets = store => store.menu.optionsets
export const getIngredients = store => store.menu.ingredients

export const getDishesWithoutSizes = store => store.menu.dishes.filter(x => Number.isInteger(x.parentSizeId) === false)

// use with hooks: useSelector(getDishById)(dishId)
export const getDishById = store => id => findId(id, store.menu.dishes)

// use with hooks: useSelector(getOptionsByRecipeId)(recipeId)
// export const getOptionsByRecipeId = store => recipeId => {
//   const options = store.menu.options.filter(x => x.recipeId === recipeId)

//   const compare = (a, b) => {
//     return parseInt(a.position) - parseInt(b.position)
//   }

//   return options.sort(compare)
// }

// returns sorted optionsets for specified recipe
// use with hooks: useSelector(getOptionsetsByRecipeId)(recipeId)
export const getOptionsetsByRecipeId = store => recipeId => {
  const optionsets = store.menu.optionsets.filter(x => x.recipeId === recipeId)

  return optionsets.sort(comparePosition)
}

const comparePosition = (a, b) => {
  return parseInt(a.position) - parseInt(b.position)
}
