import {
  SET_CARD_GENERAL,
  SET_LOGIN_GENERAL,
  SET_SETTINGS_GENERAL,
  SET_STATS_GENERAL,
  SET_VENUE_GENERAL,
  SET_IS_AUTHORIZED,
  SET_USER_PROFILE,
  SET_SELECTED_LANGUAGE,
  TOGGLE_ORDER_BOARD_ACTIVE,
  HANDLE_BRAND_INTEGRATION_STATUS
} from './types'

export const setVenueGeneral = venue => ({
  type: SET_VENUE_GENERAL,
  payload: { venue }
})

export const setCardGeneral = card => ({
  type: SET_CARD_GENERAL,
  payload: { card }
})

export const setSettingsGeneral = settings => ({
  type: SET_SETTINGS_GENERAL,
  payload: { settings }
})

export const setLoginGeneral = login => ({
  type: SET_LOGIN_GENERAL,
  payload: { login }
})

export const setIsAuthorized = isAuthorized => {
  return {
    type: SET_IS_AUTHORIZED,
    isAuthorized
  }
}

export const setStatsGeneral = stats => ({
  type: SET_STATS_GENERAL,
  payload: { stats }
})

export const setUserProfile = user => ({
  type: SET_USER_PROFILE,
  user
})
/**
 *
 * @param {string} selectedLanguage // 'ru', 'uk'
 */
export const setSelectedLanguage = selectedLanguage => ({
  type: SET_SELECTED_LANGUAGE,
  selectedLanguage
})

export const toggleOrderBoardActive = status => ({
  type: TOGGLE_ORDER_BOARD_ACTIVE,
  status
})

export const handleIntegrationStatus = isActive => ({
  type: HANDLE_BRAND_INTEGRATION_STATUS,
  isActive
})
