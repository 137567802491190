import { getAppPreferencesStarted, getAppPreferencesError, getAppPreferencesSuccess } from './actions'
import { getAppPreferences } from 'src/api'

export const getAppPreferencesThunk = async dispatch => {
  dispatch(getAppPreferencesStarted())
  try {
    const appPreferences = await getAppPreferences()
    dispatch(getAppPreferencesSuccess(appPreferences))
  } catch (error) {
    dispatch(getAppPreferencesError(error))
  }
}
