import axios from 'axios'
import { config } from 'src/constants/url'
import { addModifierToStoplist, removeModifierFromStoplist } from 'src/state/stoplist'
import { addError } from 'src/state/status/actions'
import frontOfficeRequest from 'src/api/request'
import { authHeader } from './utils/api'

// import { addTranslations } from './state/translation'
// import { fetchPaidOrders, processWebsocketOrder } from './state/orders'
// import { fetchVenues } from './state/venues'
// import { changeLanguage } from './state/shared'
// export const fetchOptionsets = async () => {
//   const result = await axios(`${config.API_URL}/v1/ru/1/menu/optionsets`)
//   setOptionsets(result.data)
// }

export const getModifiers = async (login, venue) => {
  var errorVar = false
  const url = `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venue}/modifiers`
  try {
    const result = await axios(url, authHeader).catch(e => {
      console.log(`catch`, e)
      if (e.response) {
        return e.response.status
      } else {
        return '407'
      }
    })
    return result.data
  } catch (err) {
    console.log(`catch2`)
    errorVar = true
    if (err.message === '407') {
    } else if (err.message === '404') {
      console.log('err', err)
    } else if (err.message === '401') {
      return '401'
    } else {
      return err
    }
  }
}

/**
 * Doing post user event to server.
 * @param {string} type - The type of the event.
 * @param {string} msg - The payload of the event.
 */
export const postEvent = (type, msg, resend = false) => {
  var errorVar = false
  try {
    axios.post(
      `${config.API_URL}/v3/${window.busytable.brandSlug}/log/cashier/events`,
      { type: '[event] ' + type, message: msg, resend: resend },
      authHeader
    )
  } catch (err) {
    if (err.message === '407') {
      setTimeout(() => {
        // postEvent(type, msg, resend ? resend + ' RESEND' : 'RESEND')
        console.log('resend')
      }, 4000)
    } else if (err.message === '404') {
      console.log('err', err)
    } else if (err.message === '401') {
      const str = 'Auth error!'
    } else {
      // console.log('err', err)
    }
  }
}

/**
 * Doing post user event to server.
 * @param {string} type - The type of the event.
 * @param {string} msg - The payload of the event.
 */

export const EVENT_ACTIONS = {
  INTEGRATION_ERROR: 'INTEGRATION_ERROR'
}

export const EVENT_CATEGORY = {
  INTEGRATION: 'INTEGRATION'
}

export const postEventV3 = (action, category, data) => {
  try {
    axios.post(`${config.API_URL}/v3/${window.busytable.brandSlug}/event`, { action, category, data }, authHeader)
  } catch (err) {
    if (err.message === '407') {
      // setTimeout(() => {
      // postEvent(type, msg, resend ? resend + ' RESEND' : 'RESEND')
      console.log('resend')
      // }, 4000)
    } else if (err.message === '404') {
      console.log('err', err)
    } else if (err.message === '401') {
      // const str = 'Auth error!'
    } else {
      // console.log('err', err)
    }
  }
}

export const fetchMoveOrder = (toVenue, paymentId) => {
  try {
    axios.post(
      `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/moveOrder/`,
      { toVenue, paymentsId: parseInt(paymentId) },
      authHeader
    )
  } catch (err) {
    console.log('row error')
    console.log(err)
  }
}

export const fetchRefundPayment = async ({ currentVenue, paymentId, sum, refundReason, orders = [] }, callback) => {
  try {
    const res = await axios.post(
      `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${currentVenue}/refund/`,
      { paymentId, sum, refundReason, orders },
      authHeader
    )
    if (callback) {
      if (res.status === 200 && res.data) {
        callback(res.data)
      }
    }
  } catch (err) {
    console.log('row error')
    console.log(err)
  }
}

export const postDailyStats = (cashier, totalOffline, period, venueId, currentVenue) => {
  try {
    axios.post(
      `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${currentVenue}/offlineData/add`,
      { cashier, totalOffline, period, venueId },
      authHeader
    )
  } catch (err) {
    console.log('row error')
    console.log(err)
  }
}

export const getOfflineData = async (period, currentVenue) => {
  try {
    return await axios.post(
      `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${currentVenue}/offlineData`,
      { period },
      authHeader
    )
  } catch (err) {
    console.log('row error')
    console.log(err)
  }
}

// export const useTimerPing = (login, venues, currentVenue) => {
//   const dispatch = useDispatch()
//   const [timer, setTimer] = useState()
//   const payments = useSelector(getPayments)
//   useEffect(() => {
//     clearInterval(timer)
//     setTimer(
//       setInterval(() => {
//         if (!currentVenue) return
//         if (window.__error) {
//           clearInterval(this)
//           return
//         }
//         // console.log(`[window.__error]`, window.__error)
//         axios(`${config.API_URL}/v2/frontoffice/${window.busytable.brandSlug}/${currentVenue}/ping`, authHeader)
//           .then(e => {
//             if (e.status !== 200) {
//               throw new Error(e.status)
//             } else {
//               // dispatch(showSnackbar(false)) // hide error
//               const res = e.data
//               if (res.status === 'ok') {
//                 const newId = res.lastPayment
//                 // console.log(newId)
//                 if (!newId) {
//                   //pass
//                 } else {
//                   if (payments.some(e => e.id === newId)) {
//                     // pass
//                   } else {
//                     // console.log('reload')
//                     // window.location.reload()
//                     universalFetch(
//                       {
//                         url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${currentVenue}/orders`,
//                         auth: true,
//                         cb: data => dispatch(fetchPaidPayments(data))
//                       },
//                       dispatch
//                     )
//                   }
//                 }
//               }
//             }
//           })
//           .catch(e => {
//             console.error(e)
//             // setError('no internet')
//             // console.log(e.response.status)
//             console.log('no internet')
//             if (e && e.response && e.response.status === 401) {
//               dispatch(showSnackbar('Нет доступа!'))
//               return
//             }
//             dispatch(showSnackbar('Сервер недоступен!'))
//           })
//       }, 10 * 1000)
//     )
//   }, [currentVenue, login, venues]) // eslint-disable-line
// }

export const fetchSettings = async dispatch => {
  try {
    const res = integrationFetch('/test', false, 'GET', null, errorObj => {
      console.log(errorObj)
      if (dispatch) {
        dispatch(
          addError(
            `Произошла ошибка, Кассовый аппарат не отвечает. Проверьте соединение с кассовым аппаратом!\n ${JSON.stringify(
              errorObj
            )}`
          )
        )
      } else {
        alert(
          `Произошла ошибка, Кассовый аппарат не отвечает. Проверьте соединение с кассовым аппаратом!\n ${JSON.stringify(
            errorObj
          )}`
        )
      }
    })
    console.log(JSON.stringify(res.data))
    return res
  } catch (err) {
    console.log(err)
    return true
  }
}

export const fetchAddStoplistOption = async (venue, data, dispatch) => {
  const res = await axios({
    url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venue}/modifiers-stoplist`,
    data: {
      modifierId: data.ingredientId
    },
    method: 'POST',
    ...authHeader
  })
  if (res && res.status < 300) {
    // console.log(res.status, res.statusText)
    dispatch(addModifierToStoplist({ modifierId: data.ingredientId }))
  }
}

export const fetchRemoveStoplistOption = async (venue, data, dispatch) => {
  const res = await axios({
    url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venue}/modifiers-stoplist`,
    data: {
      modifierId: data.ingredientId
    },
    method: 'DELETE',
    ...authHeader
  })
  if (res && res.status < 300) {
    // console.log(res.status, res.statusText)
    dispatch(removeModifierFromStoplist(data.ingredientId))
  }
}

export const fetchRemoveAuth = async () => {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/auth/token/del`,
    method: 'DELETE',
    ...authHeader
  })
    .then(resp => {
      if (resp) {
        return resp
      }
    })
    .catch(e => {
      return e
    })
}

export const fetchAddStoplistDish = async (venue, data) => {
  await axios({
    url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/` + venue + '/dishes-stoplist',
    method: 'POST',
    data: {
      dishId: data.dishId,
      permanent: data.permanent
    },
    ...authHeader
  })
}

export const fetchDisableVenue = async venue => {
  await axios({
    url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venue}/changeState/venue`,
    method: 'POST',
    ...authHeader
  })
}

export const fetchRemoveStoplistDish = async (venue, data) => {
  await axios({
    url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/` + venue + '/dishes-stoplist/' + data.dishId,
    method: 'DELETE',
    ...authHeader
  })
}

export const addModifierToStoplistAsync = async ({ modifierId, venueSlug, dispatch }) => {
  try {
    await axios({
      url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venueSlug}/modifiers-stoplist`,
      data: {
        modifierId: modifierId
      },
      method: 'POST',
      ...authHeader
    })
    dispatch(addModifierToStoplist({ modifierId, permanent: false }))
  } catch (e) {
    console.error('Error while adding modifier to stoplist')
    console.error(e)
  }
}

export const removeModifierFromStoplistAsync = async ({ modifierId, venueSlug, dispatch }) => {
  try {
    await axios({
      url: `${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${venueSlug}/modifiers-stoplist`,
      data: {
        modifierId: modifierId
      },
      method: 'DELETE',
      ...authHeader
    })
    dispatch(removeModifierFromStoplist(modifierId))
  } catch (e) {
    console.error('Error while removing modifier from stoplist')
    console.error(e)
  }
}

const ENCODING = 'base64'

function token(user, pass) {
  return base64([user, pass].join(':'))
}

function base64(string) {
  return new Buffer(string).toString(ENCODING)
}

export const integrationFetch = async (url, cb, method, body, errHandler) => {
  var errorVar = false
  const _ip = localStorage.getItem('_integration_ip') || ''
  const _login = localStorage.getItem('_integration_login') || ''
  const _password = localStorage.getItem('_integration_password') || ''
  const _url = localStorage.getItem('_integration_url') || ''
  try {
    const result = await axios({
      url: `https://${_ip}${_url}${url}`,
      method: method || 'GET',
      data: body,
      headers: {
        Authorization: _login && _login.length > 0 ? `Basic ${token(_login, _password)}` : '',
        'Content-Type': 'application/json'
      }
    }).catch(e => {
      // window.location.reload()
      if (e.response) {
        throw new Error(e.response.status)
      } else {
        throw new Error('407')
      }
    })
    if (cb) {
      cb(result)
    }
    return result
    // setError(false)
  } catch (err) {
    if (errHandler) {
      errHandler(err)
    } else {
      errorVar = true
      console.log('err', err)
    }
    return err
  }
}

export const getVenuesForLogin = async () => {
  return await axios
    .get(`${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/venues`)
    .then(resp => {
      if (resp) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const fetchSelfProfile = () => {
  const url = `${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/users/self`
  return axios
    .get(url, authHeader)
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      console.log(err)
    })
}

export async function getAppPreferences() {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/appPreferences`
  })
    .then(resp => {
      if (resp?.data && resp?.data?.success) {
        return resp?.data?.preferences
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
/**
 * Services Stoplist START
 */

/**
 *
 * @param {string} venue
 * @param {SERVICES_TYPE} type
 * @param {string} fromDate
 * @param {string} toDate
 */
export async function getServicesInStopList(venue, type, fromDate, toDate) {
  return frontOfficeRequest(`/${venue}/servicesStoplist`, {
    params: {
      fromDate,
      type,
      toDate
    }
  })
    .then(resp => resp.data?.data)
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
/**
 *
 * @param {string} venue
 * @param {SERVICES_TYPE} type
 * @param {string} fromDate
 * @param {string} toDate
 */
export async function createServicesStoplistItem(venue, type, endingAt, startingAt) {
  return axios
    .post(
      `${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/${venue}/servicesStoplist`,
      { type, endingAt, startingAt },
      authHeader
    )
    .then(resp => {
      if (resp?.data && resp?.data?.success) {
        return resp?.data?.data
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}

/**
 *
 * @param {string} venue
 * @param {string} fromDate
 * @param {string} toDate
 */
export async function getTipsStatsAsync(venue, fromDate, toDate) {
  return axios
    .get(`${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/${venue}/stats/tips`, {
      params: {
        fromDate,
        toDate
      },
      ...authHeader
    })
    .then(resp => {
      if (resp && resp.data) {
        return resp.data
      }
    })
    .catch(e => {
      if (e.response.data) {
        throw Error(e.response.data)
      } else {
        throw Error(e)
      }
    })
}
/**
 *
 * @param {string} venue
 * @param {string} id
 */
export async function deleteServicesStoplistItemById(venueSlug, id) {
  return axios
    .delete(
      `${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/${venueSlug}/servicesStoplist/${id}`,
      authHeader
    )
    .then(resp => {
      if (resp?.data) {
        return resp?.data
      }
    })
    .catch(e => {
      throw new Error(e)
    })
}
/**
 *
 * @param {string} venueSlug
 * @param {SERVICES_TYPE} type
 * @param {string} fromDate
 * @param {string} toDate
 */
export function getPaymentsByFilters(type, fromDate, toDate, venueSlug) {
  const endpoint = `/${venueSlug}/orders/search-payments`

  return frontOfficeRequest(endpoint, {
    params: {
      fromDate,
      type,
      toDate
    }
  }).catch(e => {
    if (e.message) {
      throw Error(e.message)
    } else {
      throw Error(e)
    }
  })
}

/**
 * Services Stoplist END
 */
