import React, { useMemo, useState, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import useAppPreferences from 'src/hooks/useAppPreferences'
import Slots from './Slots'
import { useSelector } from 'react-redux'
import { datesRange } from 'src/utils'
import {
  getServicesInStopList,
  getPaymentsByFilters,
  createServicesStoplistItem,
  deleteServicesStoplistItemById
} from 'src/api'
import { getVenueBySlug } from 'src/state/venues'
import StoplistHeader from 'src/components/stoplist/Stoplist/StoplistHeader'
import { useWindowSize } from 'src/shared/utils/useWindowSize'
import translationsManager from 'src/translations/manager'
import { getSelectedLanguage } from 'src/state/general/selectors'

const ServicesStoplist = () => {
  const { services_type } = useParams()
  const { preferences } = useAppPreferences()
  const history = useHistory()
  const venueSlug = localStorage.getItem('currentVenue')
  const [activeDay, setActiveDay] = useState(0)
  const [activePayments, setActivePayments] = useState([])
  const deliveryDays = preferences?.preorderOnFutureDaysNumber || 2
  const venue = useSelector(store => getVenueBySlug(store)(venueSlug))
  const [stoplistItems, setStoplistItems] = useState([])
  const [isStoplistLoading, setStoplistloading] = useState(false)
  const [isPaymentsLoading, setIsPaymentsLoading] = useState(false)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]

  useEffect(() => {
    if (venue) {
      fetchServiceStoplistItems()
      fetchPaymentsForActiveDay()
    }
  }, [venue, activeDay])

  /**
   * @returns fromDate in 'Date' format
   */
  const fromDate = useMemo(() => {
    const now = new Date()
    return activeDay === 0
      ? now
      : new Date(new Date(now.getTime() + activeDay * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  }, [activeDay])

  const toDate = new Date(new Date(fromDate).setHours(23, 59, 59, 999)).toISOString()

  async function fetchPaymentsForActiveDay() {
    setIsPaymentsLoading(true)
    try {
      const resp = await getPaymentsByFilters(services_type, new Date(fromDate).toISOString(), toDate, venueSlug)
      setActivePayments(resp.data)
    } catch (e) {
      console.log(`Error while getting list of services stoplist`)
    } finally {
      setIsPaymentsLoading(false)
    }
  }

  async function fetchServiceStoplistItems() {
    try {
      setStoplistloading(true)
      const resp = await getServicesInStopList(venueSlug, services_type, new Date(fromDate).toISOString(), toDate)
      setStoplistItems(resp)
    } catch (e) {
      console.log(`Error while getting list of services stoplist`)
    } finally {
      setStoplistloading(false)
    }
  }
  /**
   * @returns a list of dates in number format for active days
   */
  const datesRangeForActiveDay = useMemo(() => {
    if (Object.keys(venue)?.length) {
      return datesRange(venue?.workingHours, fromDate, preferences?.timePickerStep, 1)
    } else {
      history.push('/stoplist')
      return null
    }
  }, [venue, preferences, activeDay])

  const SubToolbar = ({ activeDay }) => {
    const viewportSize = useWindowSize()
    const ref = useRef(null)
    const [overflow, setOverflow] = useState(false)

    useEffect(() => {
      if (ref.current) {
        if (ref.current.clientWidth > viewportSize.width) {
          setOverflow(true)
        } else {
          setOverflow(false)
        }
      }
    }, [viewportSize])

    return (
      <div className={styles.subToolbar} style={overflow ? { justifyContent: 'start' } : { justifyContent: 'center' }}>
        <div ref={ref} className={styles.period}>
          {[...Array(deliveryDays + 1)].map((_, i) => {
            const today = new Date()
            const options = { weekday: 'short' }
            const currentDay = new Date(new Date().setDate(today.getDate() + i))
            const dayName = currentDay.toLocaleDateString('ru-RU', options)
            return (
              <Day
                activeDay={activeDay}
                setActiveDay={setActiveDay}
                dayname={dayName}
                daynumber={currentDay.getDate()}
                key={i}
                idx={i}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const Title = ({ title }) => (
    <div className={styles.title} color='inherit'>
      <div className={styles.flexTitle}>
        <div className={styles.topText}>Стоп-лист</div>
        <div className={styles.bottomText}>{title}</div>
      </div>
    </div>
  )

  const Day = ({ idx, dayname, daynumber, activeDay, setActiveDay }) => {
    return (
      <div className={`${styles.day} ${activeDay === idx && styles.active}`} onClick={() => setActiveDay(idx)}>
        {dayname}
        <svg width='24' height='24' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='12' cy='12' r='4' />
        </svg>
        {daynumber}
      </div>
    )
  }

  return (
    <>
      <StoplistHeader
        {...{ venueSlug }}
        title={<Title title={translations[services_type]} />}
        subToolbar={<SubToolbar activeDay={activeDay} />}
      />
      <Slots
        createServicesStoplistItem={createServicesStoplistItem}
        deleteServicesStoplistItemById={deleteServicesStoplistItemById}
        activePayments={activePayments}
        setStoplistItems={setStoplistItems}
        stoplistItems={stoplistItems}
        datesRangeForActiveDay={datesRangeForActiveDay}
        activeDay={activeDay}
        timeStep={preferences?.timePickerStep}
        currentVenue={venueSlug}
      />
    </>
  )
}

export default ServicesStoplist
