import { ADD_TIMER, REMOVE_TIMER } from './types'

export const addTimer = id => ({
  type: ADD_TIMER,
  payload: { id }
})

export const removeTimer = id => ({
  type: REMOVE_TIMER,
  payload: { id }
})
