import React from 'react'
import s from './Cashier.module.css'

export const CashierPopup = ({ onClose = () => {}, onCloseButton = () => {}, children }) => (
  <div onClick={onClose} className={s.contect}>
    <div className={s.contectContent}>
      {children}
      <div onClick={onCloseButton} className={s.contectClose}>
        Закрыть
      </div>
    </div>
  </div>
)
