import React, { useEffect } from 'react'
// import { stopListAdd, stopListRemove } from '../../../actions/stopList'
import { fetchAddStoplistOption, fetchRemoveStoplistOption } from 'src/api'
import { useDispatch, useSelector } from 'react-redux'
import classes from './DishCard.module.css'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'

const OptionCard = ({ name, stoplist, id, iid, venueSlug }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const dispatch = useDispatch()
  useEffect(() => {
    document.title = 'Менеджер'
  }, [])
  const [selected, setSelected] = React.useState(false)
  return (
    <div
      onClick={_ => setSelected(!selected)}
      className={[classes.card, stoplist ? classes.stoplisted : '', selected ? classes.selected : ''].join(' ')}
    >
      <div className={classes.font}>{name}</div>
      {selected ? (
        <div
          onClick={e => {
            if (!stoplist) {
              fetchAddStoplistOption(venueSlug, { ingredientId: id }, dispatch)
            } else {
              fetchRemoveStoplistOption(venueSlug, { ingredientId: id }, dispatch)
            }
            e.stopPropagation()
          }}
          className={[classes.font, classes.fontAdd].join(' ')}
        >
          {!stoplist ? translations.addToStopList : translations.removeFromStopList}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default OptionCard
