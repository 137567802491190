import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Dishboard from './components/dishboard/Dishboard'
import { Cashier } from './components/cashier/CashierView/Cashier'

import { ModifiersStoplist } from './components/stoplist/ModifiersStoplist'
import OrderMove from './components/stoplist/OrderMove'
import Stats from './components/stoplist/Stats/Stats'
import StopList, { StopDish } from './components/stoplist/Stoplist'
import LoginContainer from './containers/Login'
import { useSelector } from 'react-redux'

import { selectedApplicationByDomain } from 'src/globals/enumerations'
import ServicesStoplist from './containers/ServicesStoplist'
import RefundReceipt from './components/manager/RefundReceipt'

export default function AppPicker() {
  const { isAuthorized } = useSelector(store => store.general)

  if (!isAuthorized) {
    return (
      <Router>
        <LoginContainer role={selectedApplicationByDomain} />
      </Router>
    )
  }

  function renderHomePage() {
    switch (selectedApplicationByDomain) {
      case 'cashier':
        return (
          <Route to='/'>
            <Cashier />
          </Route>
        )
      case 'dishboard':
        return (
          <Route>
            <Dishboard />
          </Route>
        )
      case 'manager':
      case 'stoplist':
        return (
          <>
            <Route
              exact
              path='/'
              render={props => (
                <StopList
                  {...{
                    props
                  }}
                />
              )}
            />
            <Route
              exact
              path='/stats'
              render={props => (
                <Stats
                  {...{
                    props
                  }}
                />
              )}
            />
            <Route exact path='/moveOrder'>
              <OrderMove />
            </Route>
            <Route exact path='/services-stoplist/:services_type'>
              <ServicesStoplist />
            </Route>
            <Route
              exact
              path='/cat/:catId'
              render={props => (
                <StopList
                  {...{
                    props
                  }}
                />
              )}
            />
            <Route
              path='/stoplist'
              render={props => (
                <ModifiersStoplist
                  {...{
                    props
                  }}
                />
              )}
            />
            <Route
              path='/dish/:dishId'
              render={props => (
                <StopDish
                  {...{
                    props
                  }}
                />
              )}
            />
            <Route path='/refund-details/:paymentId'>
              <RefundReceipt />
            </Route>
          </>
        )
      default:
        return null
    }
  }

  return <Router>{renderHomePage()}</Router>
}
