import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from '../../containers/Login/styles.module.scss'
import { t } from '../../utils'
import { getGeneralVenue, setVenueGeneral } from '../../state/general'
import { getVenues, getVenuesLoading } from '../../state/venues'
import { getSelectedLanguage } from '../../state/general/selectors'
import translationsCashier from '../../translations/cashier'

export default function VenueSelector({ onSelect, className }) {
  const dispatch = useDispatch()
  const venues = useSelector(getVenues)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const selectedVenue = useSelector(getGeneralVenue)
  const isVenuesLoading = useSelector(getVenuesLoading)
  const { isAuthorized, user } = useSelector(state => state.general)
  const translations = translationsCashier[selectedLanguage]

  useEffect(() => {
    if (venues.length === 1 && !selectedVenue) {
      localStorage.setItem('currentVenue', venues[0].slug)
      dispatch(setVenueGeneral(venues[0].slug))
    }
  }, [venues, selectedVenue])

  const filteredVenues = useMemo(() => {
    if (!isAuthorized || !user?.venues) {
      return venues
    }
    if (user.venues?.includes(-1)) {
      return venues
    }
    return venues.filter(venue => user.venues.includes(venue.id))
  }, [venues, isAuthorized, user, selectedVenue])

  const handleSelectVenue = useCallback(
    event => {
      const venueSlug = event.target.value

      localStorage.setItem('currentVenue', venueSlug)
      dispatch(setVenueGeneral(venueSlug))

      if (onSelect) {
        onSelect()
      }
    },
    [dispatch, onSelect]
  )

  if (venues.length > 1) {
    return (
      <select
        onChange={handleSelectVenue}
        className={`${className || ''} ${isVenuesLoading ? classes.selectLoading : ''} ${classes.selectOpt}`}
        value={selectedVenue}
      >
        <option value='' disabled>
          {translations.entrySelectionRestaurant}
        </option>
        {filteredVenues.map(x => (
          <option disabled={selectedVenue === x.slug} key={x.id} value={x.slug}>
            {t(x.name, selectedLanguage)}
          </option>
        ))}
      </select>
    )
  }

  return null
}
