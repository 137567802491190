import { SHOW_SNACKBAR, HIDE_SNACKBAR } from './types'

export const initialState = false

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const { content } = action.payload
      return content
    }

    case HIDE_SNACKBAR: {
      return false
    }

    default:
      return state
  }
}
