import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from 'src/components/shared/ExMaterial'
import { config } from 'src/constants/url'
import { useSocketAuth } from 'src/hooks/useSocketAuth'
import SnackBannerContainer from 'src/components/shared/SnackBanner'
import { getGeneralVenue } from 'src/state/general'
import { useHistory, useLocation } from 'react-router-dom'
import { setIsAuthorized } from 'src/state/general/actions'
import axios from 'axios'
import { selectedApplicationByDomain } from 'src/globals/enumerations'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import VenueSelector from 'src/components/general/VenueSelector'
import classes from './styles.module.scss'

const QRCode = require('qrcode.react')

export default function LoginContainer({ role }) {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const selectedVenue = useSelector(getGeneralVenue)
  const translations = translationsCashier[selectedLanguage]

  const { messages, banner, setBanner } = useSocketAuth(selectedVenue, role)
  const mode = location?.state?.mode || 'authorization' // registration || authorization

  //
  // Try to identify user role on first render
  //
  useEffect(function () {
    async function getUserRoleCapabilities() {
      const app = selectedApplicationByDomain === 'stoplist' ? 'manager' : selectedApplicationByDomain
      await axios(
        `${config.API_URL3}/frontoffice/${window.busytable.brandSlug}/${selectedVenue}/users/authcheck/${app}`,
        {
          method: 'GET',
          withCredentials: true,
          headers: {
            verified: 'yes'
          }
        }
      )
        .then(promise => {
          /** @todo save user to redux when returned */
          dispatch(setIsAuthorized(true))
        })
        .catch(error => {
          console.error('getUserRoleCapabilities error', error)
        })
    }

    if (typeof selectedVenue === 'string' && selectedVenue.length >= 0) {
      getUserRoleCapabilities()
    }
  }, [])

  /**
   * Other rendering logic
   * @todo refactor
   */

  function handlePressBannerRemove() {
    setBanner(null)
  }

  function getHeaderTitleStringBy(currentRole) {
    if (currentRole === 'cashier') {
      return translations.entryHeaderCashier
    }
    if (currentRole === 'manager') {
      return translations.entryHeaderManager
    }
    if (currentRole === 'dishboard') {
      return translations.entryHeaderDishBoard
    }

    return ''
  }

  function renderQRCOde() {
    const loginUrl = `${config.ORIGIN_URL}/auth/remote/${messages}`
    const registerUrl = `${config.ORIGIN_URL}/register/${selectedVenue}`

    if (!selectedVenue || !messages) {
      return null
    }

    if (mode === 'registration') {
      return <QRCode size={240} id='dvContainer' value={registerUrl} />
    }
    if (mode === 'authorization') {
      return <QRCode size={240} id='dvContainer' value={loginUrl} />
    }

    return null
  }

  function onModeChange(type) {
    // changing query param in the location URL
    history.push({
      pathname: '/',
      search: `?mode=${type}`,
      state: {
        mode: type
      }
    })
  }

  return (
    <div className={classes.loginBg}>
      {banner ? <SnackBannerContainer settings={banner} handleRemove={handlePressBannerRemove} /> : null}
      <div className={classes.header}>
        {translations.entryHeaderStation}
        {getHeaderTitleStringBy(role)}
      </div>
      <div className={classes.loginBody}>
        <div className={classes.typeSwitcher}>
          <div
            onClick={() => onModeChange('authorization')}
            className={mode === 'authorization' ? classes.activeTab : ''}
          >
            {translations.entryTabLogin}
          </div>

          <div
            onClick={() => onModeChange('registration')}
            className={mode === 'registration' ? classes.activeTab : ''}
          >
            {translations.entryTabRegister}
          </div>
        </div>
        <form noValidate className={clsx(classes.root)}>
          <VenueSelector className={classes.venueSelector} />
          {selectedVenue && messages ? (
            <Typography variant='body1' className={classes.qrwrap}>
              {mode === 'registration'
                ? translations.entryExplanationRegister(getHeaderTitleStringBy(role))
                : translations.entryExplanationLogin(getHeaderTitleStringBy(role))}
            </Typography>
          ) : null}
          <div className={`${classes.qrwr} ${!selectedVenue || !messages ? classes.withoutCode : ''}`}>
            {renderQRCOde()}
          </div>
        </form>
      </div>
    </div>
  )
}
