import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
// import LogRocket from 'logrocket'

const enhancer = composeWithDevTools(applyMiddleware(thunk))
// const composedEnhancers = compose(enhancer, applyMiddleware(LogRocket.reduxMiddleware()))
// applyMiddleware(thunk)
// other store enhancers if any
const store = createStore(rootReducer, enhancer)

window._redux_store = store

export default store
