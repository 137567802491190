import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRemoveAuth, integrationFetch, EVENT_ACTIONS, EVENT_CATEGORY, postEventV3 } from 'src/api'
import { universalFetch } from 'src/utils/api'
import { ReactComponent as AutorenewIcon } from 'src/assets/ico-autorenew-24px.svg'
import { ReactComponent as ExitIcon } from 'src/assets/ico-exit-to-app-24px.svg'
import { ReactComponent as HelpIcon } from 'src/assets/ico-help-outline-24px.svg'
import { ReactComponent as HlebIcon } from 'src/assets/ico-hleb.svg'
import { ReactComponent as FoldIcon } from 'src/assets/ico-fold.svg'
import { ReactComponent as IntegrationIcon } from 'src/assets/ico-integration-ok.svg'
import { ReactComponent as IntegrationProgress } from 'src/assets/ico-integration-progress.svg'
import { ReactComponent as IntegrationErrorIcon } from 'src/assets/ico-integration.svg'
import { ReactComponent as PluginIcon } from 'src/assets/ico-plugin.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/ico-settings.svg'
import { ReactComponent as MoreIcon } from 'src/assets/ico-tridots.svg'
import { ReactComponent as UnplugIcon } from 'src/assets/ico-unplug.svg'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from 'src/components/shared/ExMaterial'
import { getPayments } from 'src/state/payments'
import { getSnackbar } from 'src/state/snackbar/selectors'
import { setIntegration } from 'src/state/status'
import { getApiAvailability, getIntegrationStatus, getIsOffline } from 'src/state/status/selectors'
import strings from 'src/strings'
import { t } from 'src/utils'
import { addError } from '../../../state/status'
import s from './Cashier.module.css'
import { CashierPopup } from './CashierPopup'
import { historyStatus } from './LogPaymentList'
import { setSettingsGeneral, toggleOrderBoardActive } from 'src/state/general'
import { setIsAuthorized, setSelectedLanguage } from 'src/state/general/actions'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import ClickOutsideListener from 'src/components/shared/ClickOutsideListener'
import { languages } from 'src/globals/enumerations'
import DigitalClock from '../../shared/DigitalClock'
import useIntegrationApi from '../../../hooks/useIntegrationApi'
import VenueSelector from '../../general/VenueSelector'

export const Header = ({
  venue,
  zone,
  standalone,
  popup,
  showPopup,
  history,
  showHistory,
  setIsIntegration,
  foldable,
  isHeader,
  setHeader,
  isDishboard
}) => {
  const apiAvailable = useSelector(getApiAvailability)
  const integrationStatus = useSelector(getIntegrationStatus)
  const payments = useSelector(getPayments)
  const status = { apiAvailable, venue: venue && venue.disabled }
  return useMemo(
    () => (
      <Content
        {...{
          venue,
          standalone,
          zone,
          popup,
          showPopup,
          history,
          showHistory,
          status,
          setIsIntegration,
          payments,
          integrationStatus,
          foldable,
          isHeader,
          setHeader,
          isDishboard
        }}
      />
    ),
    [venue, standalone, zone, popup, showPopup, history, showHistory, status, payments, integrationStatus, isHeader]
  )
}

const Content = ({
  venue,
  standalone,
  zone,
  popup,
  showPopup,
  history,
  showHistory,
  status,
  payments,
  setIsIntegration,
  integrationStatus,
  foldable,
  isHeader,
  setHeader,
  isDishboard
}) => {
  const dispatch = useDispatch()
  const [integrationError, setIntegrationError] = useState(false)
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false)
  const snackbar = useSelector(getSnackbar)
  const isOffline = useSelector(getIsOffline)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]

  const [
    {
      settings: { autointegration: autoIntegrationEnabled }
    }
  ] = useIntegrationApi()

  const handleClickOpen = () => {
    dispatch(setSettingsGeneral(2))
  }

  const handleMorePopupOpen = () => setIsMorePopupOpen(!isMorePopupOpen)

  const handleClose = value => {
    dispatch(setSettingsGeneral(0))
  }

  const errorStatus = status.venue || !status.apiAvailable || isOffline || snackbar
  return (
    <div>
      <div
        style={{ paddingTop: standalone ? '40px' : 'inherit' }}
        className={[s.statusbar, errorStatus ? s.statusError : ''].join(' ')}
      >
        <div className={s.statusRow}>
          {errorStatus ? <UnplugIcon /> : <PluginIcon />}
          {status.venue && <div className={s.mleft8}>{translations.appDisabled}</div>}

          {!isOffline && !status.apiAvailable && <div className={s.mleft8}>{translations.apiNotAvailable}</div>}
          {isOffline && <div className={s.mleft8}>{translations.internetIsNotAvailable}</div>}
          {snackbar && <div className={s.mleft8}>{snackbar}</div>}

          {integrationStatus !== true
            ? ''
            : payments
                .filter(x => historyStatus(x) === 'started')
                .map(x => (
                  <div className={s.statusbarItemWrapper}>
                    <div className={s.statusbarItem}>{`${x.tableNum ? `${x.tableNum} /` : ''} ${x.takeoutNum}`}</div>
                    <IntegrationProgress
                      className={s.animrotate}
                      width={16}
                      height={16}
                      style={{ marginLeft: '2px' }}
                    />
                  </div>
                ))}
        </div>
        {foldable ? <FoldIcon onClick={() => setHeader(!isHeader)} /> : ''}
      </div>

      {foldable && !isHeader ? (
        ''
      ) : (
        <div className={s.header} style={{ backgroundColor: window.mode && window.mode === 'develop' && '#ffce00' }}>
          {window.busytable.brandSlug === 'hlbn' ? <HlebIcon fill={'#888888'} className={s.iconHeader} /> : ''}
          <DigitalClock className={window.busytable.brandSlug === 'hlbn' ? s.clockPositioned : s.clock} />
          <div style={{ color: '#868686' }}>
            <div>{venue && venue.id ? t(venue.name, selectedLanguage) : strings.defaultVenue}</div>
            <div>{zone ? zone : translations.cashierStation}</div>
          </div>
          <div className={s.headerSection}>
            {/* <div
            onClick={() => showHistory(!history)}
            className={[s.iconHeader2, history ? s.activeHistory : ''].join(' ')}
          >
            <HistoryIcon style={{ fill: history ? '#ffffff' : '#868686' }} />
          </div> */}
            <div
              onClick={async () => {
                try {
                  const p = await integrationFetch('/getMenuItems', false, 'GET', null, errorObj => {
                    let errorStr = `${translations.errorOfApi}\n ${JSON.stringify(errorObj)}`
                    dispatch(addError(errorStr))
                    dispatch(setIntegration(false))
                    postEventV3(
                      EVENT_ACTIONS.INTEGRATION_ERROR,
                      EVENT_CATEGORY.INTEGRATION,
                      `venue:${venue && venue.id ? venue.id : 'defaultVenue'} ${errorStr}`
                    )
                  })
                  // console.log(p.data.newKey.filter(x => x.t))
                  if (p && p.data && p.data.status === 'ok') {
                    // alert('success, sending to server')
                    dispatch(setIntegration(false))
                    // localStorage.setItem('__INTEGRATION_active', true)
                    if (setIsIntegration) {
                      setIsIntegration(true)
                    }
                    universalFetch({
                      endpoint: `/${venue.slug}/integration/fillOrders`,
                      cb: data => {
                        console.log(data)
                        const dataWrapper = data.data
                        console.log(dataWrapper)
                        if (data.errors) {
                          dispatch(setIntegration(false))
                          dispatch(addError(JSON.stringify(data.errors)))
                          return
                        }
                        if (dataWrapper) {
                          console.log(dataWrapper.integration)
                          if (dataWrapper.integration === true) {
                            console.log('dispatch(setIntegration(true))')
                            dispatch(setIntegration(true))
                            // localStorage.setItem('__INTEGRATION_active', true)
                          } else {
                            // localStorage.setItem('__INTEGRATION_active', false)
                            setIntegrationError({ status: 'error', unmapped: dataWrapper.unmapped })
                          }
                        }
                      },
                      method: 'POST',
                      data: {
                        menu_items: p.data.menu_items,
                        settings: {
                          ip: localStorage.getItem('_integration_ip'),
                          login: localStorage.getItem('_integration_login'),
                          password: localStorage.getItem('_integration_password'),
                          url: localStorage.getItem('_integration_url'),
                          pin: localStorage.getItem('_integration_pin')
                        }
                      }
                    })
                  } else {
                    let errorStr = translations.cashRegisterNotResponding
                    postEventV3(
                      EVENT_ACTIONS.INTEGRATION_ERROR,
                      EVENT_CATEGORY.INTEGRATION,
                      `venue:${venue && venue.id ? venue.id : 'defaultVenue'} ${errorStr}`
                    )
                    dispatch(addError(translations.cashRegisterNotResponding))
                    if (setIsIntegration) {
                      setIsIntegration(false)
                    }
                  }
                } catch (err) {
                  console.log(err)
                  let errorStr = `${translations.errorCashRegisterNotResponding}\n ${JSON.stringify(err)}`
                  postEventV3(
                    EVENT_ACTIONS.INTEGRATION_ERROR,
                    EVENT_CATEGORY.INTEGRATION,
                    `venue:${venue && venue.id ? venue.id : 'defaultVenue'} ${errorStr}`
                  )
                  dispatch(addError(errorStr))
                  if (setIsIntegration) {
                    setIsIntegration(false)
                  }
                }
                // dispatch(setIntegration(true))
              }}
              className={s.iconHeader2}
            >
              {integrationStatus !== true ? (
                <IntegrationErrorIcon />
              ) : (
                <IntegrationIcon fill={autoIntegrationEnabled ? '#006400' : '#868686'} />
              )}
            </div>
            <div
              onClick={() => {
                window.location.reload()
              }}
              className={s.iconHeader2}
            >
              <AutorenewIcon style={{ fill: '#868686' }} />
            </div>
            {!!integrationError && (
              <CashierPopup onCloseButton={setIntegrationError(false)}>
                <div>
                  {translations.errorIntegration}{' '}
                  {!!integrationError.unmapped ? translations.integrationTable : translations.serverProblem}
                  {integrationError.unmapped && (
                    <li>
                      {integrationError.unmapped &&
                        integrationError.unmapped
                          .filter((x, i) => i < 4)
                          .map(x => (
                            <ul className={s.inError}>
                              {translations.name}
                              {t(x.name, selectedLanguage)}, {translations.price}
                              {x.price}, {translations.code}
                              {x.code}, {translations.pliCode}
                              {x.pli_code}
                            </ul>
                          ))}
                      {integrationError.unmapped && integrationError.unmapped.length > 3 && (
                        <ul>
                          {translations.andMore}
                          {integrationError.unmapped.length - 3} {translations.inconsistence}
                        </ul>
                      )}
                    </li>
                  )}
                </div>
              </CashierPopup>
            )}
            {/* <div className={[s.iconHeader2, popup ? s.activeHistory : ''].join(' ')}>
            <HelpIcon
              style={{ fill: popup ? '#ffffff' : '#868686' }}
              onClick={() => {
                showPopup(!popup)
              }}
            />
          </div> */}
            {/* <div className={[s.iconHeader2, s.iconExit].join(' ')}>
            <ExitIcon
              style={{ fill: '#868686' }}
              onClick={() => {
                localStorage.removeItem('currentVenue')
                window.location.pathname = '/'
                window.location.reload()
              }}
            />
          </div> */}
            <ClickOutsideListener onClickOutside={() => setIsMorePopupOpen(false)}>
              <div className={s.moreIconWrapper}>
                <div onClick={handleMorePopupOpen} className={[s.iconHeader2, s.mobheader].join(' ')}>
                  <MoreIcon />
                </div>
                <MorePopup
                  isOpen={isMorePopupOpen}
                  handleMorePopupOpen={handleMorePopupOpen}
                  isHelpOpen={popup}
                  handleHelpOpen={showPopup}
                  history={history}
                  showHistory={showHistory}
                  isDishboard={isDishboard}
                />
              </div>
            </ClickOutsideListener>
          </div>
        </div>
      )}
    </div>
  )
}

const MorePopup = ({ isOpen, handleMorePopupOpen, isHelpOpen, handleHelpOpen, history, showHistory, isDishboard }) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.general)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]

  const toggleOrdersBoard = () => {
    dispatch(toggleOrderBoardActive(true))
    if (localStorage.getItem('viewType') === 'ordersBoard') {
      localStorage.setItem('viewType', 'dishBoard')
    } else {
      localStorage.setItem('viewType', 'ordersBoard')
    }
  }

  const onLogOut = async e => {
    e.stopPropagation()
    try {
      await fetchRemoveAuth()
      dispatch(setIsAuthorized(false))
      handleMorePopupOpen()
    } catch (e) {
      console.log(`error while logging out : ${e.message}`)
    }
  }

  const onLanguageChange = e => {
    dispatch(setSelectedLanguage(e.target.value))
    const appStorage = window.localStorage

    appStorage.setItem('lang', e.target.value)
  }
  return (
    <div className={`${s.morePopupWrapper} ${isOpen && s.morePopupWrapperActive}`}>
      <List className={s.menuList}>
        <ListItem
          button
          onClick={e => {
            e.stopPropagation()
            handleHelpOpen(!isHelpOpen)
            handleMorePopupOpen()
          }}
        >
          <ListItemAvatar>
            <Avatar className={s.avatar}>
              <HelpIcon style={{ fill: '#868686' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${translations.help}`} />
        </ListItem>
        <ListItem
          button
          onClick={e => {
            e.stopPropagation()
            // todo Настройки
            dispatch(setSettingsGeneral(1))
            handleMorePopupOpen()
          }}
        >
          <ListItemAvatar>
            <Avatar className={s.avatar}>
              <SettingsIcon style={{ fill: '#868686' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${translations.settings}`} />
        </ListItem>
        <ListItem button onClick={onLogOut}>
          <ListItemAvatar>
            <Avatar className={s.avatar}>
              <ExitIcon style={{ fill: '#868686' }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${user?.firstname} ${user?.lastname}`} />
        </ListItem>
        {isDishboard && (
          <ListItem button onClick={toggleOrdersBoard}>
            <ListItemText style={{ textAlign: 'center' }} primary={translations.ordersBoard} />
          </ListItem>
        )}
      </List>
      <VenueSelector
        className={s.venueSelector}
        onSelect={() => {
          window.location.reload()
        }}
      />
      <div className={s.languageSelectWrapper}>
        <select value={selectedLanguage} onChange={onLanguageChange} onClick={e => e.stopPropagation()}>
          {Object.keys(languages).map(lang => (
            <option key={lang} value={lang}>{`${lang}`}</option>
          ))}
        </select>
      </div>
    </div>
  )
}
