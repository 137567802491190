export const SET_PING = 'SET_PING'
export const SET_PONG = 'SET_PONG'
export const SET_ERRORS = 'SET_ERRORS'
export const ADD_ERROR = 'ADD_ERROR'
export const REMOVE_ERROR = 'REMOVE_ERROR'
export const SET_GOOGLE = 'SET_GOOGLE'
export const SET_INTEGRATION = 'SET_INTEGRATION'
export const SET_OFFLINE = 'SET_OFFLINE'
export const SET_API_AVAILABLE = 'SET_API_AVAILABLE'
export const SET_NEED_RESTART = 'SET_NEED_RESTART'
