import { GET_APP_PREFERENCES_STARTED, GET_APP_PREFERENCES_ERROR, GET_APP_PREFERENCES_SUCCESS } from './types'

export const getAppPreferencesStarted = () => ({
  type: GET_APP_PREFERENCES_STARTED
})

/**
 *
 * @param {object} error
 */
export const getAppPreferencesError = error => ({
  type: GET_APP_PREFERENCES_ERROR,
  error
})

/**
 *
 * @param {object} appPreferences
 */
export const getAppPreferencesSuccess = appPreferences => ({
  type: GET_APP_PREFERENCES_SUCCESS,
  appPreferences
})
