import { propEq, find } from 'ramda'

export const getVenues = store => store.venues.list
export const getVenuesLoading = store => store.venues.loading

// use with hooks: useSelector(getVenueById)(venueId)
export const getVenueById = store => id => find(propEq('slug', `${id}`), store.venues.list)

export const getVenueBySlug = store => slug => {
  return store.venues.list.find(x => String(x.slug) === String(slug)) || {}
}
