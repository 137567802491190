const protocol = document.location.protocol
export const settings = {
  production: {
    API_URL: `${protocol}//api.${window.busytable.domain}`,
    API_URL3: `${protocol}//api.${window.busytable.domain}/v3`,
    CHANNEL_URL: `${protocol}//api.${window.busytable.domain}`,
    ORIGIN_URL: `${protocol}//${window.busytable.domain}`
  },
  staging: {
    API_URL: `${protocol}//api.${window.busytable.domain}`,
    API_URL3: `${protocol}//api.${window.busytable.domain}/v3`,
    CHANNEL_URL: `${protocol}//api.${window.busytable.domain}`,
    ORIGIN_URL: `${protocol}//${window.busytable.domain}`
  },
  develop: {
    API_URL: `${protocol}//devapi.${window.busytable.domain}`,
    API_URL3: `${protocol}//devapi.${window.busytable.domain}/v3`,
    CHANNEL_URL: `${protocol}//devapi.${window.busytable.domain}`,
    ORIGIN_URL: `${protocol}//dev.${window.busytable.domain}`
  },
  localdev: {
    API_URL: 'http://localhost:5000',
    API_URL3: 'http://localhost:5000/v3',
    CHANNEL_URL: 'http://localhost:5001',
    ORIGIN_URL: 'http://localhost:3000'
  },
  remotedev: {
    API_URL: 'http://192.168.0.103:3002',
    API_URL3: 'http://192.168.0.103:3002/v3',
    CHANNEL_URL: 'http://192.168.0.103:3002',
    ORIGIN_URL: 'http://192.168.0.103:3002'
  }
}

//window.mode
// export const config = process.env.REACT_APP_DEV ? dev : prod

export const config = settings[window.mode]
