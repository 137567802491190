import { findId } from 'src/utils'
import { createSelector } from 'reselect'

const getId = (state, id) => id

export const getTimers = store => store.timers
// export const getPaymentById = store => id => findId(id, store.payments)
// export const getPaymentOrderById = store => id => findId(id, store.payments.map(x => x.orders).flat())
// export const getPaymentViableOrders = store => (id, cats) => findId(id, store.payments).orders.filter(x => cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
// export const getPaymentUnViableOrders = store => (id, cats) => findId(id, store.payments).orders.filter(x => !cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
// export const getDishboardPayments = store => store.payments.filter(x => x.status && x.status.confirmed && x.timedTakeout).sort((a, b) => new Date(a.timedTakeout) - new Date(b.timedTakeout))

export const getTimerByIdMemo = createSelector([getTimers, getId], (timers, id) => findId(id, timers))
export const getTimerById = store => id => findId(id, store.timers)
