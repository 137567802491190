import React, { useState } from 'react'
import { fetchSettings } from 'src/api'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, FormControlLabel, TextField } from 'src/components/shared/ExMaterial'
import { setSettingsGeneral } from 'src/state/general'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import useIntegrationApi from 'src/hooks/useIntegrationApi'
import Label from './Label'
import s from './Cashier.module.css'

export const CashierSettings = () => {
  const dispatch = useDispatch()
  const [
    {
      settings: { ip, login, password, url, pin, autointegration }
    },
    { setIp, setLogin, setPassword, setPin, setUrl, setAutointegration }
  ] = useIntegrationApi()

  const [isError, setError] = useState(null)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]

  const handleChangeAutoint = () => {
    setAutointegration(prevState => !prevState)
  }
  // const showActiveTab = catId => (activeTabs.filter(e => e.id === catId).length > 0 ? s.activeTab : '')
  return (
    <div className={s.popup} onClick={() => dispatch(setSettingsGeneral(false))}>
      <div
        className={s.popupContent}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <form className={s.form} noValidate>
          <TextField
            variant='outlined'
            fullWidth
            id='ip'
            label='ip'
            name='ip'
            value={ip}
            onChange={e => setIp(e.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            name='ytwwt'
            label='log'
            type='text'
            id='qwerty'
            value={login}
            onChange={e => setLogin(e.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            name='ytwwt2'
            label='pas'
            type='qwerty3'
            id='qwerty2'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            name='url'
            label='url'
            id='url'
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
          <TextField
            variant='outlined'
            fullWidth
            name='pin'
            label='pin'
            id='pin'
            value={pin}
            onChange={e => setPin(e.target.value)}
          />
          <FormControlLabel
            label={`${translations.autoIntegration}`}
            control={<Checkbox color='primary' checked={autointegration} onChange={handleChangeAutoint} />}
          />
          <div className={s.price}>
            <div>{translations.verificationRequest}</div>
            <a href={`https://${ip}${url}/test`}>{`https://${ip}${url}/test`}</a>
          </div>
          <div>
            <a href={`https://${ip}/server.crt`} target='_blank' rel='noreferrer'>
              {translations.installCertificate}
            </a>
          </div>
          <Button
            onClick={async () => {
              const isErrorResponse = function (e) {
                return e && e.stack && e.message
              }
              try {
                setError('inprogress')
                const res = await fetchSettings(dispatch)
                if (isErrorResponse(res)) {
                  setError(true)
                } else {
                  setError(res === true ? res : false)
                }
              } catch (e) {
                console.log(setError)
                setError(true)
              }
            }}
            fullWidth
            variant='contained'
            color='primary'
            className={s.submit}
          >
            {translations.saveAndCheck}
          </Button>
          {isError === false && <Label color='success'>{translations.work}</Label>}
          {isError === true && <Label color='error'>{translations.notWork}</Label>}
          {isError === 'inprogress' && <Label color='secondary'>{translations.requestInProgress}</Label>}
        </form>
      </div>
    </div>
  )
}
