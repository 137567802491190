export const getCategories = store => store.categories

export const getCategoriesInMenu = store => menuId => {
  return getCategories(store).filter(x => x.menuId === menuId) || []
}

export const getTopCategoriesInMenu = store => menuId =>
  getCategoriesInMenu(store)(menuId).filter(x => x.parentId == null)

export const getTopCategoriesWithNestedDishes = store => menuId => {
  const topCategories = getTopCategoriesInMenu(store)(menuId)

  return topCategories.map(topCategory => {
    const nestedDishIds = store.categories
      .filter(childCategory => childCategory.parentId === topCategory.id)
      .flatMap(x => x.dishIds)

    const allDishIds = [...nestedDishIds, ...topCategory.dishIds]

    return { ...topCategory, dishIds: allDishIds }
  })
}

// /**
//  * returns virtual Misc subcategory if parent has dishes and real subcategories exists
//  */
// export const getSubcategories = store => ({ parentId }) => {
//   let virtualMisc = null
//   const subcategories = getCategories(store).filter(x => x.parentId == parentId)
//   const parent = getCategories(store).find(x => x.id == parentId)

//   if (parent && parent.dishIds.length && subcategories.length) {
//     virtualMisc = {
//       id: -1,
//       parentId: parent.id,
//       menuId: parent.menuId,
//       name: { ru: 'Прочее', en: 'Misc' },
//       icon: parent.icon,
//       dishIds: parent.dishIds
//     }
//   }

//   return virtualMisc ? [...subcategories, virtualMisc] : subcategories
// }
