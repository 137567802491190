import axios from 'axios'
import { config } from 'src/constants/url'

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

const api = axios.create()

const request = async (endpoint, options) => {
  const { method, data, params, path, isFrontOffice } = { isFrontOffice: true, ...options } || {}
  const frontOffice = isFrontOffice ? '/frontoffice' : ''

  const URL = `${config.API_URL3}${frontOffice}/${window.busytable.brandSlug}${endpoint}${path || ''}`

  const REQUEST_CONFIG = {
    ...authHeader
  }

  switch (method) {
    case 'POST':
      return api
        .post(
          URL,
          { ...data },
          {
            ...REQUEST_CONFIG
          }
        )
        .then(res => res)
    case 'PATCH':
      return api
        .patch(
          URL,
          { ...data },
          {
            ...REQUEST_CONFIG
          }
        )
        .then(res => res)
    case 'GET':
      return api
        .get(URL, {
          ...REQUEST_CONFIG,
          params: params || undefined
        })
        .then(res => res)
    case 'DELETE':
      return api
        .delete(URL, {
          ...REQUEST_CONFIG,
          data: data || undefined,
          params: params || undefined
        })
        .then(res => res)
    default:
      return api
        .get(URL, {
          ...REQUEST_CONFIG,
          params: params || undefined
        })
        .then(res => res)
  }
}

export default request
