import React, { useCallback, useEffect } from 'react'
import s from './Stats.module.scss'
import { postDailyStats, getOfflineData } from '../../../api'
import { Container, Paper, Box, TextField, Button } from 'src/components/shared/ExMaterial'
import { useSelector } from 'react-redux'
import { getGeneralStats, getGeneralVenue } from 'src/state/general'
import orders from 'src/state/orders'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import TipsStats from 'src/components/shared/TipsStats'

const dates = [
  new Date(Date.now() - 1000 * 3600 * 48),
  new Date(Date.now() - 1000 * 3600 * 24),
  new Date(Date.now()) // , new Date(Date.now() + 1000 * 3600 * 24).toISOString().slice(0, 10)
]

const StatsContent = ({ ...props }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const stats = useSelector(getGeneralStats)
  const venueSlug = useSelector(getGeneralVenue)
  const [cash, setCash] = React.useState('')
  const [total, setTotal] = React.useState('')
  const [period, setPeriod] = React.useState(new Date(dates[0]).toISOString().slice(0, 10))
  const [oldData, setOldData] = React.useState(null)

  const fetchData = useCallback(async () => {
    const d1 = await getOfflineData(dates[0].toISOString().slice(0, 10), venueSlug)
    const d2 = await getOfflineData(dates[1].toISOString().slice(0, 10), venueSlug)
    const d3 = await getOfflineData(dates[2].toISOString().slice(0, 10), venueSlug)
    let data1 = { cashier: 0, totalOffline: 0 }
    let data2 = { cashier: 0, totalOffline: 0 }
    let data3 = { cashier: 0, totalOffline: 0 }
    if (d1 && d1.data) {
      data1 = d1.data
    }
    if (d2 && d2.data) {
      data2 = d2.data
    }
    if (d3 && d3.data) {
      data3 = d3.data
    }
    setOldData([data1, data2, data3])
  }, [venueSlug])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (stats === [] || !stats.stats) {
    return 'Loading...'
  }

  // console.log(stats)
  const handleClick = async () => {
    await postDailyStats(
      (parseFloat(cash) * 100).toFixed(0),
      (parseFloat(total) * 100).toFixed(0),
      new Date(period).toISOString().slice(0, 10),
      venueSlug,
      venueSlug
    )
    setCash('')
    setTotal('')
    fetchData()
  }

  return (
    <Container>
      <TipsStats />

      <Paper style={{ marginTop: '24px' }} className={s.root}>
        <div className={s.row}>
          <div>{translations.orders}:</div>
          <div>{stats.stats.ordersCount}</div>
        </div>
        <div className={s.row}>
          <div>{translations.revenue}:</div>
          <div>{stats.stats.ordersSum}</div>
        </div>
        <div className={s.row}>
          <div>{translations.visitors}:</div>
          <div>{stats.stats.visitors}</div>
        </div>
        <div className={s.row}>
          <div>{translations.newVisitors}:</div>
          <div>{stats.stats.newVisitors}</div>
        </div>
      </Paper>
      {!oldData ? (
        translations.loading
      ) : (
        <Paper style={{ marginTop: '24px' }} className={s.root}>
          <div>{translations.closingShift}</div>
          <Box mt={2}>
            {dates[0].toLocaleDateString('ru-RU', {
              weekday: 'long',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
            :
            <div>
              {translations.revenueVenue}:{' '}
              {oldData && oldData[0] && oldData[0].cashier ? (parseInt(oldData[0].cashier) / 100).toFixed(2) : 0} грн
            </div>
            <div>
              {translations.totalRevenue}:{' '}
              {oldData && oldData[0] && oldData[0].totalOffline
                ? (parseInt(oldData[0].totalOffline) / 100).toFixed(2)
                : 0}{' '}
              грн
            </div>
          </Box>
          <Box mt={2}>
            {dates[1].toLocaleDateString('ru-RU', {
              weekday: 'long',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
            :
            <div>
              {translations.revenueVenue}:{' '}
              {oldData && oldData[1] && oldData[1].cashier ? (parseInt(oldData[1].cashier) / 100).toFixed(2) : 0} грн
            </div>
            <div>
              {translations.totalRevenue}:{' '}
              {oldData && oldData[1] && oldData[1].totalOffline
                ? (parseInt(oldData[1].totalOffline) / 100).toFixed(2)
                : 0}{' '}
              грн
            </div>
          </Box>

          <Box mt={2}>
            {dates[2].toLocaleDateString('ru-RU', {
              weekday: 'long',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
            :
            <div>
              {translations.revenueVenue}:{' '}
              {oldData && oldData[2] && oldData[2].cashier ? (parseInt(oldData[2].cashier) / 100).toFixed(2) : 0} грн
            </div>
            <div>
              {translations.totalRevenue}:{' '}
              {oldData && oldData[2] && oldData[2].totalOffline
                ? (parseInt(oldData[2].totalOffline) / 100).toFixed(2)
                : 0}{' '}
              грн
            </div>
          </Box>

          <Box mt={2}>
            <TextField
              variant='outlined'
              fullWidth
              value={period}
              select
              SelectProps={{
                native: true
              }}
              onChange={e => setPeriod(new Date(e.target.value).toISOString().slice(0, 10))}
            >
              <option value={new Date(dates[0]).toISOString().slice(0, 10)}>
                {new Date(dates[0]).toLocaleDateString('uk-UA', {
                  weekday: 'long',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </option>
              <option value={new Date(dates[1]).toISOString().slice(0, 10)}>
                {new Date(dates[1]).toLocaleDateString('uk-UA', {
                  weekday: 'long',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </option>
              <option value={new Date(dates[2]).toISOString().slice(0, 10)}>
                {new Date(dates[2]).toLocaleDateString('uk-UA', {
                  weekday: 'long',
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </option>
            </TextField>
          </Box>
          <Box mt={2}>
            <TextField
              variant='outlined'
              fullWidth
              label='Выручка ресторана, грн'
              value={cash}
              onChange={e => setCash(e.target.value)}
            />{' '}
          </Box>
          <Box mt={2}>
            <TextField
              variant='outlined'
              fullWidth
              label='Общая выручка, грн'
              value={total}
              onChange={e => setTotal(e.target.value)}
            />{' '}
          </Box>
          <Box mt={2}>
            <Button variant='contained' color='primary' onClick={handleClick}>
              {translations.send}
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  )
}

export default StatsContent
