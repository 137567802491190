import { FETCH_PAID_ORDERS, CONFIRM_ORDER, ADD_ORDER } from './types'

export const fetchPaidOrders = orders => ({
  type: FETCH_PAID_ORDERS,
  payload: { orders }
})

export const addOrder = order => ({
  type: ADD_ORDER,
  payload: { order }
})

export const confirmOrder = order => ({
  type: CONFIRM_ORDER,
  payload: { order }
})
