import classnames from 'classnames'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as HlebIcon } from 'src/assets/ico-hleb.svg'
import { ReactComponent as ReloadIcon } from 'src/assets/ico-load.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/ico-settings.svg'
import { ReactComponent as TableIcon } from 'src/assets/ico-table-big.svg'
import { ReactComponent as TogoIcon } from 'src/assets/ico-togo2.svg'
import { ReactComponent as ExitToAppIcon } from 'src/assets/ico-exittoapp.svg'
import { getCategories } from 'src/state/categories/selectors'
import { getOptionsets } from 'src/state/dish'
import { getDishesOriginal } from 'src/state/dishes/selectors'
import { getPayments } from 'src/state/payments'
import { findId, optionsToTextSimple, t } from 'src/utils'
import { LogPaymentList } from '../cashier/CashierView/LogPaymentList'
import load from 'src/components/shared/Load'
import s from './Runner.module.css'
import { useInterval } from 'src/hooks/useInterval'
import { CssBaseline } from '../shared/ExMaterial'
import { ReactComponent as CheckMark } from 'src/assets/ic_check_box_24px.svg'
import { getGeneralVenue } from 'src/state/general'
import { getVenues } from 'src/state/venues'
import { useTimerPing } from 'src/hooks/useTimerPing'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsDishboard from 'src/translations/dishboard'
import { useCashierSSE } from '../../hooks/useCashierSSE'
import useOrderApi from '../../hooks/useOrderApi'

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9]

export const IconType = type => {
  switch (type) {
    case 'beverages':
      return '☕'
    case 'breakfast':
      return '🍳'
    case 'burger':
      return '🍔'
    case 'salad':
      return '🥒'
    case 'bakery':
      return '🥐'
    case 'bread':
      return '🍞'
    case 'alcohol':
      return '🍷'
    default:
      return '☕'
  }
}

const Runner = () => {
  useCashierSSE()
  useTimerPing()
  const currentVenue = useSelector(getGeneralVenue)
  const venueSlug = currentVenue
  const payments = useSelector(getPayments)
  const venues = useSelector(getVenues)
  const cats = useSelector(getCategories)
  // console.log(cats)

  // const ordersCooking = payments
  //   .filter(x => x.status && x.status.cashierConfirmed)
  //   .reduce((a, c) => [...a, ...c.orders], [])
  // const paymentIdsCooking = payments.filter(x => x.status && x.status.cashierConfirmed).map(x => x.id)
  // const [venue, setVenue] = useState(initialVenue ? JSON.parse(initialVenue) : { id: 0, name: 'Хлебный' })
  // console.log(venues, venueSlug)
  const venue = currentVenue
  const selectedLanguage = useSelector(getSelectedLanguage)
  const [zone, setZone] = useState(localStorage.getItem('zone') || 'Кухня')
  const initialTheme = localStorage.getItem('theme')
  const [theme, setTheme] = useState(initialTheme ? JSON.parse(initialTheme) : { name: 'default', value: '96e9da' })
  const [settings, showSettings] = useState(false)
  const initialTabs = localStorage.getItem('activeTabs')
    ? localStorage.getItem('activeTabs') === 'Всё🚗'
      ? localStorage.getItem('activeTabs')
      : JSON.parse(localStorage.getItem('activeTabs'))
    : []
  const catsNew = cats.filter(
    c => c.menuId === (venues.find(e => e.slug === venueSlug) ? venues.find(e => e.slug === venueSlug).menuId : null)
  )
  const [activeTabs, setActiveTabs] = useState(
    'Всё🚗' === initialTabs ? cats : catsNew.filter(x => initialTabs.find(y => y.id === x.id))
  )
  // const [dishes, setDishes] = useState([])
  // const [cats, setCats] = useState([])
  // const [optionsets, setOptionsets] = useState([])

  useEffect(() => {
    localStorage.setItem('zone', zone)
  }, [zone])

  useEffect(() => {
    localStorage.setItem('theme', JSON.stringify(theme))
  }, [theme])

  useEffect(() => {
    localStorage.setItem('activeTabs', JSON.stringify(activeTabs))
  }, [activeTabs])

  useEffect(() => {
    document.title = 'Runner'
  }, [])

  // console.log(cats, dishes, orders, payments, optionsets)

  // console.log(paymentIdsCooking)

  return (
    <div className={s.body}>
      <div
        className={[s.header, s[theme.name]].join(' ')}
        style={{ backgroundColor: window.mode && window.mode === 'develop' ? '#ffce00' : 'inherit' }}
      >
        <HlebIcon fill={'#888888'} />
        <div>
          <div style={{ opacity: '.5' }}>{venue && venue.id ? t(venue.name, selectedLanguage) : 'ХЛЕБНЫЙ'}</div>
          <div>СТАНЦИЯ РАННЕРА / {zone}</div>
        </div>
        <SettingsIcon fill={'#000000'} onClick={_ => showSettings(!settings)} />
        <ExitToAppIcon
          onClick={() => {
            // window.location.pathname = '/'
            // onClose()
            localStorage.removeItem('currentVenue')
            window.location.pathname = '/'
            window.location.reload()
          }}
        />
        <ReloadIcon
          fill={'#ffffff'}
          onClick={() => {
            window.location.reload()
          }}
          className={s.iconHeader2}
          width='32'
        />
      </div>
      {!settings ? (
        <PaymentList
          {...{
            theme,
            activeTabs,
            venueSlug
          }}
        />
      ) : (
        <RunnerSettings
          venue={venue}
          zone={zone}
          theme={theme}
          setTheme={setTheme}
          setZone={setZone}
          setActiveTabs={setActiveTabs}
          activeTabs={activeTabs}
        />
      )}
    </div>
  )
}

const themes = [
  {
    value: 'F8E71C',
    name: 'fall'
  },
  {
    value: 'FCB900',
    name: 'fallnig'
  },
  {
    value: '7BDCB5',
    name: 'forest'
  },
  {
    value: '00D084',
    name: 'deepfor'
  },
  {
    value: '8ED1FC',
    name: 'sky'
  },
  {
    value: '0693E3',
    name: 'water'
  },
  {
    value: 'ABB8C3',
    name: 'snow'
  },
  {
    value: 'EB144C',
    name: 'pinky'
  },
  {
    value: 'F78DA7',
    name: 'fluff'
  },
  {
    value: '9900EF',
    name: 'magician'
  }
]

export const RunnerSettings = ({
  activeTabs,
  setActiveTabs,
  venue,
  zone,
  setVenue,
  setZone,
  setTheme,
  theme,
  check1,
  setCheck1,
  check2,
  setCheck2,
  check3,
  setCheck3,
  check4,
  setCheck4,
  check5,
  setCheck5,
  check6,
  setCheck6,
  onClose = () => {}
}) => {
  const allCats = useSelector(getCategories)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsDishboard[selectedLanguage]
  const cats = allCats.filter(c => c.menuId === venue.menuId)
  // const showActiveTab = catId => (activeTabs.filter(e => e.id === catId).length > 0 ? s.activeTab : '')
  const showActiveTabNew = catId =>
    activeTabs === 'Всё🚗' || localStorage.getItem('activeTabs') === 'Всё🚗'
      ? s.showContent
      : activeTabs.filter(e => e.id === catId).length > 0
      ? s.showContent
      : s.hideContent
  // console.log(activeTabs)
  return (
    <>
      <div className={s.popup}>
        <div className={s.pHeader}>{translations.settings}</div>
        <div className={[s.pSubheader, s.mtop32].join(' ')}>{translations.showCategory}</div>
        <div className={[s.mtop16, s.tArea, s.allowScrollChild].join(' ')}>
          <div
            onClick={() => {
              const isAdd = activeTabs === 'Всё🚗' || localStorage.getItem('activeTabs') === 'Всё🚗'
              if (!isAdd) {
                localStorage.setItem('activeTabs', 'Всё🚗')
                setActiveTabs(cats)
              } else {
                localStorage.setItem('activeTabs', JSON.stringify([]))
                setActiveTabs([])
              }
            }}
            className={[s.tAreaRow, s.mleft32].join(' ')}
          >
            <div
              className={[
                s.tAreaCheck,
                activeTabs === 'Всё🚗' || localStorage.getItem('activeTabs') === 'Всё🚗' ? s.showContent : s.hideContent
              ].join(' ')}
            >
              <CheckMark />
            </div>
            <div className={s.tAreaText}>Все</div>
          </div>
          {cats
            .filter(x => !x.parentId)
            .map(cat => (
              <div key={cat.id}>
                <div
                  onClick={() => {
                    const isAdd =
                      activeTabs === 'Всё🚗' || localStorage.getItem('activeTabs') === 'Всё🚗'
                        ? []
                        : activeTabs.filter(e => e.id === cat.id)
                    console.log(isAdd.length)
                    if (activeTabs !== 'Всё🚗' && localStorage.getItem('activeTabs') !== 'Всё🚗') {
                      if (isAdd.length > 0) {
                        localStorage.setItem('activeTabs', JSON.stringify(activeTabs.filter(e => e.id !== cat.id)))
                        setActiveTabs(activeTabs.filter(e => e.id !== cat.id))
                      } else {
                        localStorage.setItem('activeTabs', JSON.stringify([...activeTabs, cat]))
                        setActiveTabs([...activeTabs, cat])
                      }
                    } else {
                      localStorage.setItem('activeTabs', JSON.stringify([cat]))
                      setActiveTabs([cat])
                    }
                  }}
                  className={[s.tAreaRow, s.mleft48].join(' ')}
                >
                  <div className={[s.tAreaCheck, showActiveTabNew(cat.id)].join(' ')}>
                    <CheckMark />
                  </div>
                  <div className={s.tAreaText}>{t(cat.name, selectedLanguage)}</div>
                </div>
                {cats
                  .filter(x => x.parentId === cat.id)
                  .map(subcat => (
                    <div
                      onClick={() => {
                        const isAdd =
                          activeTabs === 'Всё🚗' || localStorage.getItem('activeTabs') === 'Всё🚗'
                            ? []
                            : activeTabs.filter(e => e.id === subcat.id)
                        console.log(isAdd.length)
                        if (activeTabs !== 'Всё🚗' && localStorage.getItem('activeTabs') !== 'Всё🚗') {
                          if (isAdd.length > 0) {
                            localStorage.setItem(
                              'activeTabs',
                              JSON.stringify(activeTabs.filter(e => e.id !== subcat.id))
                            )
                            setActiveTabs(activeTabs.filter(e => e.id !== subcat.id))
                          } else {
                            localStorage.setItem('activeTabs', JSON.stringify([...activeTabs, subcat]))
                            setActiveTabs([...activeTabs, subcat])
                          }
                        } else {
                          localStorage.setItem('activeTabs', JSON.stringify([cat]))
                          setActiveTabs([cat])
                        }
                      }}
                      className={[s.tAreaRow, s.mleft80].join(' ')}
                      key={subcat.id}
                    >
                      <div className={[s.tAreaCheck, showActiveTabNew(subcat.id)].join(' ')}>
                        <CheckMark />
                      </div>
                      <div className={s.tAreaText}>{t(subcat.name, selectedLanguage)}</div>
                    </div>
                  ))}
              </div>
            ))}
        </div>
        <CssBaseline />
        <div className={[s.flex, s.jcsb, s.mtop32].join(' ')}>
          <div>
            <div className={[s.pSubheader].join(' ')}>{translations.typeOfOrders}</div>
            <div
              onClick={() => {
                setCheck4(!check4)
              }}
              className={[s.tAreaRow, s.mtop16].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check4 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.here}</div>
            </div>
            <div
              onClick={() => {
                setCheck5(!check5)
              }}
              className={[s.tAreaRow].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check5 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.takeAway}</div>
            </div>
            <div
              onClick={() => {
                setCheck6(!check6)
              }}
              className={[s.tAreaRow].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check6 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.delivery}</div>
            </div>
          </div>
          <div>
            <div className={[s.pSubheader].join(' ')}>{translations.additionally}</div>
            <div
              onClick={() => {
                setCheck1(!check1)
              }}
              className={[s.tAreaRow, s.mtop16].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check1 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.onlyAtTime}</div>
            </div>
            <div
              onClick={() => {
                setCheck2(!check2)
              }}
              className={[s.tAreaRow].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check2 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.waitForOrderComplete}</div>
            </div>
            <div
              onClick={() => {
                setCheck3(!check3)
              }}
              className={[s.tAreaRow].join(' ')}
            >
              <div className={[s.tAreaCheck, !!check3 ? s.showContent : s.hideContent].join(' ')}>
                <CheckMark />
              </div>
              <div className={s.tAreaText}>{translations.justCookNow}</div>
            </div>
          </div>
        </div>
        {/* <FormControlLabel
        label={'только timedTakeout'}
        control={<Checkbox color='primary' checked={check1} onChange={e => setCheck1(e.target.checked)} />}
      />
      <FormControlLabel
        label={'ожидать завершения заказа'}
        control={<Checkbox color='primary' checked={check2} onChange={e => setCheck2(e.target.checked)} />}
      />
      <FormControlLabel
        label={'готовить сразу'}
        control={<Checkbox color='primary' checked={check3} onChange={e => setCheck3(e.target.checked)} />}
      /> */}
        {/* <Grid style={{ padding: '16px 32px' }} container spacing={3}>
        {cats.map(cat => (
          <Grid key={cat.id} item xs={3}>
            <div
              onClick={() => {
                const isAdd = activeTabs.filter(e => e.id === cat.id)
                console.log(isAdd.length)
                if (isAdd.length > 0) {
                  setActiveTabs(activeTabs.filter(e => e.id !== cat.id))
                } else {
                  setActiveTabs([...activeTabs, cat])
                }
              }}
              className={s.tab}
              key={cat.id}
            >
              <div className={[s.iconBox, showActiveTab(cat.id)].join(' ')}>{IconType(cat.icon)}</div>
              <div className={s.iconTitle}>{t(cat.name)}</div>
            </div>
          </Grid>
        ))}
      </Grid> */}
        <div className={[s.pSubheader, s.mtop32].join(' ')}>{translations.nameStation}</div>
        <input className={[s.tAreaInput, s.mtop16].join(' ')} value={zone} onChange={e => setZone(e.target.value)} />
        <div className={[s.pSubheader, s.mtop32].join(' ')}>{translations.colorStation}</div>
        {/* <div>
        <form className={s.container} noValidate autoComplete='off'>
          <div>
            <TextField
              id='standard-basic'
              className={s.textField}
              label='Введите зону'
              margin='normal'
              value={zone}
              onChange={_ => {
                setZone(_.target.value)
              }}
            />
          </div>
        </form>
      </div> */}
        {/* <div className={[s.picker, theme ? s[theme.name] : s[themes[0].name]].join(' ')}>
        {themes.map(e => (
          <div onClick={() => setTheme(e)} key={t(e.name)} className={[s.themeSquare, s[e.name]].join(' ')}></div>
        ))}
      </div> */}
        <div className={[s.picker, s.mtop16].join(' ')}>
          {themes.map(e => (
            <div
              onClick={() => {
                setTheme(e)
                console.log(e, theme)
              }}
              key={t(e.name, selectedLanguage)}
              className={[s.tAreaRadioWrap, s.clickable].join(' ')}
            >
              <div className={[s.themeSquare2, s[e.name]].join(' ')}></div>
              <div className={[s.tAreaRadio, s.mtop8].join(' ')}>
                <div className={theme && theme.name === e.name ? s.tAreaRadioSel : ''}></div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={s.tEnd}>
        <div onClick={() => onClose()} className={[s.pHeader, s.clickable, s.clickArea].join(' ')}>
          {translations.close}
        </div>
      </div>
    </>
  )
}

const PaymentList = ({ activeTabs, theme, venueSlug }) => {
  const dishes = useSelector(getDishesOriginal)
  const optionsets = useSelector(getOptionsets)
  const payments = useSelector(getPayments)
  const [history, showHistory] = useState(false)
  const paymentsCooking = payments.filter(x => x.status && x.status.cashierConfirmed && !x.status.runnerConfirm)

  // console.log(dishes)
  if (!dishes || dishes.length === 0) {
    return 'loading'
  }
  // paymentsCooking.sort((b, a) => {
  //   const paymentOrdersA = a.orders
  //   // const paymentOrdersA = orders.filter(x => x.status === 'ready')
  //   const paymentOrdersB = b.orders

  //   const isActiveA = activeTabs.filter(
  //     e => paymentOrdersA.filter(order => findId(order.dishId, dishes).catId === e.id).length > 0
  //   )
  //   const isActiveB = activeTabs.filter(
  //     e => paymentOrdersB.filter(order => findId(order.dishId, dishes).catId === e.id).length > 0
  //   )

  //   const isActiveA1 = activeTabs.filter(
  //     e =>
  //       paymentOrdersA.filter(order => findId(order.dishId, dishes).catId === e.id && order.status === 'cooking')
  //         .length > 0
  //   )
  //   const isActiveB1 = activeTabs.filter(
  //     e =>
  //       paymentOrdersB.filter(order => findId(order.dishId, dishes).catId === e.id && order.status === 'cooking')
  //         .length > 0
  //   )

  //   const diff = isActiveA.length - isActiveB.length
  //   const diff1 = isActiveA1.length - isActiveB1.length
  //   return diff || diff1 || b.takeoutNum - a.takeoutNum
  // })

  const paymentList = paymentsCooking
    .filter(p => p.status.cashierConfirmed)
    .map(payment => {
      return (
        <Payment {...{ payment, theme, venueSlug }} key={`payemnt-${payment.id}`}>
          <OrderList
            activeTabs={activeTabs}
            paymentId={payment.id}
            {...{ orders: payment.orders, dishes, optionsets, venueSlug }}
          />
        </Payment>
      )
    })

  // console.log(paymentList)
  if (paymentList.length === 0) return <div className={s.no_payment}> Новых заказов нет</div>
  return (
    <div style={{ display: 'flex' }}>
      <div className={s.wrapper}>{paymentList}</div>
      <div className={s.logWrapper}>
        <LogPaymentList {...{ venueSlug, payments: payments.filter(x => x.status && x.status.runnerConfirm) }} />
      </div>
    </div>
  )
}

const Payment = ({ payment, children, theme, venueSlug }) => {
  return (
    <div className={s.paymentContainer}>
      <div className={s.payment__title__wrap}>
        {/* <div className={s.takeout}>{takeout}</div> */}

        {tableRender(
          payment.takeout || payment.tableNum === 0 ? false : payment.tableNum,
          payment.takeoutNum,
          payment.takeout || payment.tableNum === 0 ? payment.takeoutNum : false,
          theme
        )}
        {/* <Timer paidAt={payment.paidAt} /> */}
        {/* <p className={s.payment_id}>
          Номер заказа: <strong>{payment.id}</strong>
        </p> */}
      </div>

      {children}
      {/* {payment.comment ? (
        <div className={s.comment}>
          Комментарий: <span>"{payment.comment}"</span>
        </div>
      ) : (
        <div className={s.comment_no}> Комментарий отсутствует</div>
      )} */}
    </div>
  )
}

const OrderList = ({ orders, dishes, paymentId, showReject, setShowReject, activeTabs, optionsets, venueSlug }) => {
  const paymentOrders = orders.sort((a, b) => a.paymentId - b.paymentId)
  // .sort((b, a) => {
  //   const aDish = dishes.filter(e => a.dishId === e.id)[0]
  //   const bDish = dishes.filter(e => b.dishId === e.id)[0]
  //   const isA = activeTabs.filter(e => e.id === aDish.catId)
  //   const isB = activeTabs.filter(e => e.id === bDish.catId)
  //   return isA.length - isB.length || (a.status === 'cooking') - (b.status === 'cooking')
  // })
  // console.log(paymentOrders)

  // console.log(activeTabs)

  const orderList = paymentOrders.map(order => (
    <Order activeTabs={activeTabs} order={order} {...{ dishes, optionsets, venueSlug }} key={`order-${order.id}`} />
  ))

  if (paymentOrders.length === 0) return null

  return load(dishes, <div className={s.orderContainer}>{orderList}</div>)
}

const Order = ({ order, dishes, activeTabs, optionsets, venueSlug }) => {
  const [showReject, setShowReject] = useState(false)
  const selectedLanguage = useSelector(getSelectedLanguage)

  const [, { sendOrderStatus }] = useOrderApi(order.id ? [order.id] : order.ids)

  const dish = findId(order.dishId, dishes)

  const optionsTitle = dish ? (
    isEmpty(order.selectedOptions) || order.selectedOptions === undefined ? (
      ''
    ) : (
      <span>{optionsToTextSimple(order.selectedOptions, optionsets)}</span>
    )
  ) : (
    ''
  )

  let orderStatus = order.status

  // console.log(orderStatus)

  const activeStyle = {
    [s.active]: order.status === 'ready' || orderStatus.runnerConfirm
  }

  const handleStart = () => {
    if (order.status === 'ready') return undefined

    setShowReject(true)
  }

  const handleDone = useCallback(async () => {
    setShowReject(false)

    // markOrder(order.id, venueSlug)
    // apiAddStatus({ orderId: order.id }, 'runnerConfirm')
    // apiAddStatus({ paymentId: order.paymentId }, 'runnerStarted')
    sendOrderStatus({ status: 'ready' })
    // apiAddStatus(order.paymentId, 'runnerStarted')
  }, [sendOrderStatus, setShowReject])

  const handleReject = e => {
    e.stopPropagation()
    setShowReject(false)
  }

  // const isActive = activeTabs.filter(e => e.id === dish.catId)
  const isActive = [1]

  const dishName = dish.label && dish.label.length > 0 ? dish.label : dish.name

  return (
    <div
      className={classnames(s.orderBox, activeStyle, isActive.length > 0 ? '' : s.disabled)}
      onClick={e => {
        console.log('click', isActive.length)
        if (isActive.length > 0) {
          handleStart()
        }
      }}
    >
      {showReject ? <OrderTimer handleDone={handleDone} handleReject={handleReject} dishName={dishName} /> : null}
      <div className={s.optionsTitle}>{optionsTitle}</div>
      <div className={s.orderTitle}>{t(dishName, selectedLanguage)}</div>
      {order.takeout ? (
        <TogoIcon
          className={s.takeoutIcon}
          width='93'
          height='54'
          fill={order.status === 'ready' ? '#69D460' : '#E6E6E6'}
        />
      ) : (
        ''
      )}
      {/* <div className={s.price}>{order.price}.</div> */}
    </div>
  )
}

const OrderTimer = ({ handleDone, handleReject, dishName }) => {
  const [seconds, setSeconds] = useState(5)
  const selectedLanguage = useSelector(getSelectedLanguage)
  useInterval(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1)
    }
  }, 1000)

  useEffect(() => {
    if (seconds === 0) {
      handleDone()
    }
  }, [seconds])

  return (
    <div className={s.reject} onClick={e => handleReject(e)}>
      <div>{t(dishName, selectedLanguage)} готов?</div> <div> {seconds}с</div>
    </div>
  )
}

export default Runner

const tableRender = (e, w, togo, theme) => (
  <div className={[s.table].join(' ')}>
    <div style={{ color: '#' + theme.value }} className={s.tableFont2}>
      {w}
    </div>
    <div className={[s.hr2, s[theme.name]].join(' ')}></div>
    {e ? (
      <div className={[s.flex, s.jcBetween, s.mtop8].join(' ')}>
        <TableIcon width='30' height='25' fill={'#' + theme.value} />
        <div style={{ color: '#' + theme.value }} className={s.tableFont}>
          {e}
        </div>
      </div>
    ) : (
      ''
    )}
    {togo ? (
      <div className={[s.flex, s.jcBetween, s.mtop8].join(' ')}>
        <TogoIcon width='30' height='25' fill={'#' + theme.value} />
      </div>
    ) : (
      ''
    )}
  </div>
)
