import { ADD_TIMER, REMOVE_TIMER } from './types'

/* State Shape
{

}
*/

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_TIMER: {
      const { id } = action.payload
      return [...state, { id, time: Date.now() + 1000 * 3 }]
    }
    case REMOVE_TIMER: {
      const { id } = action.payload
      return state.filter(e => e.id !== id)
    }

    default:
      return state
  }
}
