import { combineReducers } from 'redux'
import categories from './categories'
import contect from './contect'
import general from './general'
import menu from './dish'
import dishes from './dishes'
import modifiers from './modifiers'
import msgs from './msgs'
import orders from './orders'
import payments from './payments'
import roles from './roles'
import snackbar from './snackbar'
import status from './status'
import stoplist from './stoplist'
import stoplistonly from './stoplistonly'
import venues from './venues'
import timers from './timers'
import appPreferencesReducer from './app-preferences/reducer'
export default combineReducers({
  categories,
  contect,
  general,
  dishes,
  menu,
  modifiers,
  messages: msgs,
  orders,
  payments,
  roles,
  snackbar,
  status,
  stoplist,
  stoplistonly,
  venues,
  timers,
  appPreferences: appPreferencesReducer
})
