import { findId } from 'src/utils'
import { createSelector } from 'reselect'

const getId = (state, id) => id
const getCats = (state, cats) => cats

export const getPayments = store => store.payments.list
export const getPaymentsLoading = store => store.payments.loading
export const getPaymentById = id => store => findId(id, store.payments.list)
// export const getPaymentOrderById = store => id => findId(id, store.payments.map(x => x.orders).flat())
// export const getPaymentViableOrders = store => (id, cats) => findId(id, store.payments).orders.filter(x => cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
// export const getPaymentUnViableOrders = store => (id, cats) => findId(id, store.payments).orders.filter(x => !cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
// export const getDishboardPayments = store => store.payments.filter(x => x.status && x.status.confirmed && x.timedTakeout).sort((a, b) => new Date(a.timedTakeout) - new Date(b.timedTakeout))

// export const getPaymentById = createSelector([getPayments], (payments) => id => findId(id, payments))
export const getPaymentOrderById = createSelector([getPayments, getId], (payments, id) =>
  findId(id, payments.map(x => x.orders).flat())
)
export const getPaymentViableOrders = createSelector([getPayments, getId, getCats], (payments, id, cats) => {
  console.log(payments, id, cats)
  return findId(id, payments).orders.filter(x => cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
})
export const getPaymentUnViableOrders = createSelector([getPayments, getId, getCats], (payments, id, cats) =>
  findId(id, payments).orders.filter(x => !cats.some(cat => cat.dishIds.some(y => y === x.dishId)))
)
export const getDishboardPayments = createSelector([getPayments], payments =>
  payments.filter(x => x.status && x.status.confirmed)
)
