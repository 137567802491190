import { propEq, find, isEmpty } from 'ramda'
import { languages } from './globals/enumerations'

if (!Array.prototype.flat)
  Array.prototype.flat = function (depth = 1) {
    depth = isNaN(depth) ? 0 : Math.floor(depth)
    if (depth < 1) return this.slice()
    return [].concat(...(depth < 2 ? this : this.map(v => (Array.isArray(v) ? v.flat(depth - 1) : v))))
  } // polyfill

export const objFromArray = (arr, key = 'id') =>
  arr.reduce((accumulator, current) => {
    accumulator[current[key]] = current
    return accumulator
  }, {})

/**
 * receives etitites object and optional comparison function
 * returns sorted array of ids
 *
 * @param {Object} entities
 */
export const idsFromEntities = ({ entities, compareFunc = null }) => {
  try {
    const defaultCompareFunc = (a, b) => (a.id > b.id ? 1 : -1)
    const arr = Object.values(entities)
    return arr.sort(compareFunc || defaultCompareFunc).map(x => x.id)
  } catch (e) {
    alert(e)
  }
}

export const findId = (id, arr) => find(propEq('id', id), arr)
// export const findId = (id, arr) => arr.find(x => x.id === id)

export const combineSameDishes = orders => {
  // TODO: теряется порядок в котором пользователь заказывал блюда
  // Нужен глобальный рефакторинг чтобы не считать стоимость опций каждый раз, а получать их уже в объекте order

  const result = orders.reduce((acc, v) => {
    const totalPrice = v.dishPrice + parseInt(v.optionsPrice)
    const options = v.selectedOptions || v.options || []

    const optionsHash = isEmpty(options)
      ? ''
      : options.reduce((acc, v) => {
          acc += v.id.toString() + v.value.toString()
          return acc
        }, '')

    const idHash = `${v.dishId}OPTIONS${optionsHash}${v.takeout ? 1 : 0}`

    acc[idHash] = acc[idHash]
      ? {
          ...acc[idHash],
          count: acc[idHash].count + 1,
          takeout: v.takeout,
          dishPrice: totalPrice,
          catId: v.catId,
          ids: [...acc[idHash].ids, v.id],
          subcatId: v.subcatId,
          modifier: v.modifier
        }
      : {
          dishId: v.dishId,
          dishPrice: totalPrice,
          ids: [v.id],
          count: 1,
          selectedOptions: options,
          takeout: v.takeout,
          catId: v.catId,
          status: v.status,
          subcatId: v.subcatId,
          modifier: v.modifier
        }
    return acc
  }, {})

  var arr = []

  for (var key in result) {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      arr.push(result[key])
      // use val
    }
  }

  return arr
}

export const calcOptionsCost = (options, optionsets) => {
  if (!optionsets || optionsets.length === 0) {
    return 0
  }
  const total = options.reduce((acc, { id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    // console.log(optionset, id, value, optionsets)
    const option = optionset === undefined ? { price: 0 } : optionset.options.find(({ id }) => id === parseInt(value))

    acc += parseInt(option.price)
    return acc
  }, 0)

  return total
}

export const optionsToText = (options, optionsets) => {
  const result = options.map(({ id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    if (optionset === undefined) {
      return ''
    }
    const ingredient = optionset.options.find(({ id }) => id === parseInt(value))

    return `${t(optionset.name).toLowerCase()}: ${t(ingredient.name).toLowerCase()}`
  })

  return result.join(', ')
}

export const optionsToTextSimple = (options, optionsets) => {
  const result = options.map(({ id, value }) => {
    const tempId = id
    const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
    if (optionset === undefined) {
      return ''
    }
    const ingredient = optionset.options.find(({ id }) => id === parseInt(value))

    return `${t(ingredient.name).toLowerCase()}`
  })

  return result.join(', ')
}

export const getCookingTime = (dishes, oldOrders) => {
  let time = 0
  let max = 0
  if (Object.keys(dishes).length === 0) {
    return { max: 0, time: '' }
  }
  // if (oldOrders.length === 0) {
  //   return { max: 0, time: '' }
  // }
  // const findMinDish = orders => {
  //   const dis = orders.map(d => {
  //     return dishes.filter(dish => dish.id === d.dishId)[0].waitingTime
  //   })
  //   return dis.sort((b, a) => b - a)[0]
  // }
  const findMaxCategory = orders => {
    let cats = []
    orders.forEach(order => {
      const dish = dishes[order.dishId]

      if (!dish) {
        return
      }

      const orderCategories = cats.filter(category => dish && category.id === dish.catId)

      if (orderCategories.length === 0) {
        cats = [
          ...cats,
          {
            id: dish.catId,
            time: dish.waitingTime
          }
        ]
      } else {
        cats = cats.map(category => {
          if (category.id === dish.catId) {
            return {
              ...category,
              time: category.time + dish.waitingTime
            }
          } else {
            return category
          }
        })
      }
    })
    if (cats.length > 0) {
      return cats.sort((b, a) => a.time - b.time)[0].id
    } else {
      return []
    }
  }
  const findMaxTotalTime = (catId, orders) => {
    let dishArr = orders.map(order => dishes[order.dishId])
    if (dishArr) {
      dishArr = dishArr.filter(dish => dish && dish.catId === catId)
    } else {
      return 0
    }
    const res = dishArr
      .sort((b, a) => a.waitingTime - b.waitingTime)
      .map((dish, index) => {
        if (index === 0) {
          return dish.waitingTime
        } else {
          return dish.waitingTime ? dish.waitingTime / 3 : 0
        }
      })
    return res.reduce((total, curr) => total + curr, 0)
  }
  // let min = findMinDish(oldOrders)
  max = findMaxTotalTime(findMaxCategory(oldOrders), oldOrders)

  if (oldOrders.filter(order => order.takeout === false).length === 0) {
    time = `${Math.round(max)} мин.`
  } else {
    time = `${Math.round(max)} мин.`
  }
  return { max, time }
}

// export const optionsToTextWithPrice = (options, optionsets) => {
//   const result = options.map(({ id, value }) => {
//     const tempId = id
//     const optionset = optionsets.find(({ id }) => id === parseInt(tempId))
//     if (optionset === undefined) {
//       return ''
//     }
//     const ingredient = optionset.options.find(({ id }) => id === parseInt(value))

//     let addition = ''
//     if (ingredient.price > 0) {
//       addition = `: ${ingredient.price / 100}`
//     }
//     return ingredient.label.toLowerCase() + addition
//   })

//   return result.join(', ')
// }

/**
 * @todo временное решение
 * переписать после изменения формата optioins для фронтофиса
 */
export const optionsToTextWithPrice = (options, optionsets) => {
  const optionsArr = optionsets.map(x => x.options).flat()
  const optionById = objFromArray(optionsArr)

  const result = options.map(({ value: optionId }) => {
    const currentOption = optionById[optionId]
    if (!currentOption) return 'Ошибка! Опция не найдена'
    const { label, price } = currentOption
    const priceString = price > 0 ? `: ${price / 100}` : ''

    return `${label.toLowerCase()}${priceString}`
  })

  return result.join(', ')
}

/**
 * @todo this is just draft. Needs complete rewrite.
 * @param {string|object} text - data to translate
 * @returns {string} translated text
 * @function
 */
export const t = (text, lang) => {
  if (!text) return ''
  if (typeof text === 'string') return text
  if (text.hasOwnProperty([lang])) return text[lang]

  //if ('ru' in text) return text.ru
  //if ('en' in text) return text.en

  const keys = typeof text === 'object' ? Object.keys(text) : ''
  if (keys.length) return text[keys[0]]

  return ''
}

export const filterDuplicates = arr => {
  const result = arr.reduce((acc, v) => {
    return acc.includes(v) ? acc : [...acc, v]
  }, [])

  return result
}

export const getReadableDate = datetime => {
  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ]
  const d = new Date(datetime)
  const date = d.getDate() === new Date().getDate() ? '' : `${d.getDate()} ${months[d.getMonth()]}`
  const time = `${d.getHours()}:${d.getMinutes() < 10 ? '0' : ''}${d.getMinutes()}`
  return { date, time }
}

export const generateTokenWithPass = (user, pass) => {
  return base64([user, pass].join(':'))
}

export const base64 = stringToGenerate => {
  const ENCODING = 'base64'
  return new Buffer(stringToGenerate).toString(ENCODING)
}

export const daysOfWeek = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
}

export const getLanguageCode = currentLanguage => {
  const languagesCodes = {
    en: 'en-US',
    ru: 'ru-RU',
    uk: 'uk-UA'
  }
  return languagesCodes[currentLanguage] || 'en-US'
}

export const isVenueOpenDate = (date, workingHours) => {
  if (!workingHours) return true
  const d = new Date(date)
  const dayOfWeek = daysOfWeek[d.getDay()]
  const [openTime, closeTime] = workingHours[dayOfWeek]
  const open = new Date(date).setHours(...openTime.split(':'), 0, 0)
  const close = new Date(date).setHours(...closeTime.split(':'), 0, 0)
  return open < d.getTime() && d.getTime() <= close
}

export const timeSegment = ({ range, start, end, workingHours }) => {
  return range.filter(x => x >= start && x < end && isVenueOpenDate(x, workingHours))
}

export const getOpenTime = (date, workingHours) => {
  const providedDate = new Date(date)
  const dayOfWeek = daysOfWeek[providedDate.getDay()]
  const [openTime] = workingHours[dayOfWeek]
  const [openHours, openMinutes] = openTime.split(':')
  return new Date(date).setHours(openHours, openMinutes, 0, 0)
}

export const datesRange = (workingHours, start, step = 60, days = 2) => {
  const openDate = getOpenTime(start, workingHours)
  const limit = openDate + days * 24 * 3600 * 1000
  const stepMs = step * 60 * 1000
  const datesRangeRec = (dates, prev) => {
    if (prev >= limit) return dates
    const next = prev + stepMs
    if (prev >= start && next >= start && isVenueOpenDate(next, workingHours)) {
      dates = [...dates, prev]
    }
    return datesRangeRec(dates, next)
  }
  return datesRangeRec([], openDate)
}

export const isSameDay = (paidAt, duedate) => {
  if (!duedate) return true

  const paidAtTemp = new Date(paidAt)
  const duedateTemp = new Date(duedate)

  return paidAtTemp.setHours(0, 0, 0, 0) == duedateTemp.setHours(0, 0, 0, 0)
}

export const findDefaultLanguage = () => {
  const appStorage = window.localStorage
  const alreadySelectedLang = appStorage.getItem('lang')
  if (alreadySelectedLang) {
    return alreadySelectedLang
  }
  const usersLanugage = navigator.language || navigator.userLanguage
  if (usersLanugage.includes('-')) {
    const [language] = usersLanugage.split('-')
    if (languages[language]) {
      return language
    }
  }
  /**
   * @todo // change in to the fallback lanugage if there is any
   */
  return 'uk'
}
