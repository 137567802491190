import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { fetchDisableVenue, fetchRemoveAuth } from 'src/api'
import { ReactComponent as MenuIcon } from 'src/assets/cio-stop.svg'
import { ReactComponent as AutorenewIcon } from 'src/assets/ico-autorenew.svg'
import { ReactComponent as ExitToAppIcon } from 'src/assets/ico-exittoapp.svg'
import { ReactComponent as BrandIcon } from 'src/assets/poi_ico_selected.svg'
import { Accordion, AppBar, Drawer, IconButton, ListItem, ListItemText, Switch } from 'src/components/shared/ExMaterial'
import { getTopCategoriesWithNestedDishes } from 'src/state/categories'
import { getGeneralVenue } from 'src/state/general'
import { getStoplist } from 'src/state/stoplist/selectors'
import { getVenueBySlug, getVenues } from 'src/state/venues'
import strings from 'src/strings'
import { t } from 'src/utils'
import Badge from './Badge'
import s from './Stoplist.module.scss'
import useAppPreferences from 'src/hooks/useAppPreferences'
import { setIsAuthorized, setUserProfile, setSelectedLanguage } from 'src/state/general/actions'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsManager from 'src/translations/manager'
import { languages } from 'src/globals/enumerations'
import VenueSelector from '../../general/VenueSelector'

const daysOfWeek = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
}

const StoplistHeader = ({ subToolbar, title, children, ...props }) => {
  const { preferences } = useAppPreferences()
  const possibleServicesInStoplist = preferences?.possibleServicesInStoplist
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsManager[selectedLanguage]
  const dispatch = useDispatch()
  const venueSlug = useSelector(getGeneralVenue)
  const { user } = useSelector(state => state.general)
  const [open, setOpen] = React.useState(false)
  const stoplist = useSelector(getStoplist)
  // const stoplist = { dishes: [], options: [] }
  const venues = useSelector(getVenues)
  const venue = useSelector(getVenueBySlug)(venueSlug)
  const topCategories = useSelector(getTopCategoriesWithNestedDishes)(venue.menuId)

  const params = useParams()

  const initialCheck =
    venues && venues.length > 0 && venues.find(v => v.slug === venueSlug + '')
      ? venues.find(v => v.slug === venueSlug + '')
      : { disabled: true }

  const [state, setState] = React.useState({
    checkedA: initialCheck.disabled
  })

  var allDishes = 0

  const handleChangeCheck = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  const handleDrawerToggle = () => {
    setOpen(!open)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setState({ ...state, checkedA: !initialCheck.disabled })
  }, [venues])

  if (!venues || venues.length === 0) {
    return null
  }

  const stoppedDishesInCategory = dishIds => {
    const stoppedDishes = dishIds.filter(x => stoplist.dishes.some(y => parseInt(y.dishId) === parseInt(x)))
    return stoppedDishes.length
  }

  const linkToCategory = id => {
    return `/cat/${id}`
  }

  const onLogOut = async () => {
    try {
      await fetchRemoveAuth()
      dispatch(setIsAuthorized(false))
      // localStorage.removeItem('currentVenue')
      // dispatch(setVenueGeneral(''))
      // window.location.pathname = '/'
      // window.location.reload()
    } catch (e) {
      console.log(e.message)
    }
  }

  const onLanguageChange = e => {
    dispatch(setSelectedLanguage(e.target.value))
    const appStorage = window.localStorage

    appStorage.setItem('lang', e.target.value)
  }

  return (
    <>
      <AppBar
        className={s['MuiAppBar-colorPrimary']}
        style={{
          zIndex: '4',
          position: 'fixed'
        }}
      >
        <div
          className={s.toolbar}
          style={{ backgroundColor: window.mode && window.mode === 'develop' ? '#ffce00' : '#ffffff' }}
        >
          <div style={{ flex: '1' }}>
            {state && state.checkedA === false ? (
              <div className={s.disabledVenue}>{translations.venueOff}</div>
            ) : window.busytable && window.busytable.brandSlug && window.busytable.brandSlug === 'hlbn' ? (
              <BrandIcon />
            ) : (
              ''
            )}
          </div>
          {title}
          <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              style={{ padding: '4px' }}
              className={clsx(s.menuButton)}
              onClick={() => {
                window.location.reload()
              }}
            >
              <AutorenewIcon />
            </IconButton>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerToggle}
              edge='start'
              style={{ padding: '4px', marginLeft: '4px' }}
              className={clsx(s.menuButton)}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
        {subToolbar}
      </AppBar>
      <Drawer
        wrapperClassName={s.drawerWrapper}
        className={s.drawer}
        anchor='right'
        open={open}
        onClose={handleDrawerClose}
      >
        <div className={s.drawerHeader}>
          {/* <div onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </div> */}
        </div>
        <div className={s.vr} />
        <div>
          <Accordion
            title={translations.dishes}
            active={Boolean(params.catId) || !possibleServicesInStoplist?.length}
            content={
              <div style={{ marginLeft: '16px' }}>
                <NavLink activeClassName={s.activeList} className={s.inactiveList} exact to={`/`}>
                  <ListItem className={clsx(s.title, s.downBoxShadow)} style={{ backgroundColor: 'inherit' }} button>
                    <div style={{ alignItems: 'center' }} className={s.flex}>
                      <ListItemText primary={translations.allDishes} />
                    </div>
                    <div className={s.flex}>
                      <Badge style={{ color: 'white' }} variant={'black'} value={allDishes} />
                    </div>
                  </ListItem>
                </NavLink>
                {topCategories.map(category => {
                  return (
                    <NavLink
                      key={category.id}
                      exact
                      activeClassName={s.activeList}
                      className={s.inactiveList}
                      to={`${linkToCategory(category.id)}`}
                    >
                      <ListItem
                        className={clsx(s.title, s.downBoxShadow)}
                        style={{ backgroundColor: 'inherit' }}
                        button
                      >
                        <div style={{ alignItems: 'center' }} className={s.flex}>
                          <ListItemText primary={t(category.name, selectedLanguage)} />
                        </div>
                        <div className={s.flex}>
                          <Badge
                            style={{ color: 'white' }}
                            variant={'black'}
                            value={stoppedDishesInCategory(category.dishIds)}
                          />
                        </div>
                      </ListItem>
                    </NavLink>
                  )
                })}
              </div>
            }
          />
          <NavLink activeClassName={s.activeList} className={s.inactiveList} to={`/stoplist`}>
            <ListItem className={clsx(s.title, s.downBoxShadow)} style={{ backgroundColor: 'inherit' }} button>
              <div style={{ alignItems: 'center' }} className={s.flex}>
                <ListItemText primary={translations.modifiers} />
              </div>
              <div className={s.flex}>
                <Badge style={{ color: 'white' }} variant={'black'} value={stoplist.options.length} />
              </div>
            </ListItem>
          </NavLink>
          {possibleServicesInStoplist ? (
            <Accordion
              title={translations.services}
              active={Boolean(params.services_type)}
              content={
                <div style={{ marginLeft: '16px' }}>
                  {possibleServicesInStoplist.map(service => (
                    <NavLink
                      exact
                      key={service}
                      activeClassName={s.activeList}
                      className={s.inactiveList}
                      to={`/services-stoplist/${service}`}
                    >
                      <ListItem
                        className={clsx(s.title, s.downBoxShadow)}
                        style={{ backgroundColor: 'inherit' }}
                        button
                      >
                        <div style={{ alignItems: 'center' }} className={s.flex}>
                          <ListItemText primary={translations[service]} />
                        </div>
                      </ListItem>
                    </NavLink>
                  ))}
                </div>
              }
            />
          ) : (
            <></>
          )}
          <NavLink activeClassName={s.activeList} className={s.inactiveList} to={`/stats`}>
            <ListItem className={clsx(s.title, s.downBoxShadow)} style={{ backgroundColor: 'inherit' }} button>
              <div style={{ alignItems: 'center' }} className={s.flex}>
                <ListItemText primary={translations.statistics} />
              </div>
            </ListItem>
          </NavLink>
          <NavLink activeClassName={s.activeList} className={s.inactiveList} to={`/moveOrder`}>
            <ListItem className={clsx(s.title, s.downBoxShadow)} style={{ backgroundColor: 'inherit' }} button>
              <div style={{ alignItems: 'center' }} className={s.flex}>
                <ListItemText primary={translations.findPayment} />
              </div>
            </ListItem>
          </NavLink>
        </div>
        <div className={s.venueInfo}>
          <div onClick={e => e.stopPropagation()}>
            <VenueSelector onSelect={() => window.location.reload()} className={s.venueSelector} />
          </div>
          {/* <div style={{ marginTop: '16px' }} className={s.vklDescr}>
            Вкл/Откл клиентского приложения
          </div> */}
          <div className={s.vklDescr2}>
            <div>{translations.onOffExplain}</div>
            <div
              style={{
                fontStyle: 'normal',
                color: '#000000',
                fontWeight: 'bold',
                marginTop: '4px'
              }}
            >
              {initialCheck.workingHours ? initialCheck.workingHours[daysOfWeek[new Date().getDay()]].join(' - ') : ''}
            </div>
          </div>
          <div className={s.venueFlex}>
            <div className={clsx(!state.checkedA ? s.otkl : '')}>{translations.off}</div>
            <Switch
              checked={state.checkedA}
              onChange={e => {
                handleChangeCheck('checkedA')(e)
                fetchDisableVenue(venueSlug)
              }}
              value='checkedA'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <div className={clsx(state.checkedA ? s.vkl : '')}>{translations.on}</div>
          </div>
        </div>
        {/* <div style={{ marginTop: '16px' }} className={s.venueFont}>
          {initialCheck.workingHours ? initialCheck.workingHours[daysOfWeek[new Date().getDay()]].join(' - ') : ''}
        </div> */}
        {/* <div className={s.vklDescr2}>
          <div>выключается и включается автоматически, по графику работы заведения</div>
          <div
            style={{
              fontStyle: 'normal',
              color: '#000000',
              fontWeight: 'bold'
            }}
          >
            {initialCheck.workingHours ? initialCheck.workingHours[daysOfWeek[new Date().getDay()]].join(' - ') : ''}
          </div>
        </div> */}
        <div className={s.languageSelectWrapper}>
          <select onChange={onLanguageChange} onClick={e => e.stopPropagation()}>
            {Object.keys(languages).map(lang => {
              return <option key={lang} selected={lang === selectedLanguage}>{`${lang}`}</option>
            })}
          </select>
        </div>

        <div onClick={onLogOut} className={s.logoutContainer} style={{ marginBottom: '16px' }}>
          {user?.firstname} {user?.lastname} <ExitToAppIcon width='40' height='40' />
        </div>
      </Drawer>
    </>
  )
}

export default StoplistHeader
