import React from 'react'
import styles from './Spinner.module.scss'

const Spinner = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.loading} />
    {children}
  </div>
)

export default Spinner
