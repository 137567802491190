import { GET_APP_PREFERENCES_STARTED, GET_APP_PREFERENCES_ERROR, GET_APP_PREFERENCES_SUCCESS } from './types'

const initialState = {
  isLoading: false,
  error: undefined,
  appPreferences: {
    withFavorites: false,
    withLogRocket: false,
    timePickerStep: 120,
    minOrderAmount: null,
    popularDishesIds: [],
    disableInVenueOrders: false,
    preorderOnFutureDaysNumber: 2
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APP_PREFERENCES_STARTED: {
      return {
        isLoading: true,
        error: undefined
      }
    }
    case GET_APP_PREFERENCES_ERROR: {
      return {
        isLoading: false,
        error: action.error
      }
    }
    case GET_APP_PREFERENCES_SUCCESS: {
      return {
        isLoading: false,
        appPreferences: action.appPreferences
      }
    }
    default: {
      return state
    }
  }
}
