import {
  FETCH_PAID_PAYMENTS,
  ADD_PAYMENT,
  CONFIRM_PAYMENT,
  MARK_FIRST_TIME,
  ACTIVATE_PAYMENT,
  DEACTIVATE_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  ADD_ORDER_MODIFIER,
  REMOVE_ORDER_MODIFIER
} from './types'

export const fetchPaidPayments = payments => ({
  type: FETCH_PAID_PAYMENTS,
  payload: { payments }
})

export const addPayment = payment => ({
  type: ADD_PAYMENT,
  payload: { payment }
})

export const addOrderModifier = (orderId, paymentId, modifier) => ({
  type: ADD_ORDER_MODIFIER,
  payload: { orderId, paymentId, modifier }
})

export const removeOrderModifier = (orderId, paymentId) => ({
  type: REMOVE_ORDER_MODIFIER,
  payload: { orderId, paymentId }
})

export const deletePayment = payment => ({
  type: DELETE_PAYMENT,
  payload: { payment }
})

export const updatePayment = payment => ({
  type: UPDATE_PAYMENT,
  payload: { payment }
})

export const activatePayment = payment => ({
  type: ACTIVATE_PAYMENT,
  payload: { payment }
})

export const deactivatePayment = payment => ({
  type: DEACTIVATE_PAYMENT,
  payload: { payment }
})

export const confirmPayment = payment => ({
  type: CONFIRM_PAYMENT,
  payload: { payment }
})

export const markFirstTime = user => ({
  type: MARK_FIRST_TIME,
  payload: { user }
})
