const translationsCashier = {
  ru: {
    noOrdersTitle: 'Новых заказов нет',
    entryHeaderStation: 'Станция ',
    entryHeaderCashier: 'Кассир',
    entryHeaderManager: 'Менеджер',
    entryHeaderDishBoard: 'Упаковщик',
    entryTabLogin: 'Вход',
    entryTabRegister: 'Регистрация',
    entrySelectionRestaurant: 'Выберите заведение',
    entryExplanationLogin: nameStation => `Для авторизации на станции ${nameStation} отсканируйте QR код`,
    entryExplanationRegister: nameStation => `Для регистрации на станции ${nameStation} отсканируйте QR код`,
    history: 'История',
    cashierStation: 'СТАНЦИЯ КАССИРА',
    appDisabled: 'Приложение отключено',
    apiNotAvailable: 'АПИ недоступен',
    internetIsNotAvailable: 'Интернет недоступен',
    errorOfApi: 'Произошла ошибка, АПИ-сервер не отвечает.',
    cashRegisterNotResponding: 'Кассовый аппарат не отвечает',
    errorCashRegisterNotResponding: 'Произошла ошибка, кассовый аппарат не отвечает.',
    errorIntegration: 'Ошибка интеграции:',
    integrationTable: 'Проблема с таблицей соответствия',
    serverProblem: 'проблема с сервером',
    name: 'Название:',
    price: 'Цена:',
    code: 'Код: ',
    pliCode: 'ПлиКод: ',
    andMore: 'и еще ',
    inconsistence: 'несоответствий',
    help: 'Помощь',
    settings: 'Настройки',
    orders: 'Заказы',
    yesterday: 'За вчера',
    today: 'За сегодня',
    search: 'Поиск',
    verificationRequest: 'Проверочный запрос будет выполнен на',
    autoIntegration: 'автоинтеграция',
    installCertificate: 'Установить сертификат',
    saveAndCheck: 'Сохранить и проверить',
    work: 'работает',
    notWork: 'не работает',
    requestInProgress: 'запрос выполняется',
    january: 'января',
    february: 'февраля',
    march: 'марта',
    april: 'апреля',
    may: 'мая',
    june: 'июня',
    july: 'июля',
    august: 'августа',
    september: 'сентября',
    october: 'октября',
    november: 'ноября',
    december: 'декабря',
    visitor: 'Посетитель: ',
    phone: ', телефон: ',
    preferConnectType: ', предпочитаемый тип связи: ',
    refund: 'Возврат средств',
    anonymousVisitor: 'Анонимный посетитель',
    deliveryService: 'Служба доставки',
    novaPoshta: 'Новая Почта',
    taxi: 'Такси',
    cancel: 'Отмена',
    finishPayment: 'Завершить платеж',
    preorderTo: 'ПРЕДЗАКАЗ: ',
    deliveryAddress: (address, phone, name) =>
      `ДОСТАВКА - адрес: ${address}, тел: ${phone}${name ? `, имя: ${name}` : ''}`,
    total: 'Итого:',
    guest: 'Гость',
    order: 'Заказ',
    errorOfIntegration: 'ОШИБКА ИНТЕГРАЦИИ',
    putAside: 'Отложить',
    manually: 'Вручную',
    close: 'Закрыть',
    paymentHasBeenMade: 'Проведено',
    ready: 'Готово',
    withoutName: 'Без имени',
    withoutPhone: 'Без телефона',
    todayDate: 'Сегодня',
    addToStopList: 'Добавить в Стоп-лист',
    todayComma: 'Сегодня, ',
    /*contactUs:'Связаться с нами',
    registOfDefaultersLine1:'Регистрация в базе неплательщиков ',
    registOfDefaultersLine2:'1. Со своего личного телефона навести стандартную Камеру на QR код. Откроется форма регистрации. ',
    registOfDefaultersLine3:'2. Заполнить и отправить открывшуюся по коду анкету. Указать тип неплательщика, имя, фамилию. Код для регистрации ',
    registOfDefaultersLine4:'3. Через меню',
    registOfDefaultersLine5:'написать в поддержку для подтверждения заявки, выбрав Telegram.',*/
    novaPoshtaDeliveryAddress: (address, dept, phone, name) =>
      `ДОСТАВКА Новой Почтой - адрес: ${address}, отделение НП: ${dept}, тел: ${phone}, ФИО: ${name}`,
    ordersBoard: 'доска заказов',
    isOver: 'Закончилось',
    addComment: 'Добавить комментарий',
    manualModalConfirmButton: 'подтверждаю',
    manualModalPostButton: 'ввести вручную',
    manualModalHeader: 'Подтверждение ввода',
    manualModalDescription:
      reason => `В следствие ${reason}, интеграция сейчас не активна. Требуется подтверждение кассира, что данные
          были введены в ручном режиме.`,
    manualModalReason: {
      unpaider: 'проведения неплательщика',
      integrationError: 'ошибки с интеграцией',
      integrationCancelled: 'отключения интеграции'
    }
  },
  uk: {
    noOrdersTitle: 'Немає нових замовлень',
    entryHeaderStation: 'Станція ',
    entryHeaderCashier: 'Касир',
    entryHeaderManager: 'Менеджер',
    entryHeaderDishBoard: 'Пакувальник',
    entryTabLogin: 'Вхід',
    entryTabRegister: 'Реєстрація',
    entrySelectionRestaurant: 'Оберіть заклад',
    entryExplanationLogin: nameStation => `Для авторизації на станції ${nameStation} отскануйте QR код`,
    entryExplanationRegister: nameStation => `Для реєстрації на станції ${nameStation} отскануйте QR код`,
    history: 'Історія',
    cashierStation: 'СТАНЦІЯ КАСИРА',
    appDisabled: 'Додаток вимкнено',
    apiNotAvailable: 'До АПІ немає доступу',
    internetIsNotAvailable: 'Немає доступу до інтернету',
    errorOfApi: 'Відбулася помилка, АПІ-сервер не відповідає.',
    cashRegisterNotResponding: 'Касовий апарат не відповідає',
    errorCashRegisterNotResponding: 'Сталася помилка, касовий апарат не відповідає.',
    errorIntegration: 'Помилка інтеграції:',
    integrationTable: 'Проблема із таблицею відповідності',
    serverProblem: 'проблема із сервером',
    name: 'Назва:',
    price: 'Ціна:',
    code: 'Код: ',
    pliCode: 'ПліКод: ',
    andMore: 'та ще ',
    inconsistence: 'невідповідностей',
    help: 'Допомога',
    settings: 'Налаштування',
    orders: 'Замовлення',
    yesterday: 'За вчора',
    today: 'За сьогодня',
    search: 'Пошук',
    verificationRequest: 'Перевірочний запит буде виконано на',
    autoIntegration: 'автоінтеграція',
    installCertificate: 'Встановити сертифікат',
    saveAndCheck: 'Зберегти та перевірити',
    work: 'працює',
    notWork: 'не працює',
    requestInProgress: 'запит виконується',
    january: 'січня',
    february: 'лютого',
    march: 'березня',
    april: 'квітня',
    may: 'травня',
    june: 'червня',
    july: 'липня',
    august: 'серпня',
    september: 'вересня',
    october: 'жовтня',
    november: 'листопада',
    december: 'грудня',
    visitor: 'Відвідувач: ',
    phone: ', телефон: ',
    preferConnectType: ", Бажаний тип зв'язку: ",
    refund: 'Повернення коштів',
    anonymousVisitor: 'Анонімний відвідувач',
    deliveryService: 'Служба доставки',
    novaPoshta: 'Нова Пошта',
    taxi: 'Таксі',
    cancel: 'Відміна',
    finishPayment: 'Завершити платіж',
    preorderTo: 'ПЕРЕДЗАМОВЛЕННЯ: ',
    deliveryAddress: (address, phone, name) =>
      `ДОСТАВКА - адреса: ${address}, тел: ${phone}${name ? `, ім'я: ${name}` : ''}`,
    total: 'Разом: ',
    guest: 'Гість',
    order: 'Замовлення',
    errorOfIntegration: 'ПОМИЛКА ІНТЕГРАЦІІ',
    putAside: 'Відкласти',
    manually: 'Вручну',
    close: 'Закрити',
    paymentHasBeenMade: 'Проведено',
    ready: 'Готово',
    withoutName: 'Без імені',
    withoutPhone: 'Без телефону',
    todayDate: 'Сьогодні',
    addToStopList: 'Додати до Стоп-листа',
    todayComma: 'Сьогодня, ',
    novaPoshtaDeliveryAddress: (address, dept, phone, name) =>
      `ДОСТАВКА Новою Поштой - адреса: ${address}, відділення НП: ${dept}, тел: ${phone}, ПІБ: ${name}`,
    ordersBoard: 'дошка замовленнь',
    isOver: 'Закінчилось',
    addComment: 'Додати коментар',
    manualModalConfirmButton: 'підтверджую',
    manualModalPostButton: 'ввести власноруч',
    manualModalHeader: 'Підтвердження введення',
    manualModalDescription: reason =>
      `В результаті ${reason}, інтеграція наразі не активна. Потрібне підтвердження кассира, про те що данні були введені в ручному режимі.`,
    manualModalReason: {
      unpaider: 'проведення неплатильщика',
      integrationError: 'помилки з інтеграцією',
      integrationCancelled: 'відключення інтеграції'
    }
  }
}

export default translationsCashier
