import { ADD_CATEGORIES } from './types'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CATEGORIES: {
      const { items } = action.payload
      return items
    }

    default:
      return state
  }
}
