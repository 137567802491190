import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGeneralVenue, handleIntegrationStatus } from '../state/general'
import { getIntegrationStatus, setPong } from '../state/status'
import { getApiAvailability } from '../state/status/selectors'
import useIntegrationApi from './useIntegrationApi'
import useSetupCashierChannel from './useSetupCashierChannel'
import { resendApi, universalFetch } from '../utils/api'
import { confirmOrder } from '../state/orders/actions'
import { addMsg } from '../state/msgs'
import { addPayment, confirmPayment, deletePayment, markFirstTime, updatePayment } from '../state/payments/actions'
import { disableVenue } from '../state/venues/actions'
import { selectedApplicationByDomain } from '../globals/enumerations'
import { addStoplistDishesSSE, addStoplistOptionSSE } from '../state/stoplist/actions'
import { addOptionsets } from '../state/dish'
import { addDishes } from '../state/dishes'
import { addCategories } from '../state/categories'
import { addModifiers } from '../state/modifiers'
import { fetchSettings, postEvent } from '../api'
import usePaymentApi from './usePaymentApi'
import { getVenuesThunk } from '../state/venues/thunkActions'

export const useCashierSSE = () => {
  const { isAuthorized } = useSelector(state => state.general)
  const isIntegrationActive = useSelector(getIntegrationStatus)
  const venue = useSelector(getGeneralVenue)
  const apiAvailable = useSelector(getApiAvailability)
  const selectedVenueFromLocalStorage = localStorage.getItem('currentVenue')
  const dispatch = useDispatch()

  const [
    {
      settings: { autointegration }
    },
    { setIp, setPin, setLogin, setPassword, setUrl, integrateOrder }
  ] = useIntegrationApi()
  const [, { sendPaymentStatus }] = usePaymentApi()
  const [eventSource, { start: runChannel }] = useSetupCashierChannel()

  useEffect(() => {
    // console.log('HERE', isAuthorized, selectedVenueFromLocalStorage, venue)
    if (venue && isAuthorized && selectedVenueFromLocalStorage) {
      runChannel()
    }
  }, [runChannel, venue])

  useEffect(() => {
    if (apiAvailable) {
      resendApi(venue, dispatch)
    }
  }, [venue, apiAvailable, dispatch])

  useEffect(() => {
    const eventSetSettings = async event => {
      const msg = JSON.parse(event.data)
      console.log('new settings: ', msg)
      if (msg) {
        const { ip, login, password, url, pin } = msg
        // const res = await fetchSettings(msg, dispatch)
        setIp(ip || '')
        setLogin(login || '')
        setPassword(password || '')
        setUrl(url || '')
        setPin(pin || '')
        // console.log('HERE')
        dispatch(handleIntegrationStatus(true))
      } else {
        dispatch(handleIntegrationStatus(false))
      }
    }

    const eventRpcSetSettings = async event => {
      const msg = JSON.parse(event.data)
      console.log('new settings rpc: ', msg)

      setPin(msg.pin)
      setIp(msg.ip)
      setLogin(msg.login)
      setPassword(msg.password)
      setUrl(msg.url)
      const res = await fetchSettings(dispatch)
      postEvent('CashierRPC', res)
    }

    const eventClientMsg = event => {
      const msg = JSON.parse(event.data)
      console.log('mew nessage: ', msg)
      dispatch(addMsg({ ...msg, dateSent: Date.now() }))
    }

    const eventDeletePayment = event => {
      const msg = JSON.parse(event.data)
      console.log('delete payment: ', msg)
      dispatch(deletePayment({ ...msg }))
    }

    const eventDisableVenue = event => {
      const msg = JSON.parse(event.data)
      console.log('is disable venue: ', msg)
      dispatch(disableVenue(msg))
    }

    const eventAddFirstTime = event => {
      const msg = JSON.parse(event.data)
      console.log('is first time: ', msg)
      dispatch(markFirstTime(msg))
    }
    const eventNewPayment = event => {
      const msg = JSON.parse(event.data)
      console.log('New payment: ', msg)
      postEvent('CashierPayment', msg)
      if (!msg.status.displayed && selectedApplicationByDomain === 'cashier') {
        sendPaymentStatus({ paymentId: msg.id, status: 'displayed' })
      }

      dispatch(addPayment(msg))

      if (autointegration && isIntegrationActive && selectedApplicationByDomain === 'cashier' && !msg.timedTakeout) {
        integrateOrder(msg)
      }
    }
    const eventUpdatePayment = event => {
      const msg = JSON.parse(event.data)
      console.log('Update New payment: ', msg)
      // postEvent('CashierPayment', msg)
      dispatch(updatePayment(msg))
    }
    const eventCashierConfirm = event => {
      const msg = JSON.parse(event.data)
      console.log('Confirm payment: ', msg)
      postEvent('CashierConfirm', msg)
      // apiAddStatus(msg.id, 'confirmed')
      dispatch(confirmPayment({ id: parseInt(msg.id) }))
    }
    const eventRunnerConfirm = event => {
      const msg = JSON.parse(event.data)
      console.log('Confirm order: ', msg)
      dispatch(confirmOrder({ id: parseInt(msg) }))
    }
    const eventIngridientStoplist = event => {
      console.log('New stoplist ingredient: ', event.data)
      const payload = JSON.parse(event.data)
      console.log(payload, venue)
      dispatch(addStoplistOptionSSE(payload))
    }
    const eventDishesStoplist = event => {
      console.log('New stoplist dish: ', event.data)
      const payload = JSON.parse(event.data)
      console.log(payload, venue)
      dispatch(addStoplistDishesSSE(payload))
    }

    const eventLastChange = event => {
      const fetchOptionsets = async () =>
        universalFetch(
          {
            endpoint: '/optionsets',
            notFrontOffice: true,
            cb: data => dispatch(addOptionsets(data))
          },
          dispatch
        )
      const fetchVenuesAsync = async () => dispatch(getVenuesThunk())
      const fetchDishes = async () => {
        universalFetch(
          {
            endpoint: `/${venue}/dishes`,
            cb: data => dispatch(addDishes(data))
          },
          dispatch
        )
      }
      const fetchCats = async () =>
        universalFetch(
          {
            endpoint: '/categories',
            cb: data => dispatch(addCategories(data))
          },
          dispatch
        )
      const fetchModifiers = async () =>
        universalFetch(
          {
            endpoint: `/${venue}/modifiers`,
            auth: true,
            cb: data => dispatch(addModifiers(data))
          },
          dispatch
        )

      const eventMap = {
        dishes: () => {
          fetchDishes()
          localStorage.setItem('lastchange_dishes', Date.now())
        },
        recipes: () => {
          fetchDishes()
          localStorage.setItem('lastchange_recipes', Date.now())
        },
        cats: () => {
          fetchCats()
          localStorage.setItem('lastchange_cats', Date.now())
        },
        categories: () => {
          fetchCats()
          localStorage.setItem('lastchange_categories', Date.now())
        },
        categoriesDishes: () => {
          fetchCats()
          fetchVenuesAsync()
          localStorage.setItem('lastchange_categoriesDishes', Date.now())
        },
        options: () => {
          fetchOptionsets()
          fetchModifiers()
          localStorage.setItem('lastchange_options', Date.now())
        },
        optionSets: () => {
          fetchOptionsets()
          fetchModifiers()
          localStorage.setItem('lastchange_optionSets', Date.now())
        },
        venues: () => {
          fetchVenuesAsync()
          localStorage.setItem('lastchange_venues', Date.now())
        }
      }
      const changesList = JSON.parse(event.data)
      Object.keys(changesList).forEach(endpoint => {
        const current = Number(localStorage.getItem('lastchange_' + endpoint))
        if (!localStorage.getItem('lastchange_' + endpoint)) {
          localStorage.setItem('lastchange_' + endpoint, Date.now())
        } else if (current && current < parseInt(changesList[endpoint]) && eventMap[endpoint]) {
          console.log('Refreshing outdated data:', endpoint)
          eventMap[endpoint](dispatch)
        }
      })
    }

    const eventPong = event => {
      // console.log('new pong')
      // const payload = JSON.parse(event.data)
      // console.log(payload, venue)
      dispatch(setPong(Date.now()))
    }

    const eventVersion = event => {
      const myVersion = Number(localStorage.getItem('version'))
      const newVersion = Number(event.data)
      if (newVersion > 1500000000000 && newVersion < 2690000000000 && newVersion < Date.now() + 1000000) {
        if (myVersion === 0) {
          localStorage.setItem('version', newVersion)
        } else if (newVersion > myVersion) {
          localStorage.setItem('version', newVersion)
          if (Number(localStorage.getItem('version')) === newVersion) {
            document.location.reload() // self-update
          } else console.log("can't store version")
        }
      } else console.log('Incorrect version: ' + newVersion)
    }

    if (eventSource) {
      eventSource.onopen = () => {
        eventSource.addEventListener('_rpc_setSettings', eventRpcSetSettings)
        eventSource.addEventListener('integrationSettings', eventSetSettings)
        eventSource.addEventListener('lastchange', eventLastChange)
        eventSource.addEventListener('clientMsg', eventClientMsg)
        eventSource.addEventListener('deletePayment', eventDeletePayment)
        eventSource.addEventListener('venueStateChange', eventDisableVenue)
        eventSource.addEventListener('firstTime', eventAddFirstTime)
        eventSource.addEventListener('newPaymentWithOrders', eventNewPayment)
        eventSource.addEventListener('updatePayment', eventUpdatePayment)
        eventSource.addEventListener('cashierConfirm', eventCashierConfirm)
        eventSource.addEventListener('runnerConfirm', eventRunnerConfirm)
        eventSource.addEventListener('ingredient_stoplist', eventIngridientStoplist)
        eventSource.addEventListener('dishes_stoplist', eventDishesStoplist)
        eventSource.addEventListener('version', eventVersion)
        eventSource.addEventListener('pong', eventPong)
      }
    }

    return () => {
      if (venue !== null && venue !== '' && eventSource) {
        eventSource.addEventListener('lastchange', eventLastChange)
        eventSource.removeEventListener('_rpc_setSettings', eventRpcSetSettings)
        eventSource.removeEventListener('integrationSettings', eventSetSettings)
        eventSource.removeEventListener('clientMsg', eventClientMsg)
        eventSource.removeEventListener('deletePayment', eventDeletePayment)
        eventSource.removeEventListener('venueStateChange', eventDisableVenue)
        eventSource.removeEventListener('firstTime', eventAddFirstTime)
        eventSource.removeEventListener('newPaymentWithOrders', eventNewPayment)
        eventSource.removeEventListener('updatePayment', eventUpdatePayment)
        eventSource.removeEventListener('cashierConfirm', eventCashierConfirm)
        eventSource.removeEventListener('runnerConfirm', eventRunnerConfirm)
        eventSource.removeEventListener('ingredient_stoplist', eventIngridientStoplist)
        eventSource.removeEventListener('dishes_stoplist', eventDishesStoplist)
        eventSource.removeEventListener('version', eventVersion)
        eventSource.removeEventListener('pong', eventPong)
        eventSource.close()
      }
    }
  }, [dispatch, venue, isAuthorized, eventSource])
}
