import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGeneralVenue } from 'src/state/general'
import { getPayments } from 'src/state/payments'
import axios from 'axios'
import { config } from 'src/constants/url'
import { showSnackbar } from 'src/state/snackbar/actions'
import { setNeedRestart, setPing } from 'src/state/status/actions'
import { fetchOrders } from 'src/utils/api'
import { setIsAuthorized } from '../state/general/actions'

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const useTimerPing = () => {
  let timer = undefined
  const dispatch = useDispatch()
  const { isAuthorized } = useSelector(state => state.general)

  const currentVenue = useSelector(getGeneralVenue)
  const payments = useSelector(getPayments)
  useEffect(() => {
    if (window.__error) {
      clearInterval(timer)
      return
    }
    if (isAuthorized && timer) {
      console.log('CLEARING')
      clearInterval(timer)
    }
    // console.log(timer)
    clearInterval(timer)

    timer = setInterval(onPing, 10 * 1000)
    return () => {
      clearInterval(timer)
    }
  }, [isAuthorized, payments])

  const onPing = () => {
    if (window.__error) {
      clearInterval(timer)
      return
    }
    axios(`${config.API_URL}/v3/frontoffice/${window.busytable.brandSlug}/${currentVenue}/ping`, authHeader)
      .then(e => {
        if (e.status !== 200) {
          throw new Error(e.status)
        } else {
          dispatch(showSnackbar(false))
          const res = e.data
          if (res.status === 'ok') {
            dispatch(setPing(Date.now()))
            const newId = res.lastPayment
            // console.log(newId)
            if (!newId) {
              //pass
            } else {
              if (payments.some(e => parseInt(e.id) === newId)) {
                // pass
              } else {
                fetchOrders(currentVenue, dispatch)
              }
            }
          } else {
            dispatch(setNeedRestart(true))
          }
        }
      })
      .catch(e => {
        if (e && e.response && [401, 403].includes(e.response.status)) {
          dispatch(showSnackbar('Нет доступа!'))
          dispatch(setIsAuthorized(false))
          return
        }
        dispatch(showSnackbar('Нет интернета!'))
        dispatch(setNeedRestart(true))
      })
  }
  // }, [currentVenue, login, venues, payments, window.__error]) // eslint-disable-line
}
