import {
  ADD_DISHES,
  TOGGLE_STOPLIST,
  TOGGLE_EDIT,
  ADD_SELECTED_OPTIONS,
  ADD_OPTIONSETS,
  ADD_INGREDIENTS,
  ADD_SELECTED_DISH
} from './types'

export const initialState = {
  dishes: [],
  edit: false,
  selectedOptions: false,
  selectedDish: false,
  optionsets: [],
  popular: [
    65,
    73,
    74,
    75,
    5,
    76,
    77,
    127,
    64,
    59,
    7,
    56,
    2,
    24,
    79,
    66,
    23,
    57,
    14,
    58,
    3,
    63,
    67,
    1,
    11,
    17,
    6,
    62,
    25,
    22,
    60,
    70,
    16,
    72,
    46,
    9
  ]
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_DISHES: {
      const { dishes } = action.payload
      return {
        ...state,
        dishes: dishes
      }
    }

    case ADD_OPTIONSETS: {
      const { optionsets } = action.payload
      return {
        ...state,
        optionsets: optionsets
      }
    }

    case ADD_INGREDIENTS: {
      const { ingredients } = action.payload
      return {
        ...state,
        ingredients: ingredients
      }
    }

    case ADD_SELECTED_OPTIONS: {
      const { selectedOptions } = action.payload
      return {
        ...state,
        selectedOptions: selectedOptions
      }
    }

    case ADD_SELECTED_DISH: {
      const { selectedDish } = action.payload
      return {
        ...state,
        selectedDish: selectedDish
      }
    }

    case TOGGLE_EDIT: {
      return {
        ...state,
        edit: action.payload
      }
    }

    case TOGGLE_STOPLIST: {
      const { id, stoplist } = action.payload
      let dishesUpdated

      if (stoplist === true) {
        // add to stoplist
        dishesUpdated = state.dishes.map(x => (x.id === id ? { ...x, stoplist: true } : x))
      } else {
        dishesUpdated = state.dishes.map(x => (x.id === id ? { ...x, stoplist: false } : x))
      }

      return {
        ...state,
        dishes: dishesUpdated
      }
    }

    default:
      return state
  }
}
