import React from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'src/hooks/useInterval'
import { getLastClick, soundEffect } from 'src/utils/soundEffect'
import { getSelectedLanguage } from 'src/state/general/selectors'
import translationsCashier from 'src/translations/cashier'
import useIntegrationApi from 'src/hooks/useIntegrationApi'
import { getIntegrationStatus } from 'src/state/status'
import Payment from './Payment'
import { isCashier } from './LogPaymentList'
import s from './Cashier.module.css'

export const PaymentList = ({ payments, venueSlug, handleManualPayment }) => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsCashier[selectedLanguage]
  const [
    {
      settings: { autointegration }
    }
  ] = useIntegrationApi()
  const integrationEnabled = useSelector(getIntegrationStatus)

  const activePayments = payments
    .filter(payment => {
      if (integrationEnabled && autointegration) {
        return !payment.status.cashierClosed
      } else {
        return !isCashier(payment)
      }
    })
    .sort((b, a) => {
      let timeA = new Date(b.paidAt).getTime()
      let timeB = new Date(a.paidAt).getTime()
      if (b.timedTakeout) {
        timeA = new Date(b.timedTakeout).getTime()
      }
      if (a.timedTakeout) {
        timeB = new Date(a.timedTakeout).getTime()
      }
      return timeA - timeB
    })

  return activePayments.length ? (
    <Content {...{ activePayments, venueSlug, handleManualPayment }} />
  ) : (
    <div className={s.no_payment}>{translations.noOrdersTitle}</div>
  )
}

const Content = ({ activePayments, venueSlug, handleManualPayment }) => {
  useInterval(() => {
    const lastClick = getLastClick()
    if (lastClick && activePayments.length > 0 && activePayments.filter(x => x.activated === 'true').length > 0) {
      soundEffect.play()
    }
  }, 30000)

  return activePayments.map(payment => (
    <Payment
      payment={{
        ...payment,
        takeout: payment.orders && payment.orders.filter(o => o.takeout).length > 0
      }}
      key={`payment-${payment.id}`}
      venueSlug={venueSlug}
      handleManualPayment={handleManualPayment}
    />
  ))
}
