import { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRestApi } from './useRestApi'
import { updatePayment } from '../state/payments/actions'
import { getPaymentById } from '../state/payments'
import { getIntegrationStatus } from '../state/status'

export default function usePaymentApi(paymentId) {
  const venue = localStorage.getItem('currentVenue')
  const dispatch = useDispatch()
  const payment = useSelector(getPaymentById(paymentId))
  const integrationStatus = useSelector(getIntegrationStatus)

  const [pending, setPending] = useState(false)
  const [manualIntegration, setManualIntegration] = useState(false)
  const [{ data, loading, error }, { sendRequest }] = useRestApi(`/${venue}/setStatus`)

  useEffect(() => {
    if (data && paymentId) {
      dispatch(updatePayment({ id: paymentId, status: data.data?.status }))
    }
  }, [data, dispatch])

  useEffect(() => {
    setManualIntegration(!integrationStatus)
  }, [integrationStatus])

  useEffect(() => {
    setPending(loading)

    if (data || error) {
      setPending(false)
    }
  }, [loading, data, error])

  useEffect(() => {
    if (payment?.integrationStatus === 'started') {
      setPending(true)
    } else if (['finish', 'error'].includes(payment?.integrationStatus)) {
      setPending(false)
    }
  }, [payment])

  const updatePaymentIntegrationStatus = useCallback(
    status => {
      dispatch(updatePayment({ id: paymentId, integrationStatus: status }))
    },
    [dispatch, paymentId]
  )

  const sendPaymentStatus = useCallback(
    (info = { status: null, integrationId: null }) => {
      const { status, integrationId, ...rest } = info
      sendRequest({
        method: 'POST',
        data: {
          paymentId,
          ...(info.status ? { status: info.status } : {}),
          ...(info.integrationId ? { integrationId: info.integrationId } : {}),
          ...rest
        }
      })
    },
    [paymentId, sendRequest]
  )

  const state = useMemo(
    () => ({
      paymentStatus: payment?.status,
      paymentIntegrationStatus: payment?.integrationStatus,
      paymentPending: pending,
      manualIntegration
    }),
    [payment, pending, manualIntegration]
  )

  const api = useMemo(
    () => ({
      sendPaymentStatus,
      updatePaymentIntegrationStatus,
      setPaymentPending: setPending,
      setManualIntegration
    }),
    [sendPaymentStatus, updatePaymentIntegrationStatus, setPending, setManualIntegration]
  )

  return [state, api]
}
