import React from 'react'
import ReactDOM from 'react-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import clsx from 'clsx'
import classes from './ExMaterial.module.css'
import s from '../stoplist/Stoplist/Stoplist.module.scss'
import { ReactComponent as CheckMark } from 'src/assets/ic_check_box_24px.svg'

const usePortal = id => {
  const rootElemRef = React.useRef(document.createElement('div'))

  useEffect(
    function setupElement() {
      // Look for existing target dom element to append to
      const parentElem = document.querySelector(`#${id}`)
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current)
      // This function is run on unmount
      return function removeElement() {
        rootElemRef.current.remove()
      }
    },
    [id]
  )

  return rootElemRef.current
}

export const AntSwitch = () => <div>AntSwitch</div>

export const AppBar = ({ children, className, style, position }) => (
  <div className={classes.appbarWrapper}>
    <div className={[classes.appbar, className].join(' ')} style={{ ...style, position }}>
      {children}
    </div>
  </div>
)

export const Avatar = ({ children, className, style, position }) => (
  <div className={className} style={{ ...style, position }}>
    {children}
  </div>
)

export const Box = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Button = ({ children, className, style, onClick, fullWidth }) => (
  <div
    className={[classes.button, fullWidth ? classes.fullwidth : '', className].join(' ')}
    onClick={onClick}
    style={style}
  >
    {children}
  </div>
)

export const CardMedia = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Checkbox = ({
  children,
  className,
  style,
  disabled = false,
  color = 'primary',
  checked = false,
  onChange = () => {}
}) => {
  const onClick = () => {
    onChange({ target: { value: !checked, checked: !checked } })
  }
  return (
    <div
      onClick={onClick}
      className={[
        classes.checkbox,
        classes.clickable,
        color === 'primary' ? classes.checkboxPrimary : classes.checkboxSecondary,
        className
      ].join(' ')}
      style={style}
    >
      {checked ? <CheckMark fill={color === 'primary' ? '#3f51b5' : '#dc004e'} /> : ''}
    </div>
  )
}

export const CssBaseline = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Container = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Dialog = ({ children, onClose, open, className, style }) => {
  const target = usePortal('root')
  const el = (
    <div
      className={[className, classes.dialogWrapper, open ? classes.dialogOpen : classes.dialogClose].join(' ')}
      style={style}
      onClick={onClose}
    >
      <div className={classes.dialog}>{children}</div>
    </div>
  )
  return ReactDOM.createPortal(el, target)
}

export const Divider = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Drawer = ({ children, wrapperClassName, className, onClose, open, style, anchor = 'right' }) => {
  const target = usePortal('root')
  const el = (
    <div
      className={[classes.drawerWrapper, open ? classes.drawerOpen : classes.drawerClose, wrapperClassName].join(' ')}
      style={style}
      onClick={onClose}
    >
      <div
        className={[classes.drawer, anchor === 'right' ? classes.drawerRight : classes.drawerLeft, className].join(' ')}
      >
        {children}
      </div>
    </div>
  )
  return ReactDOM.createPortal(el, target)
}

export const FormControlLabel = ({ children, label, control, className, style }) => (
  <div className={[classes.formControlLabel, className].join(' ')} style={style}>
    {label}
    <div className={classes.formControlLabelControl}>{control}</div>
  </div>
)

export const Grid = ({ children, className, style, container, item, spacing }) => (
  <div
    className={[
      container ? classes.gridContainer : item ? classes.gridItem : classes.grid,
      spacing ? classes['spacing' + spacing] : '',
      className
    ].join(' ')}
    style={style}
  >
    {children}
  </div>
)

export const IconButton = ({ children, className, style, onClick }) => (
  <div className={[classes.clickable, className].join(' ')} onClick={onClick} style={style}>
    {children}
  </div>
)

export const Link = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const List = ({ children, className, style }) => (
  <ul className={[className, classes.list].join(' ')} style={style}>
    {children}
  </ul>
)

export const ListItem = ({ children, className, style, onClick, button = false }) => (
  <li
    className={[className, classes.listItem, button ? classes.clickable : ''].join(' ')}
    style={style}
    onClick={onClick}
  >
    {children}
  </li>
)

export const ListItemAvatar = ({ children, className, style, primary }) => (
  <div className={[className, classes.listItemAvatar].join(' ')} style={style}>
    {primary}
    {children}
  </div>
)

export const ListItemText = ({ children, className, style, primary }) => (
  <div className={[className, classes.listItemText].join(' ')} style={style}>
    {primary}
    {children}
  </div>
)

export const ListItemIcon = ({ children, className, style, primary }) => (
  <div className={[className, classes.listItemIcon].join(' ')} style={style}>
    {primary}
    {children}
  </div>
)

export const Paper = ({ children, className, onClick, style }) => (
  <div
    onClick={onClick}
    className={[classes.paper, onClick ? classes.clickable : '', className].join(' ')}
    style={style}
  >
    {children}
  </div>
)

export const SvgIcon = ({ children, className, style, onClick, fontSize }) => (
  <svg
    className={[className, classes.svgIcon, fontSize === 'large' ? classes.largeFont : ''].join(' ')}
    onClick={onClick}
    style={style}
  >
    {children}
  </svg>
)

export const Switch = ({ className, style, onChange, checked, value }) => {
  const onClick = () => {
    onChange({ target: { value: !value, checked: !checked } })
  }
  return (
    <div
      className={[className, classes.switchBase, checked ? classes.switchActive : ''].join(' ')}
      onClick={onClick}
      style={style}
    >
      <div className={classes.switchThumb}></div>
    </div>
  )
}

export const TextField = ({
  children,
  value,
  onChange,
  className,
  style,
  select = false,
  label,
  fullWidth = false,
  error,
  ...props
}) => {
  var control = (
    <input
      {...props}
      error={error && error.toString ? error.toString() : ''}
      onChange={onChange}
      value={value}
      className={[className, classes.inputBody].join(' ')}
      style={style}
    />
  )
  if (select) {
    control = (
      <select onChange={onChange} value={value} className={className} style={style}>
        {children}
      </select>
    )
  }
  // return control
  return (
    <div className={[classes.inputFormControl, fullWidth ? classes.fullwidth : ''].join(' ')}>
      <div className={[classes.inputLabel, fullWidth ? classes.fullwidth : ''].join(' ')}>{label}</div>
      <div className={[classes.inputBase, fullWidth ? classes.fullwidth : ''].join(' ')}>{control}</div>
    </div>
  )
}

export const Toolbar = ({ children, className, style }) => (
  <div className={[classes.toolbar, className].join(' ')} style={style}>
    {children}
  </div>
)

export const Typography = ({ children, className, style }) => (
  <div className={className} style={style}>
    {children}
  </div>
)

export const Accordion = ({ title, content, active }) => {
  const [isActive, setIsActive] = useState(false)
  const onClickHandler = e => {
    e.stopPropagation()
    setIsActive(!isActive)
  }

  useEffect(() => {
    setIsActive(active)
  }, [active])

  return (
    <div className='accordion-item'>
      <ListItem
        className={clsx(s.title, s.downBoxShadow)}
        style={{ backgroundColor: 'inherit' }}
        button
        onClick={onClickHandler}
      >
        <ListItemText primary={title} />
        <div>{isActive ? '-' : '+'}</div>
      </ListItem>
      {isActive && <div className='accordion-content'>{content}</div>}
    </div>
  )
}
