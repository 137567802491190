import React from 'react'
import useCurrentTime from '../../hooks/useCurrentTime'
import PropTypes from 'prop-types'

export default function DigitalClock({ className }) {
  const { hours, minutes, seconds } = useCurrentTime()

  return (
    <div className={className}>
      <span>{hours}</span>&#58;
      <span>{minutes}</span>&#58;
      <span>{seconds}</span>
    </div>
  )
}

DigitalClock.propTypes = {
  className: PropTypes.string
}

DigitalClock.defaultProps = {
  className: ''
}
