import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decoratePhone } from '../../../shared/utils/phone'
import { getContect, hideContect } from '../../../state/contect'
import s from './Cashier.module.css'
import { CashierPopup } from './CashierPopup'

var QRCode = require('qrcode.react')

const contacts = {
  tg: 'Telegram',
  wa: 'WhatsApp',
  fb: 'Facebook',
  vb: 'Viber',
  pc: 'Телефонный звонок'
}

export const Contact = () => {
  const contact = useSelector(getContect)

  return contact ? <Content {...contact} /> : null
}

const Content = ({ phone, type, name, userID, delType, delAdress }) => {
  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideContect())

  return (
    <CashierPopup onCloseButton={handleClose}>
      <div className={s.contectBigtext}>{name}</div>
      <div style={{ display: 'flex', marginTop: '24px', marginBottom: '36px' }}>
        {phone !== null ? (
          <QRCode
            size={112}
            value={
              type === 'tg'
                ? `BEGIN:VCARD
VERSION:3.0
N:${userID};${name}
TEL;TYPE=voice,work,pref:${formatPhone(phone)}
END:VCARD`
                : type === 'wa'
                ? `whatsapp://send?phone=${formatPhone(phone)}`
                : type === 'fb'
                ? `whatsapp://send?phone=${formatPhone(phone)}`
                : type === 'vb'
                ? `viber://contact?number=${formatPhone(phone)}`
                : type === 'pc'
                ? `tel:${formatPhone(phone)}`
                : `tel:${formatPhone(phone)}`
            }
          />
        ) : (
          ''
        )}
        <div className={s.contectCol}>
          <div>Телефон: {decoratePhone(phone)}</div>
          <div>Доставка: {delType ? delType : 'Самовывоз'}</div>
          {delType ? <div>Адресс: {delAdress}</div> : ''}
          <div>Тип связи: {type ? contacts[type] : ''}</div>
        </div>
      </div>
    </CashierPopup>
  )
}

const formatPhone = rawPhone => {
  if (/^\d{9}$/.test(rawPhone)) return '+380' + rawPhone
  else if (/^\d{10}$/.test(rawPhone)) return '+38' + rawPhone
  else if (/^\d+$/.test(rawPhone)) return '+' + rawPhone
  else return rawPhone
}
